import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, share, shareReplay, tap, throttleTime } from 'rxjs/operators';
import { environment } from '~environments/environment';
import {
  ListCurrentUserPermissionsRequest,
  ListCurrentUserPermissionsResponse,
} from '~proto/billingsettings/settings_api_pb';
import { BillingSettingsAPI } from '~proto/billingsettings/settings_api_pb_service';
import { UserPermissionsMap } from '~proto/types/types_pb';
import {
  CanCrudUserRequest,
  CanCrudUserResponse,
  CreateEmailUserV2Request,
  CreateUserV2Response,
  DeleteUserRequest,
  DeleteUserResponse,
  ListUsersV2Request,
  ListUsersV2Response,
  UpdateEmailUserV2Request,
  UpdateUserV2Response,
} from '~proto/user/user_api_pb';
import { UserAPI } from '~proto/user/user_api_pb_service';
import { AuthService } from '~services/auth.service';
import { GrpcService } from '~services/grpc.service';
import { RouterStateService } from '~services/router-state.service';
import { userIdArrayToRecord } from '~utilities/idArrayToRecord';
import { observableArrayFromRecordGetter$ } from '~utilities/observableGetter';
import { sortByName } from '~utilities/sortByName';
import * as fromRouterConstants from '../../app-routing.constants';
import { UserV2 } from './../../../proto/user/user/user_pb.d';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private canCrudUsers$$ = new BehaviorSubject<{ initialized: boolean; canCrud: boolean }>({
    canCrud: false,
    initialized: false,
  });
  public canCrudUsers$ = this.canCrudUsers$$.pipe(shareReplay(1));
  private loadUsersThrottle$$ = new Subject();
  private users$$: BehaviorSubject<Record<string, UserV2.AsObject>> = new BehaviorSubject({});
  private token: string;
  private listCurrentUserPermissionsThrottle$$ = new Subject();
  private userPermissions$$: BehaviorSubject<UserPermissionsMap[keyof UserPermissionsMap][]> = new BehaviorSubject<
    UserPermissionsMap[keyof UserPermissionsMap][]
  >([]);

  public get users$(): Observable<UserV2.AsObject[]> {
    this.loadUsers();
    return observableArrayFromRecordGetter$(this.users$$, sortByName);
  }

  public get currentUser$(): Observable<UserV2.AsObject> {
    this.loadUsers();
    return combineLatest([this.routerState.listenForParamChange$(fromRouterConstants.USER_ID), this.users$$]).pipe(
      map(([userId, users]) => users[userId]),
    );
  }

  public get userPermissions$(): Observable<UserPermissionsMap[keyof UserPermissionsMap][]> {
    this.listCurrentUserPermissions();
    return this.userPermissions$$.asObservable().pipe(share());
  }

  constructor(
    private grpc: GrpcService,
    private routerState: RouterStateService,
    private http: HttpClient,
    private authService: AuthService,
  ) {
    this.loadUsersThrottle$$.pipe(throttleTime(100)).subscribe(() => this._loadUsers());
    this.authService.jwt$.subscribe((jwt) => {
      this.token = `bearer ${jwt}`;
    });
    this.listCurrentUserPermissionsThrottle$$.pipe(throttleTime(100)).subscribe(() => this._loadBusinessLines());
    this.grpc.invoke$(UserAPI.CanCrudUser, new CanCrudUserRequest()).subscribe({
      next: (result: CanCrudUserResponse) => {
        this.canCrudUsers$$.next({
          canCrud: result.getAllowed(),
          initialized: true,
        });
      },
    });
  }

  public createUser$(request: CreateEmailUserV2Request): Observable<CreateUserV2Response.AsObject> {
    return this.grpc.invoke$(UserAPI.CreateEmailUserV2, request).pipe(
      map((response: CreateUserV2Response) => response.toObject()),
      tap((response) => {
        const existingUsers = this.users$$.value;
        this.users$$.next({
          ...existingUsers,
          [response.user.authId]: response.user,
        });
      }),
    );
  }

  public updateUserDetails$(request: UpdateEmailUserV2Request): Observable<UpdateUserV2Response.AsObject> {
    return this.grpc.invoke$(UserAPI.UpdateEmailUserV2, request).pipe(
      map((response: UpdateUserV2Response) => response.toObject()),
      tap((response) => {
        const existingUsers = this.users$$.value;
        this.users$$.next({
          ...existingUsers,
          [response.user.authId]: response.user,
        });
      }),
    );
  }

  private loadUsers() {
    this.loadUsersThrottle$$.next();
  }

  private _loadUsers() {
    const request = new ListUsersV2Request();
    this.grpc.invoke$(UserAPI.ListUsersV2, request).subscribe((users: ListUsersV2Response) => {
      console.log('user list: ', users.toObject());
      this.users$$.next(userIdArrayToRecord(users.toObject().userList));
    });
  }

  private listCurrentUserPermissions() {
    this.listCurrentUserPermissionsThrottle$$.next();
  }

  private _loadBusinessLines() {
    const request = new ListCurrentUserPermissionsRequest();
    this.grpc
      .invoke$(BillingSettingsAPI.ListCurrentUserPermissions, request)
      .subscribe((permissions: ListCurrentUserPermissionsResponse) => {
        this.userPermissions$$.next(permissions.toObject().permissionsList);
      });
  }

  public unlinkUser$(authId: string): Observable<DeleteUserResponse> {
    const request = new DeleteUserRequest();
    request.setAuthId(authId);

    return this.grpc.invoke$(UserAPI.DeleteHauliUser, request).pipe(
      tap((response: DeleteUserResponse) => {
        this.loadUsers();
      }),
    );
  }

  public userBulkUploadFile$(fileToUpload): Observable<any> {
    const formData = new FormData();
    formData.append('file', fileToUpload);
    const header = new HttpHeaders({
      Authorization: this.token,
    });
    return this.http
      .post(`${environment.api}/upload_users`, formData, {
        headers: header,
      })
      .pipe(
        tap((_) => {
          this.loadUsers();
        }),
      );
  }
}
