import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment-precise-range-plugin';
import { interval, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface PreciseDiff {
  days: number;
  firstDateWasLater: boolean;
  hours: number;
  minutes: number;
  months: number;
  seconds: number;
  years: number;
}

const withoutSeconds: (keyof PreciseDiff)[] = ['years', 'months', 'days', 'hours', 'minutes'];
const withSeconds: (keyof PreciseDiff)[] = [...withoutSeconds, 'seconds'];

@Pipe({
  name: 'ago',
})
export class AgoPipe implements PipeTransform {
  // tslint:disable-next-line
  public transform(timeString: string | number, showSuffix = true): string {
    return preciseDiffToHuman(timeString, withoutSeconds, showSuffix);
  }
}

@Pipe({
  name: 'agoLive',
})
export class AgoLivePipe implements PipeTransform {
  // tslint:disable-next-line
  public transform(timeString: string | number, showSuffix = true): Observable<string> {
    return interval(200).pipe(map(() => preciseDiffToHuman(timeString, withSeconds, showSuffix)));
  }
}

const substitutes: Partial<Record<keyof PreciseDiff, string>> = { months: 'mo' };

function preciseDiffToHuman(
  dateAsString: string | number,
  dateOrder: (keyof PreciseDiff)[],
  showSuffix = true,
): string {
  const asMoment = moment(dateAsString);
  if (asMoment.isValid) {
    const preciseDiff: PreciseDiff = (moment as any).preciseDiff(moment(), asMoment, true);
    if (!preciseDiff.firstDateWasLater) {
      return '-';
    }
    const result = [];
    let dateOrderIndex = 0;
    while (dateOrderIndex < dateOrder.length && result.length < 2) {
      const key = dateOrder[dateOrderIndex];
      if (preciseDiff[key] > 0) {
        const keyName = substitutes[key] ? substitutes[key] : key.substring(0, 1).toLocaleLowerCase();
        result.push(`${preciseDiff[key]}${keyName}`);
      }
      dateOrderIndex++;
    }
    if (result.length) {
      if (showSuffix) {
        return `${result.join(' ')} ago`;
      }
      return `${result.join(' ')}`;
    }
    return 'just now';
  }
  return '-';
}
