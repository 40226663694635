// package: cbv1
// file: proto/cybertron/marketplace/rfq_api.proto

var proto_cybertron_marketplace_rfq_api_pb = require("../../../proto/cybertron/marketplace/rfq_api_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var MarketplaceLMOAPI = (function () {
  function MarketplaceLMOAPI() {}
  MarketplaceLMOAPI.serviceName = "cbv1.MarketplaceLMOAPI";
  return MarketplaceLMOAPI;
}());

MarketplaceLMOAPI.CreateRFQ = {
  methodName: "CreateRFQ",
  service: MarketplaceLMOAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_marketplace_rfq_api_pb.CreateRFQRequest,
  responseType: proto_cybertron_marketplace_rfq_api_pb.CreateRFQResponse
};

MarketplaceLMOAPI.loadRFQById = {
  methodName: "loadRFQById",
  service: MarketplaceLMOAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_marketplace_rfq_api_pb.LoadRFQByIdRequest,
  responseType: proto_cybertron_marketplace_rfq_api_pb.LoadRFQByIdResponse
};

MarketplaceLMOAPI.SearchBidder = {
  methodName: "SearchBidder",
  service: MarketplaceLMOAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_marketplace_rfq_api_pb.SearchBidderRequest,
  responseType: proto_cybertron_marketplace_rfq_api_pb.SearchBidderResponse
};

MarketplaceLMOAPI.cancelRfq = {
  methodName: "cancelRfq",
  service: MarketplaceLMOAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_marketplace_rfq_api_pb.cancelRfqRequest,
  responseType: proto_cybertron_marketplace_rfq_api_pb.cancelRfqResponse
};

MarketplaceLMOAPI.listRfq = {
  methodName: "listRfq",
  service: MarketplaceLMOAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_marketplace_rfq_api_pb.listRfqRequest,
  responseType: proto_cybertron_marketplace_rfq_api_pb.listRfqResponse
};

MarketplaceLMOAPI.awardRfq = {
  methodName: "awardRfq",
  service: MarketplaceLMOAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_marketplace_rfq_api_pb.awardRfqRequest,
  responseType: proto_cybertron_marketplace_rfq_api_pb.awardRfqResponse
};

MarketplaceLMOAPI.fileUrl = {
  methodName: "fileUrl",
  service: MarketplaceLMOAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_marketplace_rfq_api_pb.RfqFileUrlRequest,
  responseType: proto_cybertron_marketplace_rfq_api_pb.RfqFileUrlResponse
};

MarketplaceLMOAPI.streamer = {
  methodName: "streamer",
  service: MarketplaceLMOAPI,
  requestStream: false,
  responseStream: true,
  requestType: proto_cybertron_marketplace_rfq_api_pb.StreamerRequest,
  responseType: proto_cybertron_marketplace_rfq_api_pb.StreamerResponse
};

MarketplaceLMOAPI.rfqSuggestions = {
  methodName: "rfqSuggestions",
  service: MarketplaceLMOAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_marketplace_rfq_api_pb.rfqSuggestionsRequest,
  responseType: proto_cybertron_marketplace_rfq_api_pb.rfqSuggestionsResponse
};

exports.MarketplaceLMOAPI = MarketplaceLMOAPI;

function MarketplaceLMOAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

MarketplaceLMOAPIClient.prototype.createRFQ = function createRFQ(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketplaceLMOAPI.CreateRFQ, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketplaceLMOAPIClient.prototype.loadRFQById = function loadRFQById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketplaceLMOAPI.loadRFQById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketplaceLMOAPIClient.prototype.searchBidder = function searchBidder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketplaceLMOAPI.SearchBidder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketplaceLMOAPIClient.prototype.cancelRfq = function cancelRfq(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketplaceLMOAPI.cancelRfq, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketplaceLMOAPIClient.prototype.listRfq = function listRfq(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketplaceLMOAPI.listRfq, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketplaceLMOAPIClient.prototype.awardRfq = function awardRfq(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketplaceLMOAPI.awardRfq, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketplaceLMOAPIClient.prototype.fileUrl = function fileUrl(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketplaceLMOAPI.fileUrl, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketplaceLMOAPIClient.prototype.streamer = function streamer(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MarketplaceLMOAPI.streamer, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MarketplaceLMOAPIClient.prototype.rfqSuggestions = function rfqSuggestions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketplaceLMOAPI.rfqSuggestions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.MarketplaceLMOAPIClient = MarketplaceLMOAPIClient;

var MarketplaceBidderAPI = (function () {
  function MarketplaceBidderAPI() {}
  MarketplaceBidderAPI.serviceName = "cbv1.MarketplaceBidderAPI";
  return MarketplaceBidderAPI;
}());

MarketplaceBidderAPI.CanBid = {
  methodName: "CanBid",
  service: MarketplaceBidderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_marketplace_rfq_api_pb.CanBidRequest,
  responseType: proto_cybertron_marketplace_rfq_api_pb.CanBidResponse
};

MarketplaceBidderAPI.CreateBid = {
  methodName: "CreateBid",
  service: MarketplaceBidderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_marketplace_rfq_api_pb.CreateRFQBidRequest,
  responseType: proto_cybertron_marketplace_rfq_api_pb.CreateRFQBidResponse
};

MarketplaceBidderAPI.CanUpdateBidderInformation = {
  methodName: "CanUpdateBidderInformation",
  service: MarketplaceBidderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_marketplace_rfq_api_pb.CanUpdateBidderInformationRequest,
  responseType: proto_cybertron_marketplace_rfq_api_pb.CanUpdateBidderInformationResponse
};

MarketplaceBidderAPI.updateBidderInformation = {
  methodName: "updateBidderInformation",
  service: MarketplaceBidderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_marketplace_rfq_api_pb.updateBidderInformationRequest,
  responseType: proto_cybertron_marketplace_rfq_api_pb.updateBidderInformationResponse
};

MarketplaceBidderAPI.CanMarkNotInterested = {
  methodName: "CanMarkNotInterested",
  service: MarketplaceBidderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_marketplace_rfq_api_pb.CanMarkNotInterestedRequest,
  responseType: proto_cybertron_marketplace_rfq_api_pb.CanMarkNotInterestedResponse
};

MarketplaceBidderAPI.markNotInterested = {
  methodName: "markNotInterested",
  service: MarketplaceBidderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_marketplace_rfq_api_pb.markNotInterestedRequest,
  responseType: proto_cybertron_marketplace_rfq_api_pb.markNotInterestedResponse
};

MarketplaceBidderAPI.distance = {
  methodName: "distance",
  service: MarketplaceBidderAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_marketplace_rfq_api_pb.CalculateDistanceRequest,
  responseType: proto_cybertron_marketplace_rfq_api_pb.CalculateDistanceResponse
};

exports.MarketplaceBidderAPI = MarketplaceBidderAPI;

function MarketplaceBidderAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

MarketplaceBidderAPIClient.prototype.canBid = function canBid(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketplaceBidderAPI.CanBid, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketplaceBidderAPIClient.prototype.createBid = function createBid(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketplaceBidderAPI.CreateBid, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketplaceBidderAPIClient.prototype.canUpdateBidderInformation = function canUpdateBidderInformation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketplaceBidderAPI.CanUpdateBidderInformation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketplaceBidderAPIClient.prototype.updateBidderInformation = function updateBidderInformation(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketplaceBidderAPI.updateBidderInformation, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketplaceBidderAPIClient.prototype.canMarkNotInterested = function canMarkNotInterested(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketplaceBidderAPI.CanMarkNotInterested, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketplaceBidderAPIClient.prototype.markNotInterested = function markNotInterested(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketplaceBidderAPI.markNotInterested, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MarketplaceBidderAPIClient.prototype.distance = function distance(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MarketplaceBidderAPI.distance, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.MarketplaceBidderAPIClient = MarketplaceBidderAPIClient;

