import { Pipe, PipeTransform } from '@angular/core';
import { combineLatest, isObservable, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureFlagService, processFlag } from '~services/feature-flag.service';

@Pipe({
  name: 'featureFlag',
})
export class FeatureFlagPipe implements PipeTransform {
  constructor(private featureFlagService: FeatureFlagService) {}

  // tslint:disable-next-line
  public transform<T>(value: T): Observable<T>;
  // tslint:disable-next-line
  public transform<T>(value: Observable<T>): Observable<T>;
  // tslint:disable-next-line
  public transform<T>(value: T | Observable<T>): any {
    if (Array.isArray(value)) {
      return this.handleArray$(value);
    } else if (isObservable(value)) {
      return this.handleObservable$(value);
    }
    return value;
  }

  private handleObservable$<T>(value$: Observable<T>): Observable<T> {
    return combineLatest([value$, this.featureFlagService.flags$]).pipe(
      map(([valueAsArray, flags]) => {
        if (Array.isArray(valueAsArray)) {
          return (valueAsArray.filter(filterer(flags)) as unknown) as T;
        }
        return valueAsArray;
      }),
    );
  }

  private handleArray$<T>(value: T[]): Observable<T[]> {
    return this.featureFlagService.flags$.pipe(map((flags) => value.filter(filterer(flags))));
  }
}

function filterer(flags: Record<string, boolean>): (item: any) => boolean {
  return (item: any) => {
    if (typeof item === 'object' && item !== null && Reflect.has(item as Object, 'featureFlag')) {
      return processFlag(flags, (item as any).featureFlag);
    }
    return true;
  };
}
