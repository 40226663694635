import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, forwardRef, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, pluck, startWith, tap } from 'rxjs/operators';

const onlyNumbers = /\D/g;

@Directive({
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyInputDirective),
    },
    CurrencyPipe,
  ],
  selector: '[ctCurrency]',
})
export class CurrencyInputDirective implements ControlValueAccessor {
  private type: string;
  public onChange: Function;
  public onTouched: Function;
  public value: number;

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef, private currencyPipe: CurrencyPipe) {
    this.type = (_elementRef.nativeElement as HTMLInputElement).type;
    if (this.type !== 'text') {
      console.warn('currency pipe without text input');
    }
    fromEvent(this._elementRef.nativeElement, 'input')
      .pipe(
        tap((event: Event) => {
          event.preventDefault();
          event.stopImmediatePropagation();
          event.stopPropagation();
        }),
        pluck('target', 'value'),
        startWith(''),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        const minusSignCount = `${value}`.split('-');
        const inputOnlyNumberString = (value as string).replace(onlyNumbers, '');
        const inputOnlyNumber = parseInt(inputOnlyNumberString, 10) || 0;
        this.writeValue(inputOnlyNumber * Math.pow(-1, minusSignCount.length - 1));
      });
  }

  public writeValue(input: number): void {
    const inDollars = input / 100;
    if (this.type === 'text') {
      const formattedField = this.currencyPipe.transform(inDollars);
      this._renderer.setProperty(this._elementRef.nativeElement, 'value', formattedField);
    } else {
      this._renderer.setProperty(this._elementRef.nativeElement, 'value', inDollars);
    }
    if (this.onChange) {
      this.onChange(input);
    }
  }
  public registerOnChange(fn: Function): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
  }
}
