import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { BehaviorSubject, combineLatest, Observable, of, Subject, throwError, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, share, switchMap, take, tap, throttleTime } from 'rxjs/operators';
import { environment } from '~environments/environment';
import {
  CreateContractTypeRequest,
  CreateContractTypeResponse,
  CreateLMOVendorPayloadTypePercentageRequest,
  CreateLMOVendorPayloadTypePercentageResponse,
  CreateVendorContractRequest,
  CreateVendorContractResponse,
  DownloadP2PFileRequest,
  DownloadP2PFileResponse,
  EditContractTypeRequest,
  EditContractTypeResponse,
  EditVendorContractRequest,
  EditVendorContractResponse,
  GetLMOVendorPayloadTypePercentageRequest,
  GetLMOVendorPayloadTypePercentageResponse,
  GetVendorContractRequest,
  GetVendorContractResponse,
  ListContractTypeRequest,
  ListContractTypeResponse,
  ListFixPointCostsRequest,
  ListFixPointCostsResponse,
  ListVendorContractsRequest,
  ListVendorContractsResponse,
  ToggleArchiveVendorContractRequest,
  ToggleArchiveVendorContractResponse,
  ToggleArchiveVendorContractTypeRequest,
  ToggleArchiveVendorContractTypeResponse,
} from '~proto/contracts/contracts_api_pb';
import { ContractsAPI } from '~proto/contracts/contracts_api_pb_service';
import {
  FixedPointCost,
  LMOVendorPayloadTypePercentage,
  VendorContract,
  VendorContractType,
} from '~proto/contracts/contracts_pb';
import { ListCostCenterRequest, ListCostCenterResponse } from '~proto/order/order_api_pb';
import { OrderAPI } from '~proto/order/order_api_pb_service';
import { CostCenter } from '~proto/order/order_pb';
import { PointToPointCostOrderBy } from '~proto/types/types_pb';
import { AuthService } from '~services/auth.service';
import { ConstantsService } from '~services/constants.service';
import { GrpcService } from '~services/grpc.service';
import { RouterStateService } from '~services/router-state.service';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { observableArrayFromRecordGetter$ } from '~utilities/observableGetter';
import * as fromRouterConstants from '../../app-routing.constants';

function contractSortByDate(a: VendorContract.AsObject, b: VendorContract.AsObject) {
  return b.createdUnix - a.createdUnix;
}

export interface FileSortParams {
  fileId: number;
  key: String;
  limit: number;
  page: 1;
  searchBy: string;
  sortDirection: string;
}

interface FileStatusResponse {
  status: boolean;
  data: string;
  fileId: number;
}

@Injectable({
  providedIn: 'root',
})
export class ContractsService {
  private refreshCurrentContract$ = new Subject<null>();
  private contracts$$: BehaviorSubject<Record<number, VendorContract.AsObject>> = new BehaviorSubject({});

  private percentages$$: BehaviorSubject<LMOVendorPayloadTypePercentage.AsObject[]> = new BehaviorSubject(null);
  private contractTypes$$: BehaviorSubject<VendorContractType.AsObject[]> = new BehaviorSubject([]);
  private contractSaved = 'saved';
  private token: string;

  private costCenters$$: BehaviorSubject<CostCenter.AsObject[]> = new BehaviorSubject([]);

  private fileSortParams$$ = new BehaviorSubject<FileSortParams>({
    fileId: -1,
    key: 'originCity',
    limit: 100,
    page: 1,
    searchBy: '',
    sortDirection: 'asc',
  });

  private loadingPointToPointFileData$$ = new BehaviorSubject<boolean>(false);
  private pointToPointFileData$$ = new BehaviorSubject<FixedPointCost.AsObject[]>([]);
  private total$$ = new BehaviorSubject<Number>(0);

  public get nonExpiredContracts$(): Observable<VendorContract.AsObject[]> {
    this.getContracts();
    return observableArrayFromRecordGetter$(this.contracts$$, contractSortByDate).pipe(
      map((contracts) =>
        this.constantsService.sortDataValues(contracts).filter((contract) => !contract.archived && !contract.isExpired),
      ),
    );
  }

  public get allContracts$(): Observable<VendorContract.AsObject[]> {
    this.getContracts();
    return observableArrayFromRecordGetter$(this.contracts$$, contractSortByDate).pipe(
      map((contracts) => this.constantsService.sortDataValues(contracts).filter((contract) => !contract.archived)),
    );
  }

  private async currentContractInternal(): Promise<VendorContract.AsObject> {
    const contractId = await this.routerState
      .listenForParamChange$(fromRouterConstants.CONTRACT_ID)
      .pipe(take(1))
      .toPromise();
    if (!contractId) {
      return null;
    }
    return this.contracts$$.value[contractId];
  }

  public get percentages$(): Observable<Record<number, Record<number, number>>> {
    this.getPercentages();
    return this.percentages$$.asObservable().pipe(
      filter((a) => !!a),
      map((percentages) =>
        percentages.reduce(
          (r, percent) => ({
            ...r,
            [percent.payloadType.id]: {
              ...(r[percent.payloadType.id] || {}),
              [percent.vendor.id]: percent.percentage,
            },
          }),
          {},
        ),
      ),
    );
  }

  public setSavedStatus(status: string) {
    this.contractSaved = status;
  }

  public getSavedStatus() {
    return this.contractSaved;
  }

  public get currentContract$(): Observable<VendorContract.AsObject> {
    this.refreshCurrentContract$.next();
    return combineLatest([
      this.routerState.listenForParamChange$(fromRouterConstants.CONTRACT_ID),
      this.contracts$$,
    ]).pipe(map(([contractId, contracts]) => contracts[contractId]));
  }

  public get contractTypes$(): Observable<VendorContractType.AsObject[]> {
    return this.contractTypes$$.asObservable().pipe(
      map((contractTypes) => this.constantsService.sortDataValues(contractTypes)),
      share(),
    );
  }

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private grpcService: GrpcService,
    private routerState: RouterStateService,
    private snackBar: MatSnackBar,
    private constantsService: ConstantsService,
  ) {
    this.authService.jwt$.subscribe((jwt) => {
      this.token = `bearer ${jwt}`;
    });
    this.getContracts();
    this.getContractTypes();
    this.getPercentages();
    this.loadCostCenters();
    this.startPurchaseOrdersLoading();
    this.refreshCurrentContract$.pipe(throttleTime(50)).subscribe(() => {
      this.routerState
        .listenForParamChange$(fromRouterConstants.CONTRACT_ID)
        .pipe(
          take(1),
          distinctUntilChanged(),
        )
        .subscribe((contractId) => {
          this.loadSingleContract(+contractId);
        });
    });
  }

  public createContract$(request: CreateVendorContractRequest): Observable<VendorContract.AsObject> {
    return this.grpcService.invoke$(ContractsAPI.CreateVendorContract, request).pipe(
      map((response: CreateVendorContractResponse) => {
        const asObject = response.toObject().vendorContract;
        return asObject;
      }),
      tap((contract) => {
        this.contracts$$.next({
          ...this.contracts$$.value,
          [contract.id]: contract,
        });
      }),
    );
  }

  public async editCurrentContract(request: EditVendorContractRequest): Promise<VendorContract.AsObject> {
    const contract = await this.currentContractInternal();
    if (!contract || !contract.id) {
      throwError('Current Contract is not defined');
    }
    request.setId(contract.id);
    const updatedContract = (await this.grpcService
      .invoke$(ContractsAPI.EditVendorContract, request)
      .pipe(take(1))
      .toPromise()) as EditVendorContractResponse;
    const asObject = updatedContract.toObject();
    this.contracts$$.next({
      ...this.contracts$$.value,
      [asObject.vendorContract.id]: asObject.vendorContract,
    });
    return asObject.vendorContract;
  }

  public async toggleCurrentContractArchive(): Promise<void> {
    const contract = await this.currentContractInternal();
    if (!contract || !contract.id) {
      return;
    }
    const request = new ToggleArchiveVendorContractRequest();
    request.setId(contract.id);
    request.setArchive(!contract.archived);
    const updatedContract = (await this.grpcService
      .invoke$(ContractsAPI.ToggleArchiveVendorContract, request)
      .pipe(take(1))
      .toPromise()) as ToggleArchiveVendorContractResponse;
    const asObject = updatedContract.toObject();
    this.contracts$$.next({
      ...this.contracts$$.value,
      [asObject.vendorContract.id]: asObject.vendorContract,
    });
    const verb = contract.archived ? 'restored' : 'archived';
    const snack = this.snackBar.open(`${contract.name} ${verb}`, 'Undo', { duration: 10000 });
    snack.onAction().subscribe(() => {
      this.changeCurrentContractArchive(contract.id, contract.archived);
    });
  }

  public archiveVendorContract(contract: VendorContractType.AsObject) {
    const request = new ToggleArchiveVendorContractTypeRequest();
    request.setId(contract.id);
    request.setArchive(true);
    this.grpcService
      .invoke$(ContractsAPI.ToggleArchiveVendorContractType, request)
      .subscribe((response: ToggleArchiveVendorContractTypeResponse) => {
        this.getContractTypes();
        const snack = this.snackBar.open(`${contract.name} archived`, 'Undo', { duration: 20000 });
        snack.onAction().subscribe(() => {
          this.unArchiveVendorContract(contract);
        });
      });
  }

  public unArchiveVendorContract(contract: VendorContractType.AsObject) {
    const request = new ToggleArchiveVendorContractTypeRequest();
    request.setId(contract.id);
    request.setArchive(false);
    this.grpcService
      .invoke$(ContractsAPI.ToggleArchiveVendorContractType, request)
      .subscribe((response: ToggleArchiveVendorContractTypeResponse) => {
        this.getContractTypes();
        this.snackBar.open(`${contract.name} restored`, null, { duration: 20000 });
      });
  }

  public updatePercentages(updates: CreateLMOVendorPayloadTypePercentageRequest) {
    this.grpcService
      .invoke$(ContractsAPI.CreateLMOVendorPayloadTypePercentage, updates)
      .subscribe((response: CreateLMOVendorPayloadTypePercentageResponse) => {
        this.getPercentages();
      });
  }

  private getPercentages() {
    const request = new GetLMOVendorPayloadTypePercentageRequest();
    this.grpcService
      .invoke$(ContractsAPI.GetLMOVendorPayloadTypePercentage, request)
      .subscribe((response: GetLMOVendorPayloadTypePercentageResponse) => {
        const percentages = response.toObject().payloadPercentagesList;
        this.percentages$$.next(percentages);
      });
  }

  private changeCurrentContractArchive(contractId: number, archived: boolean): void {
    const request = new ToggleArchiveVendorContractRequest();
    request.setId(contractId);
    request.setArchive(archived);
    this.grpcService
      .invoke$(ContractsAPI.ToggleArchiveVendorContract, request)
      .subscribe((response: ToggleArchiveVendorContractResponse) => {
        const contract = response.toObject().vendorContract;
        if (contract) {
          this.contracts$$.next({
            ...this.contracts$$.value,
            [contract.id]: contract,
          });
        } else {
          this.getContracts();
        }
      });
  }

  private async getContracts() {
    const request = new ListVendorContractsRequest();
    const response = (await this.grpcService
      .invoke$(ContractsAPI.ListVendorContracts, request)
      .toPromise()) as ListVendorContractsResponse;

    this.contracts$$.next(idArrayToRecord(response.toObject().vendorContractsList));
  }

  private getContractTypes() {
    const request = new ListContractTypeRequest();
    (this.grpcService.invoke$(ContractsAPI.ListVendorContractTypes, request) as Observable<
      ListContractTypeResponse
    >).subscribe((response) => {
      this.contractTypes$$.next(response.toObject().contracttypesList);
    });
  }

  public createContractType$(
    name: string,
    edi: string,
    fuelContractId?: number,
  ): Observable<VendorContractType.AsObject> {
    const request = new CreateContractTypeRequest();
    request.setName(name);
    request.setEdiCode(edi);
    request.setFuelChargeContractId(fuelContractId);
    return (this.grpcService.invoke$(ContractsAPI.CreateVendorContractType, request) as Observable<
      CreateContractTypeResponse
    >).pipe(
      map((response) => {
        this.getContractTypes();
        return response.toObject().contracttype;
      }),
    );
  }

  public editContractType$(
    id: number,
    name,
    ediCode: string,
    fuelContractId?: number,
  ): Observable<VendorContractType.AsObject> {
    const request = new EditContractTypeRequest();
    request.setId(id);
    request.setEdiCode(ediCode);
    request.setName(name);
    request.setFuelChargeContractId(fuelContractId);
    return (this.grpcService.invoke$(ContractsAPI.EditVendorContractType, request) as Observable<
      EditContractTypeResponse
    >).pipe(
      map((response) => {
        this.getContractTypes();
        return response.toObject().contracttype;
      }),
    );
  }

  private loadSingleContract(contractId: number) {
    const request = new GetVendorContractRequest();
    request.setId(contractId);
    this.grpcService
      .invoke$(ContractsAPI.GetVendorContract, request)
      .subscribe((response: GetVendorContractResponse) => {
        const contract = response.toObject().vendorContract;
        this.contracts$$.next({
          ...this.contracts$$.value,
          [contract.id]: contract,
        });
      });
  }

  public uploadP2PCostFile$(fileToUpload): Observable<any> {
    const formData = new FormData();
    formData.append('file', fileToUpload);
    const header = new HttpHeaders({
      Authorization: this.token,
    });

    return (this.http.post(`${environment.api}/point_cost_upload`, formData, {
      headers: header,
    }) as Observable<number>).pipe(
      switchMap((fileId) => {
        return timer(1000, 1000 * 10).pipe(
          switchMap(() => {
            return this.http.post(
              `${environment.api}/cost_file_status`,
              { fileId: fileId },
              {
                headers: header,
              },
            ) as Observable<FileStatusResponse>;
          }),
          filter((data) => {
            return data.status;
          }),
          map((data) => {
            return { ...data, fileId };
          }),
          take(1),
          tap(() => {
            this.loadFileDetails(fileId);
          }),
        );
      }),
    );
  }

  public downloadP2PCostFile$(fileId: number): Observable<DownloadP2PFileResponse> {
    const request = new DownloadP2PFileRequest();
    request.setFileId(fileId);
    return this.grpcService.invoke$(ContractsAPI.DownloadP2PFile, request) as Observable<DownloadP2PFileResponse>;
  }

  public uploadMileageBracketsFile$(fileToUpload): Observable<any> {
    const formData = new FormData();
    formData.append('file', fileToUpload);
    const header = new HttpHeaders({
      Authorization: this.token,
    });
    // TODO: update path for mileage bracket
    return this.http.post(`${environment.api}/upload_mileage_brackets`, formData, {
      headers: header,
    });
  }

  public get pointToPointFileData$(): Observable<FixedPointCost.AsObject[]> {
    return this.pointToPointFileData$$.asObservable().pipe(share());
  }

  public get totalPointToPointFileData$(): Observable<Number> {
    return this.total$$.asObservable().pipe(share());
  }

  public get pointToPointFileDataSort$(): Observable<FileSortParams> {
    return this.fileSortParams$$.asObservable().pipe(share());
  }

  public get loadingPointToPointFileData$(): Observable<boolean> {
    return this.loadingPointToPointFileData$$.asObservable().pipe(share());
  }

  public loadFileDetails(fileId) {
    const currentSort = this.fileSortParams$$.value;
    currentSort.fileId = fileId;
    currentSort.page = 1;
    this.fileSortParams$$.next(currentSort);
  }

  private getOrderBy(key: any, sortDirection: any) {
    switch (key) {
      case 'originCity': {
        if (sortDirection === 'asc') {
          return PointToPointCostOrderBy.POINTTOPOINTCOST_ORDER_BY_ORIGIN_CITY_ASC;
        } else {
          return PointToPointCostOrderBy.POINTTOPOINTCOST_ORDER_BY_ORIGIN_CITY_DESC;
        }
      }
      case 'destinationCity': {
        if (sortDirection === 'asc') {
          return PointToPointCostOrderBy.POINTTOPOINTCOST_ORDER_BY_DESTINATION_CITY_ASC;
        } else {
          return PointToPointCostOrderBy.POINTTOPOINTCOST_ORDER_BY_DESTINATION_CITY_DESC;
        }
      }
      case 'originState': {
        if (sortDirection === 'asc') {
          return PointToPointCostOrderBy.POINTTOPOINTCOST_ORDER_BY_ORIGIN_STATE_ASC;
        } else {
          return PointToPointCostOrderBy.POINTTOPOINTCOST_ORDER_BY_ORIGIN_STATE_DESC;
        }
      }
      case 'destinationState': {
        if (sortDirection === 'asc') {
          return PointToPointCostOrderBy.POINTTOPOINTCOST_ORDER_BY_DESTINATION_STATE_ASC;
        } else {
          return PointToPointCostOrderBy.POINTTOPOINTCOST_ORDER_BY_DESTINATION_STATE_DESC;
        }
      }
      case 'originZip': {
        if (sortDirection === 'asc') {
          return PointToPointCostOrderBy.POINTTOPOINTCOST_ORDER_BY_ORIGIN_ZIP_ASC;
        } else {
          return PointToPointCostOrderBy.POINTTOPOINTCOST_ORDER_BY_ORIGIN_ZIP_DESC;
        }
      }
      case 'destinationZip': {
        if (sortDirection === 'asc') {
          return PointToPointCostOrderBy.POINTTOPOINTCOST_ORDER_BY_DESTINATION_ZIP_ASC;
        } else {
          return PointToPointCostOrderBy.POINTTOPOINTCOST_ORDER_BY_DESTINATION_ZIP_DESC;
        }
      }
      case 'cost': {
        if (sortDirection === 'asc') {
          return PointToPointCostOrderBy.POINTTOPOINTCOST_ORDER_BY_COST_ASC;
        } else {
          return PointToPointCostOrderBy.POINTTOPOINTCOST_ORDER_BY_COST_DESC;
        }
      }
    }
    return undefined;
  }

  private startPurchaseOrdersLoading() {
    of(1)
      .pipe(
        switchMap(() => this.fileSortParams$$.asObservable()),
        tap(() => this.loadingPointToPointFileData$$.next(true)),
        filter((params) => params.fileId > 0),
        switchMap((params) => {
          const request = new ListFixPointCostsRequest();
          request.setFileId(params.fileId);
          request.setLimit(params.limit);
          request.setOffset((params.page - 1) * params.limit);
          request.setOrderBy(this.getOrderBy(params.key, params.sortDirection));
          request.setSearchField(params.searchBy);
          return this.grpcService.invoke$(ContractsAPI.ListFixPointCosts, request) as Observable<
            ListFixPointCostsResponse
          >;
        }),
        tap(() => this.loadingPointToPointFileData$$.next(false)),
      )
      .subscribe(
        (data) => {
          this.pointToPointFileData$$.next(data.toObject().fixedPointCostsList);
          this.total$$.next(data.toObject().totalCount);
        },
        (error) => {
          console.log('Error while loading', error);
        },
      );
  }

  public sortData(sort) {
    const currentSort = this.fileSortParams$$.value;
    currentSort.key = sort.active;
    currentSort.sortDirection = sort.direction;
    this.fileSortParams$$.next(currentSort);
  }

  public loadPage(pageNumber) {
    const currentSort = this.fileSortParams$$.value;
    console.log('new page is', pageNumber);
    currentSort.page = pageNumber;
    this.fileSortParams$$.next(currentSort);
  }

  public search(searchBy: string) {
    const currentSort = this.fileSortParams$$.value;
    currentSort.searchBy = searchBy;
    currentSort.page = 1;
    this.fileSortParams$$.next(currentSort);
  }

  private loadCostCenters() {
    this.grpcService
      .invoke$(OrderAPI.ListCostCenter, new ListCostCenterRequest())
      .subscribe((response: ListCostCenterResponse) => {
        this.costCenters$$.next(response.toObject().costCentersList);
      });
  }

  public get costCenters$(): Observable<CostCenter.AsObject[]> {
    this.loadCostCenters();
    return this.costCenters$$.asObservable().pipe(share());
  }
}
