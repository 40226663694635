import { Observable, race, timer } from 'rxjs';
import { filter, map, switchMapTo, take, tap } from 'rxjs/operators';

type SortFN<T> = (a: T, b: T) => number;

const timeToWait = 1000;
export function observableArrayFromRecordGetter$<T>(
  subject$: Observable<Record<string, T>>,
  sortFn?: SortFN<T>,
): Observable<T[]> {
  return race(
    timer(timeToWait).pipe(
      take(1),
      switchMapTo(subject$),
    ),
    subject$.pipe(
      filter((t) => !isEmptyRecordOrNull(t)),
      // After the first one, empties are okay so we switch to an unfiltered version
      take(1),
      switchMapTo(subject$),
    ),
  ).pipe(
    map((t) => Object.values(t)),
    tap((t) => {
      if (sortFn) {
        t.sort(sortFn);
      }
    }),
  );
}

export function observableArrayFromArrayGetter$<T>(
  subject$: Observable<T[]>,
  sortFn?: SortFN<T>,
  localTimeToWait = timeToWait,
): Observable<T[]> {
  return race(
    timer(localTimeToWait).pipe(
      take(1),
      switchMapTo(subject$),
    ),
    subject$.pipe(
      filter((t) => !isEmptyArrayOrNull(t)),
      // After the first one, empties are okay so we switch to an unfiltered version
      take(1),
      switchMapTo(subject$),
    ),
  ).pipe(
    tap((t) => {
      if (sortFn) {
        t.sort(sortFn);
      }
    }),
  );
}

function isEmptyRecordOrNull(t: Record<string, any>): boolean {
  if (!t) {
    return true;
  }
  if (t !== Object(t)) {
    console.error(`${t} was passed into isEmptyRecordOrNull but is not an object`);
    return true;
  }
  return Object.keys(t).length === 0;
}

function isEmptyArrayOrNull(t: any[]): boolean {
  if (!t) {
    return true;
  }
  if (!Array.isArray(t)) {
    console.error(`${t} was passed into isEmptyArrayOrNull but is not an array`);
    return true;
  }
  return t.length === 0;
}
