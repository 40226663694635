import { CurrencyInPenniesPipe } from '~common/pipes/currency-in-pennies.pipe';
import { DashIfNothingPipe } from '~common/pipes/dash-if-nothing.pipe';
import { DateOrEmDashPipe } from '~common/pipes/date-or-em-dash';
import { FormatDatePipe } from '~common/pipes/format-date.pipe';
import { MphPipe } from '~common/pipes/mph.pipe';
import { OrderByPipe } from '~common/pipes/order-by.pipe';
import { AgoLivePipe, AgoPipe } from './ago.pipe';
import { CountdownPipe } from './countdown.pipe';
import { DriverStatusPipe } from './driver-status.pipe';
import { MinutesCountdownPipe } from './minutes-countdown.pipe';
import { MinutesToHumanPipe } from './minutes-to-human.pipe';
import { OrderStatusPipe } from './order-status.pipe';
import { PhonePipe } from './phone.pipe';
import { PickerModePipe } from './picker-mode.pipe';
import { SiteStatusPipe } from './site-status.pipe';
import { TaskTypePipe } from './task-type.pipe';

export const pipes = [
  AgoPipe,
  AgoLivePipe,
  SiteStatusPipe,
  TaskTypePipe,
  OrderStatusPipe,
  FormatDatePipe,
  MinutesToHumanPipe,
  DriverStatusPipe,
  PhonePipe,
  CountdownPipe,
  MinutesCountdownPipe,
  MphPipe,
  PickerModePipe,
  OrderByPipe,
  DateOrEmDashPipe,
  CurrencyInPenniesPipe,
  DashIfNothingPipe,
];
