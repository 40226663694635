import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { range, uniq } from 'remeda';
import { DispatcherCalendarPendingTaskSummary, TaskSummary } from '~proto/order/order_pb';
import { ConstantsService } from '~services/constants.service';
import { idColorMap } from '../payload-icon/payload-icon.component';

const payloadIconLeft = 9;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-payloads-icon',
  styleUrls: ['./payloads-icon.component.scss'],
  templateUrl: './payloads-icon.component.svg',
})
export class PayloadsIconComponent implements OnInit {
  private nameMap: Record<string, string> = {};
  public width = 12;
  public viewBox = '0 0 12 12';
  public payloadColors: string[];
  @Input() set taskSummaries(summaries: (TaskSummary.AsObject | DispatcherCalendarPendingTaskSummary.AsObject)[]) {
    const payloadTypeIds = [];
    if (!Array.isArray(summaries)) {
      return;
    }
    summaries.forEach((summary) => {
      if (isTaskSummary(summary)) {
        summary.subTasksList.forEach((subTask) => {
          if (subTask.payload && subTask.payload.type) {
            payloadTypeIds.push(subTask.payload.type.id);
          }
        });
      } else {
        summary.subTasksList.forEach((subTask) => {
          if (subTask.payloadTypeId) {
            payloadTypeIds.push(summary.payloadTypeId);
          }
        });
      }
    });
    this.payloadColors = uniq(payloadTypeIds).map((typeId) => idColorMap[typeId]);
    this.calculateWidthAndViewbox();
  }

  @Input() set textNames(names: string) {
    const trimmedNames = names.split(',').map((name) => name.trim());
    this.payloadColors = trimmedNames.map((name) => {
      if (this.nameMap[name]) {
        return this.nameMap[name];
      }
      return idColorMap[0];
    });
    this.calculateWidthAndViewbox();
  }

  constructor(private constantsService: ConstantsService) {
    const payloadTypes = this.constantsService.payloadTypes;
    range(0, 17).forEach((payloadTypeId) => {
      const payloadType = payloadTypes[payloadTypeId];
      if (payloadType) {
        this.nameMap[payloadType.name] = idColorMap[payloadTypeId];
      }
    });
  }

  public ngOnInit() {}

  public getLeftNobPath(index: number): string {
    return `M${5.70893 + index * payloadIconLeft} 2.66418V0H${1.74624 + index * payloadIconLeft}L${0.179077 +
      index * payloadIconLeft} 2.66418H${5.70893 + index * payloadIconLeft}Z`;
  }

  public getBasePath(index: number): string {
    return `M${0.0223881 + index * payloadIconLeft} 3.11194L${0 + index * payloadIconLeft} 12H${11.8657 +
      index * payloadIconLeft}L${11.8433 + index * payloadIconLeft} 3.11194H${0.0223881 + index * payloadIconLeft}Z`;
  }

  public getRightNobPath(index: number): string {
    return `M${6.15674 + index * payloadIconLeft} 2.66418H${11.6866 + index * payloadIconLeft}L${10.1194 +
      index * payloadIconLeft} 0H${6.15674 + index * payloadIconLeft}V2.66418Z`;
  }

  public trackByString(_index, str: string) {
    return str;
  }

  private calculateWidthAndViewbox() {
    this.width = 12 + (this.payloadColors.length - 1) * payloadIconLeft;
    this.viewBox = `0 0 ${12 + (this.payloadColors.length - 1) * payloadIconLeft} 12`;
  }
}

function isTaskSummary(
  t: TaskSummary.AsObject | DispatcherCalendarPendingTaskSummary.AsObject,
): t is TaskSummary.AsObject {
  return !!(t as TaskSummary.AsObject).type;
}
