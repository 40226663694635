import { LoginPageComponent } from './login-page/login-page.component';
import { NotificationPreferencesComponent } from './notification-preferences/notification-preferences.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SigninWithCustomTokenComponent } from './signin-with-custom-token/signin-with-custom-token.component';
import { AddTrailerComponent } from './trailer/add-trailer/add-trailer.component';
import { EditTrailerComponent } from './trailer/edit-trailer/edit-trailer.component';
import { TrailerManagementComponent } from './trailer/trailer-management/trailer-management.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';

export const containers = [
  LoginPageComponent,
  ResetPasswordComponent,
  NotificationPreferencesComponent,
  SigninWithCustomTokenComponent,
  TrailerManagementComponent,
  AddTrailerComponent,
  EditTrailerComponent,
  UpdatePasswordComponent
];

export { LoginPageComponent } from './login-page/login-page.component';
export { ResetPasswordComponent } from './reset-password/reset-password.component';
export { NotificationPreferencesComponent } from './notification-preferences/notification-preferences.component';
export { SigninWithCustomTokenComponent } from './signin-with-custom-token/signin-with-custom-token.component';
export { AddTrailerComponent } from './trailer/add-trailer/add-trailer.component';
export { EditTrailerComponent } from './trailer/edit-trailer/edit-trailer.component';
export { TrailerManagementComponent } from './trailer/trailer-management/trailer-management.component';
export { UpdatePasswordComponent } from './update-password/update-password.component';
