import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  public transform(value: any): any {
    return this.prettyFormat(value);
  }

  private prettyFormat(value) {
    return moment(value * 1000).format('h:mma MM/DD/YY');
  }
}
