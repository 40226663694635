import { Pipe, PipeTransform } from '@angular/core';
import { TaskType } from '~proto/types/types_pb';

@Pipe({
  name: 'taskType',
})
export class TaskTypePipe implements PipeTransform {
  public transform(value: number): string {
    if (typeof value === 'string') {
      return value;
    }
    switch (value) {
      case TaskType.TASK_TYPE_DROPOFF:
        return 'Dropoff';
      case TaskType.TASK_TYPE_INVALID:
        return 'Invalid';
      case TaskType.TASK_TYPE_OTHER:
        return 'Other';
      case TaskType.TASK_TYPE_PICKUP:
        return 'Pickup';
      case TaskType.TASK_TYPE_RETURN_MATERIALS:
        return 'Return Trailer';
      default:
        return '';
    }
  }
}
