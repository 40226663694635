// package: cbv1
// file: proto/cybertron/account/account_api.proto

var proto_cybertron_account_account_api_pb = require("../../../proto/cybertron/account/account_api_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var AccountAPI = (function () {
  function AccountAPI() {}
  AccountAPI.serviceName = "cbv1.AccountAPI";
  return AccountAPI;
}());

AccountAPI.CreateAccount = {
  methodName: "CreateAccount",
  service: AccountAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_account_account_api_pb.CreateAccountRequest,
  responseType: proto_cybertron_account_account_api_pb.CreateAccountResponse
};

AccountAPI.GetAccount = {
  methodName: "GetAccount",
  service: AccountAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_account_account_api_pb.GetAccountRequest,
  responseType: proto_cybertron_account_account_api_pb.GetAccountResponse
};

AccountAPI.DeleteAccount = {
  methodName: "DeleteAccount",
  service: AccountAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_account_account_api_pb.DeleteAccountRequest,
  responseType: proto_cybertron_account_account_api_pb.DeleteAccountResponse
};

AccountAPI.UpdateAccount = {
  methodName: "UpdateAccount",
  service: AccountAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_account_account_api_pb.UpdateAccountRequest,
  responseType: proto_cybertron_account_account_api_pb.UpdateAccountResponse
};

AccountAPI.PutAccount = {
  methodName: "PutAccount",
  service: AccountAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_account_account_api_pb.PutAccountRequest,
  responseType: proto_cybertron_account_account_api_pb.UpdateAccountResponse
};

AccountAPI.ListAccounts = {
  methodName: "ListAccounts",
  service: AccountAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_account_account_api_pb.ListAccountsRequest,
  responseType: proto_cybertron_account_account_api_pb.ListAccountsResponse
};

AccountAPI.GetAccountDriverCertifications = {
  methodName: "GetAccountDriverCertifications",
  service: AccountAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_account_account_api_pb.GetAccountDriverCertificationsRequest,
  responseType: proto_cybertron_account_account_api_pb.GetAccountDriverCertificationsResponse
};

AccountAPI.SetAccountDriverCertifications = {
  methodName: "SetAccountDriverCertifications",
  service: AccountAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_account_account_api_pb.SetAccountDriverCertificationsRequest,
  responseType: proto_cybertron_account_account_api_pb.SetAccountDriverCertificationsResponse
};

AccountAPI.TransferAccount = {
  methodName: "TransferAccount",
  service: AccountAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_account_account_api_pb.TransferAccountRequest,
  responseType: proto_cybertron_account_account_api_pb.TransferAccountResponse
};

exports.AccountAPI = AccountAPI;

function AccountAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

AccountAPIClient.prototype.createAccount = function createAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AccountAPI.CreateAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AccountAPIClient.prototype.getAccount = function getAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AccountAPI.GetAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AccountAPIClient.prototype.deleteAccount = function deleteAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AccountAPI.DeleteAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AccountAPIClient.prototype.updateAccount = function updateAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AccountAPI.UpdateAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AccountAPIClient.prototype.putAccount = function putAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AccountAPI.PutAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AccountAPIClient.prototype.listAccounts = function listAccounts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AccountAPI.ListAccounts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AccountAPIClient.prototype.getAccountDriverCertifications = function getAccountDriverCertifications(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AccountAPI.GetAccountDriverCertifications, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AccountAPIClient.prototype.setAccountDriverCertifications = function setAccountDriverCertifications(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AccountAPI.SetAccountDriverCertifications, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

AccountAPIClient.prototype.transferAccount = function transferAccount(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(AccountAPI.TransferAccount, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.AccountAPIClient = AccountAPIClient;

