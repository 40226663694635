import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, share, tap, throttleTime } from 'rxjs/operators';
import { UserV2 } from 'src/proto/user/user/user_pb';
import {
  ArchiveBusinessLineRequest,
  ArchiveBusinessLineResponse,
  BusinessLinesAttachedToUserRequest,
  BusinessLinesAttachedToUserResponse,
  CreateNewBusinessLineRequest,
  CreateNewBusinessLineResponse,
  ListBusinessLinesRequest,
  ListBusinessLinesResponse,
  UpdateNewBusinessLineRequest,
  UpdateNewBusinessLineResponse,
  UsersAttachedToBusinessLineRequest,
  UsersAttachedToBusinessLineResponse,
} from '~proto/businessline/businessline_api_pb';
import { BusinessLineAPI } from '~proto/businessline/businessline_api_pb_service';
import { BusinessLine } from '~proto/businessline/businessline_pb';
import { GrpcService } from '~services/grpc.service';
import { RouterStateService } from '~services/router-state.service';
import { businessLineIdArrayToRecord } from '~utilities/idArrayToRecord';
import { observableArrayFromRecordGetter$ } from '~utilities/observableGetter';
import { sortByName } from '~utilities/sortByName';
import * as fromRouterConstants from '../../app-routing.constants';

@Injectable({
  providedIn: 'root',
})
export class BusinessLineService {
  private loadBusinessLinesThrottle$$ = new Subject();
  private businessLines$$: BehaviorSubject<Record<string, BusinessLine.AsObject>> = new BehaviorSubject({});
  private businessLinesAttachedToUser$$: BehaviorSubject<BusinessLine.AsObject[]> = new BehaviorSubject([]);
  private usersAttachedToBusinessLine$$: BehaviorSubject<UserV2.AsObject[]> = new BehaviorSubject([]);

  public get businessLines$(): Observable<BusinessLine.AsObject[]> {
    this.loadBusinessLines();
    return observableArrayFromRecordGetter$(this.businessLines$$, sortByName);
  }

  public get currentBusinessLine$(): Observable<BusinessLine.AsObject> {
    this.loadBusinessLines();
    return combineLatest([
      this.routerState.listenForParamChange$(fromRouterConstants.BUSINESS_LINE),
      this.businessLines$$,
    ]).pipe(map(([businessLineId, businessLines]) => businessLines[businessLineId]));
  }

  public businessLinesAttachedToUser$(userId: string): Observable<BusinessLine.AsObject[]> {
    this._businessLinesAttachedToUser$(userId);
    return this.businessLinesAttachedToUser$$.asObservable().pipe(share());
  }

  public usersAttachedToBusinessLine$(id: number): Observable<UserV2.AsObject[]> {
    this._usersAttachedToBusinessLine$(id);
    return this.usersAttachedToBusinessLine$$.asObservable().pipe(share());
  }

  constructor(private grpc: GrpcService, private routerState: RouterStateService) {
    this.loadBusinessLinesThrottle$$.pipe(throttleTime(100)).subscribe(() => this._loadBusinessLines());
  }

  public createBusinessLine$(
    request: CreateNewBusinessLineRequest,
  ): Observable<CreateNewBusinessLineResponse.AsObject> {
    return this.grpc.invoke$(BusinessLineAPI.CreateNewBusinessLine, request).pipe(
      map((response: CreateNewBusinessLineResponse) => response.toObject()),
      tap((response) => {
        const existingBLs = this.businessLines$$.value;
        this.businessLines$$.next({
          ...existingBLs,
          [response.businessLine.id]: response.businessLine,
        });
      }),
    );
  }

  private loadBusinessLines() {
    this.loadBusinessLinesThrottle$$.next();
  }

  private _loadBusinessLines() {
    const request = new ListBusinessLinesRequest();
    this.grpc
      .invoke$(BusinessLineAPI.ListBusinessLines, request)
      .subscribe((businessLines: ListBusinessLinesResponse) => {
        this.businessLines$$.next(businessLineIdArrayToRecord(businessLines.toObject().businessLinesList));
      });
  }

  public archiveBusinessLine$(id: number): Observable<ArchiveBusinessLineResponse> {
    const request = new ArchiveBusinessLineRequest();
    request.setId(id);

    return this.grpc.invoke$(BusinessLineAPI.ArchiveBusinessLine, request).pipe(
      tap((response: ArchiveBusinessLineResponse) => {
        this.loadBusinessLines();
      }),
    );
  }

  public updateBusinessLine$(request: UpdateNewBusinessLineRequest): Observable<UpdateNewBusinessLineResponse> {
    return this.grpc.invoke$(BusinessLineAPI.UpdateNewBusinessLine, request).pipe(
      tap((response: UpdateNewBusinessLineResponse) => {
        this.loadBusinessLines();
      }),
    );
  }

  private _businessLinesAttachedToUser$(userId: string) {
    const request = new BusinessLinesAttachedToUserRequest();
    request.setUserId(userId);
    this.grpc
      .invoke$(BusinessLineAPI.BusinessLinesAttachedToUser, request)
      .subscribe((businessLines: BusinessLinesAttachedToUserResponse) => {
        this.businessLinesAttachedToUser$$.next(businessLines.toObject().businessLineList);
      });
  }

  private _usersAttachedToBusinessLine$(id: number) {
    this.usersAttachedToBusinessLine$$.next([]);
    const request = new UsersAttachedToBusinessLineRequest();
    request.setId(id);
    this.grpc
      .invoke$(BusinessLineAPI.UsersAttachedToBusinessLine, request)
      .subscribe((users: UsersAttachedToBusinessLineResponse) => {
        this.usersAttachedToBusinessLine$$.next(users.toObject().usersList);
      });
  }
}
