import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pickerMode',
})
export class PickerModePipe implements PipeTransform {
  public transform(value: null): any {
    return window.innerHeight < 768 || window.innerWidth <= 768 ? 'dialog' : 'popup';
  }
}
