import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, share } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { AuthService } from './auth.service';

const defaultFeatureFlags = {
  currentSitePolling: true,
  dispatcherCalendar: true,
  dispatcherClustering: true,
  editPendingOrder: true,
  groupPendingRunboardByDriver: true,
  localDefaultFeatureFlags: true,
  mappableOrdersPolling: true,
  pollingDispatcher: true,
  pollingLMO: true,
  rescheduleOrders: true,
  showWrongVersion: true,
  siteListPolling: true,
  vendorBulkExport: true,
  vendorBulkExportInternal: true,
};

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  // This is only used in dev to override feature flags for development
  private overrides: Record<string, boolean> = {};
  private flags$$ = new BehaviorSubject<Record<string, boolean>>(defaultFeatureFlags);
  private _env: 'stage' | 'prod' = (sessionStorage.getItem('featureFlagEnv') as any) || 'prod';
  private flagMap: { stage: Record<string, boolean>; prod: Record<string, boolean> } = {} as any;

  get flags$(): Observable<Record<string, boolean>> {
    return this.flags$$.asObservable().pipe(
      filter((flags) => !!flags),
      map((flags) => Object.assign({}, flags, this.overrides)),
      share(),
    );
  }

  set env(env: 'stage' | 'prod') {
    this._env = env;
    sessionStorage.setItem('featureFlagEnv', env);
    this.pushFlags();
  }

  get env() {
    return this._env;
  }

  constructor(private db: AngularFirestore, private authService: AuthService, private logger: NGXLogger) {
    if (environment.environment !== 'stage') {
      window['listFeatureFlags'] = () => ({
        environment: environment.environment,
        flags: this.flags$$.value,
      });
    } else {
      window['listFeatureFlags'] = () => ({
        environment: this.env,
        flags: this.flags$$.value,
      });
    }
    if (environment.environment === 'dev') {
      window['setFeatureFlagOverrides'] = (overrides: Record<string, boolean>) => {
        this.overrides = overrides;
        // Send out same flags so observable pushes out again
        this.flags$$.next(this.flags$$.value);
        if (Object.keys(overrides).length === 0 && overrides.constructor === Object) {
          console.log('Feature flags are now in sync with firebase');
        } else {
          console.log(
            'Feature flags will be out of sync with firebase until you refresh or call setFeatureFlagOverrides({})',
          );
        }
      };
    }

    // if (environment.environment !== 'stage') {
    this.subscribeToEnvFlags();
    // } else {
    // this.subscribeToStageAndProdFlags();
    // }
  }

  private subscribeToEnvFlags() {
    this.db
      .doc<Record<string, boolean>>(`cybertron-feature-flags/${environment.environment}`)
      .valueChanges()
      .subscribe(
        (flags) => {
          if (flags) {
            this.flags$$.next(flags);
          } else {
            this.flags$$.next(defaultFeatureFlags);
          }
        },
        (error) => {
          this.logger.error(error);
        },
      );
  }

  private pushFlags() {
    this.flags$$.next(this.flagMap[this.env]);
  }

  public isFlagActive$(flag: string): Observable<boolean> {
    return combineLatest([this.flags$, this.authService.isShaleAppsUser$()]).pipe(
      map(([flags, isShaleAppsUser]) => {
        if (isShaleAppsUser) {
          return processFlag(flags, flag) || processFlag(flags, `${flag}Internal`);
        }
        return processFlag(flags, flag);
      }),
    );
  }

  public isSAOnlyFlag$(flag: keyof Record<string, boolean>): Observable<boolean> {
    return this.flags$.pipe(
      map((flags) => {
        const regularFlag = processFlag(flags, flag);
        const internalFlag = processFlag(flags, `${flag}Internal`);
        return !regularFlag && internalFlag;
      }),
    );
  }
}

export function processFlag(flags: Record<string, boolean>, flag: string): boolean {
  let targetState = true;
  if (flag.startsWith('!')) {
    flag = flag.substr(1);
    targetState = false;
  }
  if (Reflect.has(flags, flag)) {
    return flags[flag] === targetState;
  }
  return false;
}
