import { Pipe, PipeTransform } from '@angular/core';
import { SiteStatus } from '~proto/types/types_pb';

@Pipe({
  name: 'siteStatus',
})
export class SiteStatusPipe implements PipeTransform {
  public transform(value: number): string {
    switch (value) {
      case SiteStatus.SITE_STATUS_COMPLETION:
        return 'Completion';
      case SiteStatus.SITE_STATUS_DRILLING:
        return 'Drilling';
      case SiteStatus.SITE_STATUS_INVALID:
        return 'Invalid';
      case SiteStatus.SITE_STATUS_PLANNING:
        return 'Planning';
      case SiteStatus.SITE_STATUS_PRODUCTION:
        return 'Production';
      case SiteStatus.SITE_STATUS_WORK_OVER:
        return 'Work Over';
      case SiteStatus.SITE_STATUS_NONE:
        return '';
      default:
        return '';
    }
  }
}
