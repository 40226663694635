import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { JobSitesService } from 'src/app/lmo/services/job-sites.service';
import { LMOSiteSummary } from '~proto/site/site_pb';
import { trackById } from '~utilities/trackById';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-jobs-home-pinned',
  styleUrls: ['./jobs-home-pinned.component.scss'],
  templateUrl: './jobs-home-pinned.component.html',
})
export class JobsHomePinnedComponent implements OnInit {
  public trackById = trackById;
  public pinnedJobs$: Observable<LMOSiteSummary.AsObject[]>;

  constructor(private jobsService: JobSitesService) {}

  public ngOnInit() {
    this.pinnedJobs$ = this.jobsService.pinnedSites$.pipe(filter((sites) => !!sites));
  }
}
