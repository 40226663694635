import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '~environments/environment';
import { AttachmentUrlRequest } from '~proto/order/order_api_pb';
import { OrderAPI } from '~proto/order/order_api_pb_service';
import { AuthService } from '~services/auth.service';
import { GrpcService } from '~services/grpc.service';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private token: string;

  constructor(private authService: AuthService, private http: HttpClient, private grpc: GrpcService) {
    this.authService.jwt$.subscribe((jwt) => {
      this.token = `bearer ${jwt}`;
    });
  }

  public uploadFile$(fileToUpload, orderId: number, category?: string): Observable<any> {
    const formData = new FormData();
    formData.append('file', fileToUpload);
    formData.append('category', category);
    const header = new HttpHeaders({
      Authorization: this.token,
    });
    return this.http.post(`${environment.api}/order/${orderId}/upload`, formData, {
      headers: header,
    });
  }

  public downloadFile$(fileId, orderId): Observable<any> {
    const orderAttachmentUrl = new AttachmentUrlRequest();
    orderAttachmentUrl.setId(fileId);
    return this.grpc.invoke$(OrderAPI.GetOrderAttachmentUrl, orderAttachmentUrl);
  }
}
