import { Injectable } from '@angular/core';
import * as Fuse from 'fuse.js';
import { BehaviorSubject, combineLatest, forkJoin, interval, Observable, of } from 'rxjs';
import { filter, map, switchMap, switchMapTo, take, tap } from 'rxjs/operators';
import {
  BackHaulRequest,
  BackHaulResponse,
  CompleteOrderRequest,
  CompleteOrderResponse,
  CreateOrderRequest,
  CreateOrderResponse,
  DeleteAttachmentRequest,
  DeleteAttachmentResponse,
  FleetManagerDriverRequest,
  FleetManagerDriverResponse,
  GetOrderRequest,
  LMOBulkCancelOrderRequest,
  LMOBulkCancelOrderResponse,
  LMOBulkMoveScheduleForOrdersRequest,
  LMOBulkMoveScheduleForOrdersResponse,
  LMOBulkSetAsASAPOrdersRequest,
  LMOBulkSetAsASAPOrdersResponse,
  LMOBulkSetAsScheduledOrdersRequest,
  LMOBulkSetAsScheduledOrdersResponse,
  LMOCancelOrderRequest,
  LMOCancelOrderResponse,
  LMOUpdateOrderRequest,
  LMOUpdateOrderResponse,
  LMOUpdatePendingOrderRequest,
  LMOUpdatePendingOrderResponse,
  ManuallyCompleteTaskRequest,
  ManuallyCompleteTaskResponse,
  MappableOrderRequest,
  MappableOrderResponse,
  OrderResponse,
  StatsRequest,
  StatsResponse,
} from '~proto/order/order_api_pb';
import { OrderAPI } from '~proto/order/order_api_pb_service';
import { DetentionStats, FleetManagerDriver, MappableOrder, Order, OrderAttachment } from '~proto/order/order_pb';
import { AccountType } from '~proto/types/types_pb';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { observableArrayFromRecordGetter$ } from '~utilities/observableGetter';
import * as fromRouterConstants from '../../app-routing.constants';
import { AuthService } from '../../services/auth.service';
import { FeatureFlagService } from '../../services/feature-flag.service';
import { GrpcService } from '../../services/grpc.service';
import { RouterStateService } from '../../services/router-state.service';
import { JobSitesService } from './job-sites.service';

// @ts-ignore
const searchOptions: Fuse.FuseOptions = {
  distance: 100,
  keys: ['id', 'truckName', 'driver.user.name', 'trailerName', 'taskSummariesList.payload.name', 'vendorName'],
  location: 0,
  maxPatternLength: 16,
  minMatchCharLength: 1,
  shouldSort: true,
  threshold: 0.2,
};

function sortByMapId(a: MappableOrder.AsObject, b: MappableOrder.AsObject): number {
  return a.id - b.id;
}

const pollingTime = 1 * 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  private scheduledDrivers$$: BehaviorSubject<FleetManagerDriver.AsObject[]> = new BehaviorSubject([]);
  private mappableInProgressOrders$$: BehaviorSubject<Record<string, MappableOrder.AsObject>> = new BehaviorSubject({});
  private mappableCompletedOrders$$: BehaviorSubject<Record<string, MappableOrder.AsObject>> = new BehaviorSubject({});
  private mappablePendingOrders$$: BehaviorSubject<Record<string, MappableOrder.AsObject>> = new BehaviorSubject({});
  private currentOrder$$: BehaviorSubject<Order.AsObject> = new BehaviorSubject(null);
  private allCompletedAndCancelledOrders: MappableOrder.AsObject[] = [];
  private fuseCompletedSearch: Fuse<MappableOrder.AsObject>;

  private _searchTerm: string = null;
  public get searchTerm(): string {
    return this._searchTerm;
  }
  public set searchTerm(searchTerm: string) {
    this._searchTerm = searchTerm;
    this.filter();
  }

  private filter(): void {
    if (this.searchTerm === null || this.searchTerm === '') {
      this.mappableCompletedOrders$$.next(idArrayToRecord(this.allCompletedAndCancelledOrders));
      return;
    }

    if (this.fuseCompletedSearch) {
      this.mappableCompletedOrders$$.next(idArrayToRecord(this.fuseCompletedSearch.search(this.searchTerm)));
    }
  }

  public get mappableInProgressOrders$(): Observable<MappableOrder.AsObject[]> {
    this.getMappableInProgressOrders();
    return observableArrayFromRecordGetter$(this.mappableInProgressOrders$$, sortByMapId);
  }

  public get mappableCompletedOrders$(): Observable<MappableOrder.AsObject[]> {
    this.getMappableCompletedOrders();
    return observableArrayFromRecordGetter$(this.mappableCompletedOrders$$, sortByMapId);
  }

  public get mappablePendingOrders$(): Observable<MappableOrder.AsObject[]> {
    this.getMappablePendingOrders();
    return observableArrayFromRecordGetter$(this.mappablePendingOrders$$, sortByMapId);
  }

  public get currentOrder$(): Observable<Order.AsObject> {
    return this.currentOrder$$.asObservable();
  }

  public get scheduledDrivers$(): Observable<FleetManagerDriver.AsObject[]> {
    return this.scheduledDrivers$$.asObservable();
  }

  constructor(
    private grpcService: GrpcService,
    private routerState: RouterStateService,
    private siteService: JobSitesService,
    private authService: AuthService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.subscribeToRouterOrderIdChanges();
    this.mappableOrdersPolling();

    this.authService.idToken$.subscribe((idToken) => {
      if (!idToken) {
        this.clearEverything();
      }
    });
    this.listenForDateChanges();
  }

  public createOrder$(createOrderRequest: CreateOrderRequest): Observable<CreateOrderResponse.AsObject> {
    return this.grpcService.invoke$(OrderAPI.CreateOrder, createOrderRequest).pipe(
      tap(() => {
        this.siteService.reloadCurrentSite();
        this.siteService.getLMOSiteCalendarStats();
        this.getMappablePendingOrders();
      }),
      map((response: CreateOrderResponse) => response.toObject()),
    );
  }

  public createMultipleOrders$(createOrderRequests: CreateOrderRequest[]): Observable<{}> {
    return forkJoin(
      createOrderRequests.map((req) => {
        return this.grpcService.invoke$(OrderAPI.CreateOrder, req);
      }),
    ).pipe(
      tap(() => {
        this.siteService.reloadCurrentSite();
        this.siteService.getLMOSiteCalendarStats();
        this.getMappablePendingOrders();
      }),
      map(() => ({})),
    );
  }

  public cancelPendingOrder$(orderId: number): Observable<{}> {
    return this.cancelOrder$(orderId).pipe(
      tap(() => {
        this.siteService.getLMOSiteCalendarStats();
        this.siteService.reloadCurrentSite();
        this.getMappablePendingOrders();
      }),
    );
  }

  public cancelInProgressOrder$(orderId: number): Observable<{}> {
    return this.cancelOrder$(orderId).pipe(
      tap(() => {
        this.siteService.getLMOSiteCalendarStats();
        this.getMappableInProgressOrders();
      }),
    );
  }

  public manuallyCompleteTask$(taskId: number): Observable<Order.AsObject> {
    const request = new ManuallyCompleteTaskRequest();
    request.setTaskId(taskId);

    return this.grpcService.invoke$(OrderAPI.ManuallyCompleteTask, request).pipe(
      map((response: ManuallyCompleteTaskResponse) => {
        const order = response.toObject().order;
        this.currentOrder$$.next(order);
        this.siteService.reloadCurrentSite();
        this.siteService.getLMOSiteCalendarStats();
        return order;
      }),
    );
  }

  private cancelOrder$(orderId: number): Observable<{}> {
    const cancelOrder = new LMOCancelOrderRequest();
    cancelOrder.setOrderId(orderId);

    return this.grpcService.invoke$(OrderAPI.LMOCancelOrder, cancelOrder).pipe(
      map((response: LMOCancelOrderResponse) => {
        this.getMappablePendingOrders();
        this.getMappableInProgressOrders();
        this.siteService.reloadCurrentSite();
        return response.toObject();
      }),
    );
  }

  public completeOrder$(orderId: number): Observable<{}> {
    const completeOrder = new CompleteOrderRequest();
    completeOrder.setOrderId(orderId);

    return this.grpcService.invoke$(OrderAPI.CompleteOrder, completeOrder).pipe(
      map((response: CompleteOrderResponse) => {
        this.getMappableInProgressOrders();
        this.getMappableCompletedOrders();
        this.siteService.reloadCurrentSite();
        return response.toObject();
      }),
    );
  }

  public updateOrder$(request: LMOUpdateOrderRequest): Observable<Order.AsObject> {
    request.setId(this.currentOrder$$.value.id);
    return this.grpcService.invoke$(OrderAPI.LMOUpdateOrder, request).pipe(
      map((response: LMOUpdateOrderResponse) => {
        const order = response.toObject().order;
        this.currentOrder$$.next(order);
        return order;
      }),
    );
  }

  public updatePendingOrder$(request: LMOUpdatePendingOrderRequest): Observable<Order.AsObject> {
    request.setId(this.currentOrder$$.value.id);
    return this.grpcService.invoke$(OrderAPI.LMOUpdatePendingOrder, request).pipe(
      map((response: LMOUpdatePendingOrderResponse) => {
        this.getMappablePendingOrders();
        const order = response.toObject().order;
        this.currentOrder$$.next(order);
        return order;
      }),
    );
  }

  public bulkConvertToAsap$(
    request: LMOBulkSetAsASAPOrdersRequest,
  ): Observable<LMOBulkSetAsASAPOrdersResponse.AsObject> {
    return this.grpcService.invoke$(OrderAPI.LMOBulkSetAsASAPOrders, request).pipe(
      map((response: LMOBulkSetAsASAPOrdersResponse) => response.toObject()),
      tap(() => this.getMappablePendingOrders()),
    );
  }

  public bulkConvertToScheduled$(
    request: LMOBulkSetAsScheduledOrdersRequest,
  ): Observable<LMOBulkSetAsScheduledOrdersResponse.AsObject> {
    return this.grpcService.invoke$(OrderAPI.LMOBulkSetAsScheduledOrders, request).pipe(
      map((response: LMOBulkSetAsScheduledOrdersResponse) => response.toObject()),
      tap(() => this.getMappablePendingOrders()),
    );
  }

  public bulkMoveOrders$(
    request: LMOBulkMoveScheduleForOrdersRequest,
  ): Observable<LMOBulkMoveScheduleForOrdersResponse.AsObject> {
    return this.grpcService.invoke$(OrderAPI.LMOBulkMoveScheduleForOrders, request).pipe(
      map((response: LMOBulkMoveScheduleForOrdersResponse) => response.toObject()),
      tap(() => this.getMappablePendingOrders()),
    );
  }

  private async getMappableCompletedOrders(backgroundRequest = false) {
    const jobId = await this.routerState
      .listenForParamChange$(fromRouterConstants.JOB_ID)
      .pipe(take(1))
      .toPromise();
    if (!jobId) {
      this.mappableCompletedOrders$$.next({});
      return;
    }

    if (
      !(await this.authService
        .isLMO$()
        .pipe(take(1))
        .toPromise())
    ) {
      return;
    }

    const mappableCompletedOrders = new MappableOrderRequest();
    mappableCompletedOrders.setSiteId(+jobId);
    mappableCompletedOrders.setMaxResults(1000);

    this.grpcService
      .invoke$(OrderAPI.LMOListMappableCompletedOrders, mappableCompletedOrders, backgroundRequest)
      .subscribe((response: MappableOrderResponse) => {
        this.allCompletedAndCancelledOrders = response.toObject().ordersList;
        const mappableOrders = idArrayToRecord(response.toObject().ordersList);
        this.fuseCompletedSearch = new Fuse(this.allCompletedAndCancelledOrders, searchOptions);
        this.mappableCompletedOrders$$.next(mappableOrders);
      });
  }

  private async getMappableInProgressOrders(backgroundRequest = false) {
    const jobId = await this.routerState
      .listenForParamChange$(fromRouterConstants.JOB_ID)
      .pipe(take(1))
      .toPromise();
    if (!jobId) {
      this.mappableInProgressOrders$$.next({});
      return;
    }

    if (
      !(await this.authService
        .isLMO$()
        .pipe(take(1))
        .toPromise())
    ) {
      return;
    }

    const mappableInProgressOrdersReq = new MappableOrderRequest();
    mappableInProgressOrdersReq.setSiteId(+jobId);
    mappableInProgressOrdersReq.setMaxResults(1000);

    this.grpcService
      .invoke$(OrderAPI.LMOListMappableInProgressOrders, mappableInProgressOrdersReq, backgroundRequest)
      .subscribe((message: MappableOrderResponse) => {
        const mappableOrders = idArrayToRecord(message.toObject().ordersList);
        this.mappableInProgressOrders$$.next(mappableOrders);
      });
  }

  private async getMappablePendingOrders(backgroundRequest = false) {
    const jobId = await this.routerState
      .listenForParamChange$(fromRouterConstants.JOB_ID)
      .pipe(take(1))
      .toPromise();
    if (!jobId) {
      this.mappablePendingOrders$$.next({});
      return;
    }

    if (
      !(await this.authService
        .isLMO$()
        .pipe(take(1))
        .toPromise())
    ) {
      return;
    }
    const mappablePendingOrdersRequest = new MappableOrderRequest();
    mappablePendingOrdersRequest.setSiteId(+jobId);
    mappablePendingOrdersRequest.setMaxResults(1000);
    this.grpcService
      .invoke$(OrderAPI.LMOListMappablePendingOrders, mappablePendingOrdersRequest, backgroundRequest)
      .subscribe((message: MappableOrderResponse) => {
        const mappableOrders = idArrayToRecord(message.toObject().ordersList);
        this.mappablePendingOrders$$.next(mappableOrders);
      });
  }

  private mappableOrdersPolling() {
    this.routerState.routerState$
      .pipe(
        filter((state) => !!state),
        switchMap((state) => {
          if (state.params[fromRouterConstants.JOB_ID] && !state.url.endsWith('/dashboard')) {
            return interval(pollingTime).pipe(
              switchMapTo(this.featureFlagService.isFlagActive$('pollingLMO').pipe(take(1))),
              tap(async (isFlagActive) => {
                if (isFlagActive) {
                  const isLMO = await this.authService
                    .isAccountType$(AccountType.ACCOUNT_TYPE_LMO)
                    .pipe(take(1))
                    .toPromise();
                  if (!isLMO) {
                    return;
                  }

                  this.getMappablePendingOrders(true);
                  this.getMappableInProgressOrders(true);
                  this.getMappableCompletedOrders(true);
                }
              }),
            );
          } else {
            return of(null);
          }
        }),
      )
      .subscribe();
  }

  private subscribeToRouterOrderIdChanges() {
    this.routerState.listenForParamChange$(fromRouterConstants.ORDER_ID).subscribe((orderId) => {
      // if (!orderId) {
      this.currentOrder$$.next(null);
      //   return;
      // }
      if (!!orderId) {
        this.getOrder(+orderId);
      }
    });
  }

  private getOrder(orderId: number) {
    const getOrder = new GetOrderRequest();
    getOrder.setId(orderId);
    this.grpcService.invoke$(OrderAPI.GetOrder, getOrder).subscribe((message: OrderResponse) => {
      this.currentOrder$$.next(message.toObject().order);
    });
  }

  public deleteAttachment$(fileId: number): Observable<OrderAttachment.AsObject[]> {
    const deleteRequest = new DeleteAttachmentRequest();
    deleteRequest.setId(fileId);
    return this.grpcService.invoke$(OrderAPI.DeleteOrderAttachment, deleteRequest).pipe(
      map((response: DeleteAttachmentResponse) => {
        if (response.toObject().status) {
          const currentOrder = this.currentOrder$$.value;
          const index = currentOrder.attachmentsList.findIndex((file) => file.id === fileId);
          currentOrder.attachmentsList.splice(index, 1);
          this.currentOrder$$.next(currentOrder);
          return currentOrder.attachmentsList;
        } else {
          return [];
        }
      }),
    );
  }

  // Saving for sockets eventually
  // private subscribeToMappableOrders() {
  //   const request = new StreamMappableOrderRequest();
  //   combineLatest(
  //     this.routerState.listenForParamChange$(fromRouterConstants.JOB_ID),
  //     this.grpcService.invoke$(OrderAPI.StreamMappableOrders, request),
  //   ).subscribe(([jobId, eventResponse]: [string, StreamMappableOrderResponse]) => {
  //     const event = eventResponse.toObject().mappableOrderEvent;
  //     const billingTask = event.order.taskSummariesList.find((task) => task.isBillable);
  //     if (jobId && billingTask && billingTask.site && billingTask.site.id === +jobId) {
  //       if (+event.event === EventType.INSERT || +event.event === EventType.UPDATE) {
  //         this.upsertMappableOrder(event.order);
  //       } else if (+event.event === EventType.DELETE) {
  //         this.deleteMappableOrder(event.order);
  //       }
  //     }
  //   });
  // }

  // private upsertMappableOrder(order: MappableOrder.AsObject) {
  //   if (isPending(order)) {
  //     if (this.mappablePendingOrders$$.value && !this.mappablePendingOrders$$.value[order.id]) {
  //       this.deleteMappableOrder(order);
  //       this.mappablePendingOrders$$.next({
  //         ...(this.mappablePendingOrders$$.value || {}),
  //         [order.id]: order,
  //       });
  //     }
  //   }
  //   if (isInProgress(order)) {
  //     if (this.mappableInProgressOrders$$.value && !this.mappableInProgressOrders$$.value[order.id]) {
  //       this.deleteMappableOrder(order);
  //       this.mappableInProgressOrders$$.next({
  //         ...(this.mappableInProgressOrders$$.value || {}),
  //         [order.id]: order,
  //       });
  //     }
  //   }
  //   if (isCompleted(order)) {
  //     if (this.mappableCompletedOrders$$.value && !this.mappableCompletedOrders$$.value[order.id]) {
  //       this.deleteMappableOrder(order);
  //       this.mappableCompletedOrders$$.next({
  //         ...(this.mappableCompletedOrders$$.value || {}),
  //         [order.id]: order,
  //       });
  //     }
  //   }
  // }

  // private deleteMappableOrder(order: MappableOrder.AsObject) {
  //   if (this.mappablePendingOrders$$.value && this.mappablePendingOrders$$.value[order.id]) {
  //     this.mappablePendingOrders$$.next(omit(this.mappablePendingOrders$$.value, [`${order.id}`]));
  //   }
  //   if (this.mappableInProgressOrders$$.value && this.mappableInProgressOrders$$.value[order.id]) {
  //     this.mappableInProgressOrders$$.next(omit(this.mappableInProgressOrders$$.value, [`${order.id}`]));
  //   }
  //   if (this.mappableCompletedOrders$$.value && this.mappableCompletedOrders$$.value[order.id]) {
  //     this.mappableCompletedOrders$$.next(omit(this.mappableCompletedOrders$$.value, [`${order.id}`]));
  //   }
  // }

  private clearEverything() {
    this.mappableInProgressOrders$$.next({});
    this.mappableCompletedOrders$$.next({});
    this.mappablePendingOrders$$.next({});
    this.currentOrder$$.next(null);
  }

  private listenForDateChanges() {
    combineLatest([
      this.routerState.listenForParamChange$(fromRouterConstants.YEAR),
      this.routerState.listenForParamChange$(fromRouterConstants.MONTH),
      this.routerState.listenForParamChange$(fromRouterConstants.DAY),
    ]).subscribe(([year, month, date]) => {
      if (!year || !month || !date) {
        return;
      }

      const request = new FleetManagerDriverRequest();
      request.setYear(+year);
      request.setMonth(+month);
      request.setDay(+date);

      this.grpcService
        .invoke$(OrderAPI.GetFleetManagerScheduleForDay, request)
        .subscribe((response: FleetManagerDriverResponse) => {
          this.scheduledDrivers$$.next(response.toObject().driversList);
        });
    });
  }

  public getStats$(): Observable<DetentionStats.AsObject> {
    const statsReq = new StatsRequest();
    return this.grpcService
      .invoke$(OrderAPI.GetDetentionStats, statsReq)
      .pipe(map((response: StatsResponse) => response.toObject().stats));
  }

  public bulkCancelOrders$(request: LMOBulkCancelOrderRequest): Observable<{}> {
    return this.grpcService.invoke$(OrderAPI.LMOBulkCancelOrders, request).pipe(
      map((response: LMOBulkCancelOrderResponse) => response.toObject),
      tap(() => {
        this.siteService.getLMOSiteCalendarStats();
        this.siteService.reloadCurrentSite();
        this.getMappablePendingOrders();
      }),
    );
  }

  public createBackHaul$(request: BackHaulRequest): Observable<BackHaulResponse.AsObject> {
    return (this.grpcService.invoke$(OrderAPI.BackHaul, request) as Observable<BackHaulResponse>).pipe(
      map((response) => {
        this.currentOrder$$.next(response.toObject().order);
        return response.toObject();
      }),
    );
  }
}
