import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { GrpcService } from '~services/grpc.service';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { observableArrayFromRecordGetter$ } from '~utilities/observableGetter';
import { sortByName } from '~utilities/sortByName';

import { LMOListDistrictsRequest, LMOListDistrictsResponse } from '~proto/site/site_api_pb';
import { SiteAPI } from '~proto/site/site_api_pb_service';
import { District } from '~proto/site/site_pb';

@Injectable({
  providedIn: 'root',
})
export class LmoDistrictService {
  private loadLmoDistrictsThrottle$$ = new Subject();
  private lmoDistricts$$: BehaviorSubject<Record<string, District.AsObject>> = new BehaviorSubject({});

  public get lmoDistricts$(): Observable<District.AsObject[]> {
    this.loadLmoDistricts();
    return observableArrayFromRecordGetter$(this.lmoDistricts$$, sortByName);
  }

  constructor(private grpc: GrpcService) {
    this.loadLmoDistrictsThrottle$$.pipe(throttleTime(100)).subscribe(() => this._loadLmoDistricts());
  }

  private loadLmoDistricts() {
    this.loadLmoDistrictsThrottle$$.next();
  }

  private _loadLmoDistricts() {
    const request = new LMOListDistrictsRequest();
    this.grpc.invoke$(SiteAPI.LMOListDistricts, request).subscribe((lmoDistricts: LMOListDistrictsResponse) => {
      this.lmoDistricts$$.next(idArrayToRecord(lmoDistricts.toObject().districtsList));
    });
  }
}
