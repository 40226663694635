import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { environment } from '~environments/environment';
import { Bidder, CreateRFQRequest } from '~proto/marketplace/rfq_api_pb';
import { Unit } from '~proto/payload/payload_pb';
import { AuthService } from '~services/auth.service';
import { MarketplaceService } from '~services/marketplace.service';
import { Point } from '../../../proto/geo/geo_pb';

export interface RfqItemData {
  attachments: number[];
  description: string;
  jobDuration: Date[];
  minBidReduction: number;
  maxPrice: number;
  payload: string;
  quantity: number;
  rfqDuration: Date[];
  site: string;
  unit: Unit.AsObject;
  freightType: string;
  pickupSite: string;
  pickupTime: string;
  dropoffSite: string;
  dropoffTime: string;
  demandCenter: string;
}

export interface RfqVendorData {
  vendors: Bidder[];
  // message: string;
}

const FREIGHT_RFQ_TYPE = 'freight';
const PRODUCT_RFQ_TYPE = 'product';
const DELIVERED_PRODUCT_RFQ_TYPE = 'deliveredProduct';

@Injectable({
  providedIn: 'root',
})
export class RfqFormService implements OnDestroy {
  private rfqItemData: Partial<RfqItemData>;
  private rfqVendorData: RfqVendorData;
  private token: string;
  public rfqType$$ = new BehaviorSubject<string>('');
  private subs: Subscription[] = [];
  constructor(
    private marketplaceService: MarketplaceService,
    private http: HttpClient,
    private authService: AuthService,
  ) {
    this.subs.push(
      this.authService.jwt$.subscribe((jwt) => {
        this.token = `bearer ${jwt}`;
      }),
    );
  }

  public ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  public saveFormData(data: any, type: string) {
    switch (type) {
      case 'ITEM_DATA':
        this.rfqItemData = { ...data };
        break;
      case 'VENDOR_DATA':
        this.rfqVendorData = { ...data };
        this.createRfq();
        break;
    }
  }

  public setRfqType(type) {
    this.rfqType$$.next(type);
  }

  public isFirstFormNotFilled() {
    return this.rfqItemData === undefined;
  }

  public createRfq() {
    const rfqRequest = new CreateRFQRequest();

    // RFQ Item Form Data

    rfqRequest.setQuantity(this.rfqItemData.quantity);
    rfqRequest.setBiddingStartTime(new Date(this.rfqItemData.rfqDuration[0]).getTime() / 1000);
    rfqRequest.setBiddingEndTime(new Date(this.rfqItemData.rfqDuration[1]).getTime() / 1000);
    rfqRequest.setFileIdsList(this.rfqItemData.attachments);
    rfqRequest.setUnit(this.rfqItemData.unit.name);
    rfqRequest.setFileIdsList(this.rfqItemData.attachments);
    rfqRequest.setDescription(this.rfqItemData.description);
    rfqRequest.setMinBidReduction(this.rfqItemData.minBidReduction * 100);

    if (this.rfqType$$.value === FREIGHT_RFQ_TYPE) {
      rfqRequest.setRfqType(2);
      rfqRequest.setFreightTypeName(this.rfqItemData.freightType);
      rfqRequest.setPickupLocation(this.getGeofence(this.rfqItemData.pickupSite));
      rfqRequest.setPickupTime(Math.round(new Date(this.rfqItemData.pickupTime).getTime() / 1000));
      rfqRequest.setLocation(this.getGeofence(this.rfqItemData.dropoffSite));
      rfqRequest.setDropOffTime(Math.round(new Date(this.rfqItemData.dropoffTime).getTime() / 1000));
    } else if (this.rfqType$$.value === PRODUCT_RFQ_TYPE || this.rfqType$$.value === DELIVERED_PRODUCT_RFQ_TYPE) {
      rfqRequest.setJobStartTime(new Date(this.rfqItemData.jobDuration[0]).getTime() / 1000);
      rfqRequest.setJobEndTime(new Date(this.rfqItemData.jobDuration[1]).getTime() / 1000);
      rfqRequest.setPayload(this.rfqItemData.payload);
      rfqRequest.setMaxPrice(this.rfqItemData.maxPrice * 100);
      if (this.rfqType$$.value === PRODUCT_RFQ_TYPE) {
        rfqRequest.setRfqType(3);
        if (this.rfqItemData.demandCenter) {
          rfqRequest.setLocation(this.getGeofence(this.rfqItemData.demandCenter));
        }
      }
      if (this.rfqType$$.value === DELIVERED_PRODUCT_RFQ_TYPE) {
        rfqRequest.setRfqType(4);
        // DROPOFF Site Location Data
        if (this.rfqItemData.site) {
          rfqRequest.setLocation(this.getGeofence(this.rfqItemData.site));
        }
      }
    }

    // RFQ Vendor Form Data
    rfqRequest.setBiddersList(this.rfqVendorData.vendors);
    // rfqRequest.setMessageForBidders(this.rfqVendorData.message);
    this.marketplaceService.createRFQ(rfqRequest);
  }

  private getGeofence(latLng: string) {
    if (latLng) {
      const point = new Point();
      const siteData = latLng.split(',');
      point.setLat(+siteData[0]);
      point.setLon(+siteData[1]);
      return point;
    }
  }

  public uploadFile$(fileToUpload): Observable<any> {
    const formData = new FormData();
    formData.append('file', fileToUpload);
    const header = new HttpHeaders({
      Authorization: this.token,
    });
    return this.http.post(`${environment.api}/upload`, formData, {
      headers: header,
    });
  }
}
