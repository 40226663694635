import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { CountdownPipe } from './countdown.pipe';

@Pipe({
  name: 'minutesCountdown',
})
export class MinutesCountdownPipe implements PipeTransform {
  constructor(private countdownPipe: CountdownPipe) {}
  public transform(value: number): any {
    return this.countdownPipe.transform(
      moment()
        .add(value, 'minutes')
        .unix() * 1000,
    );
  }
}
