// package: cbv1
// file: proto/cybertron/billingsettings/settings_api.proto

var proto_cybertron_billingsettings_settings_api_pb = require("../../../proto/cybertron/billingsettings/settings_api_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var BillingSettingsAPI = (function () {
  function BillingSettingsAPI() {}
  BillingSettingsAPI.serviceName = "cbv1.BillingSettingsAPI";
  return BillingSettingsAPI;
}());

BillingSettingsAPI.CreateLMOBillingSetting = {
  methodName: "CreateLMOBillingSetting",
  service: BillingSettingsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billingsettings_settings_api_pb.CreateLMOBillingSettingRequest,
  responseType: proto_cybertron_billingsettings_settings_api_pb.CreateLMOBillingSettingResponse
};

BillingSettingsAPI.GetLMOBillingSetting = {
  methodName: "GetLMOBillingSetting",
  service: BillingSettingsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billingsettings_settings_api_pb.GetLMOBillingSettingRequest,
  responseType: proto_cybertron_billingsettings_settings_api_pb.GetLMOBillingSettingResponse
};

BillingSettingsAPI.AddUserPermission = {
  methodName: "AddUserPermission",
  service: BillingSettingsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billingsettings_settings_api_pb.AddUserPermissionRequest,
  responseType: proto_cybertron_billingsettings_settings_api_pb.AddUserPermissionResponse
};

BillingSettingsAPI.AddChargeType = {
  methodName: "AddChargeType",
  service: BillingSettingsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billingsettings_settings_api_pb.AddChargeTypeRequest,
  responseType: proto_cybertron_billingsettings_settings_api_pb.AddChargeTypeResponse
};

BillingSettingsAPI.UpdateChargeType = {
  methodName: "UpdateChargeType",
  service: BillingSettingsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billingsettings_settings_api_pb.UpdateChargeTypeRequest,
  responseType: proto_cybertron_billingsettings_settings_api_pb.UpdateChargeTypeResponse
};

BillingSettingsAPI.ListChargeType = {
  methodName: "ListChargeType",
  service: BillingSettingsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billingsettings_settings_api_pb.ListChargeTypeRequest,
  responseType: proto_cybertron_billingsettings_settings_api_pb.ListChargeTypeResponse
};

BillingSettingsAPI.DeleteChargeType = {
  methodName: "DeleteChargeType",
  service: BillingSettingsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billingsettings_settings_api_pb.DeleteChargeTypeRequest,
  responseType: proto_cybertron_billingsettings_settings_api_pb.DeleteChargeTypeResponse
};

BillingSettingsAPI.ListUsersFromAuthIds = {
  methodName: "ListUsersFromAuthIds",
  service: BillingSettingsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billingsettings_settings_api_pb.ListUsersFromAuthIdsRequest,
  responseType: proto_cybertron_billingsettings_settings_api_pb.ListUsersFromAuthIdsResponse
};

BillingSettingsAPI.ListCurrentUserPermissions = {
  methodName: "ListCurrentUserPermissions",
  service: BillingSettingsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billingsettings_settings_api_pb.ListCurrentUserPermissionsRequest,
  responseType: proto_cybertron_billingsettings_settings_api_pb.ListCurrentUserPermissionsResponse
};

exports.BillingSettingsAPI = BillingSettingsAPI;

function BillingSettingsAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

BillingSettingsAPIClient.prototype.createLMOBillingSetting = function createLMOBillingSetting(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingSettingsAPI.CreateLMOBillingSetting, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingSettingsAPIClient.prototype.getLMOBillingSetting = function getLMOBillingSetting(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingSettingsAPI.GetLMOBillingSetting, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingSettingsAPIClient.prototype.addUserPermission = function addUserPermission(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingSettingsAPI.AddUserPermission, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingSettingsAPIClient.prototype.addChargeType = function addChargeType(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingSettingsAPI.AddChargeType, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingSettingsAPIClient.prototype.updateChargeType = function updateChargeType(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingSettingsAPI.UpdateChargeType, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingSettingsAPIClient.prototype.listChargeType = function listChargeType(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingSettingsAPI.ListChargeType, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingSettingsAPIClient.prototype.deleteChargeType = function deleteChargeType(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingSettingsAPI.DeleteChargeType, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingSettingsAPIClient.prototype.listUsersFromAuthIds = function listUsersFromAuthIds(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingSettingsAPI.ListUsersFromAuthIds, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingSettingsAPIClient.prototype.listCurrentUserPermissions = function listCurrentUserPermissions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingSettingsAPI.ListCurrentUserPermissions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.BillingSettingsAPIClient = BillingSettingsAPIClient;

