import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uniqueOrder',
})
export class UniqueOrderPipe implements PipeTransform {
  public transform(value: string): string {
    if (!value) {
      return '';
    }
    const asString = `${value}`;
    if (!asString.startsWith('SA')) {
      return asString;
    }

    return `${asString.substring(0, 2)} ${asString.substring(2, 5)} ${asString.substring(5, 8)} ${asString.substring(
      8,
    )}`;
  }
}
