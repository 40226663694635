/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_cybertron_billing_billing_pb = require('../../../proto/cybertron/billing/billing_pb.js');
var proto_cybertron_billingsettings_settings_pb = require('../../../proto/cybertron/billingsettings/settings_pb.js');
var proto_cybertron_contracts_contracts_pb = require('../../../proto/cybertron/contracts/contracts_pb.js');
var proto_cybertron_account_account_pb = require('../../../proto/cybertron/account/account_pb.js');
var proto_cybertron_types_types_pb = require('../../../proto/cybertron/types/types_pb.js');
goog.exportSymbol('proto.cbv1.ApproveInvoicesRequest', null, global);
goog.exportSymbol('proto.cbv1.ApproveInvoicesResponse', null, global);
goog.exportSymbol('proto.cbv1.BackToDispatcherRequest', null, global);
goog.exportSymbol('proto.cbv1.BackToDispatcherResponse', null, global);
goog.exportSymbol('proto.cbv1.BillOrdersRequest', null, global);
goog.exportSymbol('proto.cbv1.BillOrdersResponse', null, global);
goog.exportSymbol('proto.cbv1.GetBillingOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.GetBillingOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.Invoice', null, global);
goog.exportSymbol('proto.cbv1.LMOCancelCompletedOrdersRequest', null, global);
goog.exportSymbol('proto.cbv1.LMOCancelCompletedOrdersResponse', null, global);
goog.exportSymbol('proto.cbv1.ListAccountsForOrdersRequest', null, global);
goog.exportSymbol('proto.cbv1.ListAccountsForOrdersResponse', null, global);
goog.exportSymbol('proto.cbv1.ListApprovedByLMOOrdersRequest', null, global);
goog.exportSymbol('proto.cbv1.ListApprovedByLMOOrdersResponse', null, global);
goog.exportSymbol('proto.cbv1.ListApprovedByVendorOrdersRequest', null, global);
goog.exportSymbol('proto.cbv1.ListApprovedByVendorOrdersResponse', null, global);
goog.exportSymbol('proto.cbv1.ListApprovedOrdersRequest', null, global);
goog.exportSymbol('proto.cbv1.ListApprovedOrdersResponse', null, global);
goog.exportSymbol('proto.cbv1.ListBatchInvoicesRequest', null, global);
goog.exportSymbol('proto.cbv1.ListBatchInvoicesResponse', null, global);
goog.exportSymbol('proto.cbv1.ListBatchedOrdersRequest', null, global);
goog.exportSymbol('proto.cbv1.ListBatchedOrdersResponse', null, global);
goog.exportSymbol('proto.cbv1.ListContractsForOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.ListContractsForOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.ListInvoicedOrdersRequest', null, global);
goog.exportSymbol('proto.cbv1.ListInvoicedOrdersResponse', null, global);
goog.exportSymbol('proto.cbv1.ListNotBilledOrdersRequest', null, global);
goog.exportSymbol('proto.cbv1.ListNotBilledOrdersResponse', null, global);
goog.exportSymbol('proto.cbv1.ListOrderChargeTypesRequest', null, global);
goog.exportSymbol('proto.cbv1.ListOrderChargeTypesResponse', null, global);
goog.exportSymbol('proto.cbv1.NeedsPushbackRequest', null, global);
goog.exportSymbol('proto.cbv1.NeedsPushbackResponse', null, global);
goog.exportSymbol('proto.cbv1.OrdersCountPerStatusRequest', null, global);
goog.exportSymbol('proto.cbv1.OrdersCountPerStatusResponse', null, global);
goog.exportSymbol('proto.cbv1.OrdersCountPerStatusResponse.OrderCount', null, global);
goog.exportSymbol('proto.cbv1.RejectInvoicesRequest', null, global);
goog.exportSymbol('proto.cbv1.RejectInvoicesResponse', null, global);
goog.exportSymbol('proto.cbv1.SendToInvoicingRequest', null, global);
goog.exportSymbol('proto.cbv1.SendToInvoicingResponse', null, global);
goog.exportSymbol('proto.cbv1.SwitchContractForOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.SwitchContractForOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.UnApproveInvoicesRequest', null, global);
goog.exportSymbol('proto.cbv1.UnApproveInvoicesResponse', null, global);
goog.exportSymbol('proto.cbv1.UpdateBillingOrderRequest', null, global);
goog.exportSymbol('proto.cbv1.UpdateBillingOrderResponse', null, global);
goog.exportSymbol('proto.cbv1.UpdatedChargeObjListRequest', null, global);
goog.exportSymbol('proto.cbv1.UpdatedChargeObjListResponse', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOCancelCompletedOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.LMOCancelCompletedOrdersRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.LMOCancelCompletedOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOCancelCompletedOrdersRequest.displayName = 'proto.cbv1.LMOCancelCompletedOrdersRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.LMOCancelCompletedOrdersRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOCancelCompletedOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOCancelCompletedOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOCancelCompletedOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOCancelCompletedOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderIdsList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOCancelCompletedOrdersRequest}
 */
proto.cbv1.LMOCancelCompletedOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOCancelCompletedOrdersRequest;
  return proto.cbv1.LMOCancelCompletedOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOCancelCompletedOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOCancelCompletedOrdersRequest}
 */
proto.cbv1.LMOCancelCompletedOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setOrderIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOCancelCompletedOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOCancelCompletedOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOCancelCompletedOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOCancelCompletedOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 order_ids = 1;
 * @return {!Array<number>}
 */
proto.cbv1.LMOCancelCompletedOrdersRequest.prototype.getOrderIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<number>} value */
proto.cbv1.LMOCancelCompletedOrdersRequest.prototype.setOrderIdsList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.LMOCancelCompletedOrdersRequest.prototype.addOrderIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.LMOCancelCompletedOrdersRequest.prototype.clearOrderIdsList = function() {
  this.setOrderIdsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.LMOCancelCompletedOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.LMOCancelCompletedOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.LMOCancelCompletedOrdersResponse.displayName = 'proto.cbv1.LMOCancelCompletedOrdersResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.LMOCancelCompletedOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.LMOCancelCompletedOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.LMOCancelCompletedOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOCancelCompletedOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.LMOCancelCompletedOrdersResponse}
 */
proto.cbv1.LMOCancelCompletedOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.LMOCancelCompletedOrdersResponse;
  return proto.cbv1.LMOCancelCompletedOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.LMOCancelCompletedOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.LMOCancelCompletedOrdersResponse}
 */
proto.cbv1.LMOCancelCompletedOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.LMOCancelCompletedOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.LMOCancelCompletedOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.LMOCancelCompletedOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.LMOCancelCompletedOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.LMOCancelCompletedOrdersResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.LMOCancelCompletedOrdersResponse.prototype.setStatus = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.cbv1.LMOCancelCompletedOrdersResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.LMOCancelCompletedOrdersResponse.prototype.setMessage = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListNotBilledOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListNotBilledOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListNotBilledOrdersRequest.displayName = 'proto.cbv1.ListNotBilledOrdersRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListNotBilledOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListNotBilledOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListNotBilledOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListNotBilledOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    commonFilters: (f = msg.getCommonFilters()) && proto_cybertron_billing_billing_pb.CommonFilters.toObject(includeInstance, f),
    olderThanTwoDays: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListNotBilledOrdersRequest}
 */
proto.cbv1.ListNotBilledOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListNotBilledOrdersRequest;
  return proto.cbv1.ListNotBilledOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListNotBilledOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListNotBilledOrdersRequest}
 */
proto.cbv1.ListNotBilledOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.CommonFilters;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.CommonFilters.deserializeBinaryFromReader);
      msg.setCommonFilters(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOlderThanTwoDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListNotBilledOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListNotBilledOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListNotBilledOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListNotBilledOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommonFilters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.CommonFilters.serializeBinaryToWriter
    );
  }
  f = message.getOlderThanTwoDays();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional CommonFilters common_filters = 1;
 * @return {?proto.cbv1.CommonFilters}
 */
proto.cbv1.ListNotBilledOrdersRequest.prototype.getCommonFilters = function() {
  return /** @type{?proto.cbv1.CommonFilters} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billing_billing_pb.CommonFilters, 1));
};


/** @param {?proto.cbv1.CommonFilters|undefined} value */
proto.cbv1.ListNotBilledOrdersRequest.prototype.setCommonFilters = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.ListNotBilledOrdersRequest.prototype.clearCommonFilters = function() {
  this.setCommonFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.ListNotBilledOrdersRequest.prototype.hasCommonFilters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool older_than_two_days = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.ListNotBilledOrdersRequest.prototype.getOlderThanTwoDays = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.ListNotBilledOrdersRequest.prototype.setOlderThanTwoDays = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListNotBilledOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListNotBilledOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListNotBilledOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListNotBilledOrdersResponse.displayName = 'proto.cbv1.ListNotBilledOrdersResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListNotBilledOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListNotBilledOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListNotBilledOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListNotBilledOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListNotBilledOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto_cybertron_billing_billing_pb.BillingListOrder.toObject, includeInstance),
    totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListNotBilledOrdersResponse}
 */
proto.cbv1.ListNotBilledOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListNotBilledOrdersResponse;
  return proto.cbv1.ListNotBilledOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListNotBilledOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListNotBilledOrdersResponse}
 */
proto.cbv1.ListNotBilledOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.BillingListOrder;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.BillingListOrder.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListNotBilledOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListNotBilledOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListNotBilledOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListNotBilledOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.BillingListOrder.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated BillingListOrder orders = 1;
 * @return {!Array<!proto.cbv1.BillingListOrder>}
 */
proto.cbv1.ListNotBilledOrdersResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.cbv1.BillingListOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_billing_billing_pb.BillingListOrder, 1));
};


/** @param {!Array<!proto.cbv1.BillingListOrder>} value */
proto.cbv1.ListNotBilledOrdersResponse.prototype.setOrdersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.BillingListOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.BillingListOrder}
 */
proto.cbv1.ListNotBilledOrdersResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.BillingListOrder, opt_index);
};


proto.cbv1.ListNotBilledOrdersResponse.prototype.clearOrdersList = function() {
  this.setOrdersList([]);
};


/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.cbv1.ListNotBilledOrdersResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.ListNotBilledOrdersResponse.prototype.setTotalCount = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListApprovedByVendorOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListApprovedByVendorOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListApprovedByVendorOrdersRequest.displayName = 'proto.cbv1.ListApprovedByVendorOrdersRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListApprovedByVendorOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListApprovedByVendorOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListApprovedByVendorOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListApprovedByVendorOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    commonFilters: (f = msg.getCommonFilters()) && proto_cybertron_billing_billing_pb.CommonFilters.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListApprovedByVendorOrdersRequest}
 */
proto.cbv1.ListApprovedByVendorOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListApprovedByVendorOrdersRequest;
  return proto.cbv1.ListApprovedByVendorOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListApprovedByVendorOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListApprovedByVendorOrdersRequest}
 */
proto.cbv1.ListApprovedByVendorOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.CommonFilters;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.CommonFilters.deserializeBinaryFromReader);
      msg.setCommonFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListApprovedByVendorOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListApprovedByVendorOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListApprovedByVendorOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListApprovedByVendorOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommonFilters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.CommonFilters.serializeBinaryToWriter
    );
  }
};


/**
 * optional CommonFilters common_filters = 1;
 * @return {?proto.cbv1.CommonFilters}
 */
proto.cbv1.ListApprovedByVendorOrdersRequest.prototype.getCommonFilters = function() {
  return /** @type{?proto.cbv1.CommonFilters} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billing_billing_pb.CommonFilters, 1));
};


/** @param {?proto.cbv1.CommonFilters|undefined} value */
proto.cbv1.ListApprovedByVendorOrdersRequest.prototype.setCommonFilters = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.ListApprovedByVendorOrdersRequest.prototype.clearCommonFilters = function() {
  this.setCommonFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.ListApprovedByVendorOrdersRequest.prototype.hasCommonFilters = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListApprovedByVendorOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListApprovedByVendorOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListApprovedByVendorOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListApprovedByVendorOrdersResponse.displayName = 'proto.cbv1.ListApprovedByVendorOrdersResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListApprovedByVendorOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListApprovedByVendorOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListApprovedByVendorOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListApprovedByVendorOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListApprovedByVendorOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto_cybertron_billing_billing_pb.BillingListOrder.toObject, includeInstance),
    canapprove: jspb.Message.getFieldWithDefault(msg, 2, false),
    totalCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListApprovedByVendorOrdersResponse}
 */
proto.cbv1.ListApprovedByVendorOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListApprovedByVendorOrdersResponse;
  return proto.cbv1.ListApprovedByVendorOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListApprovedByVendorOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListApprovedByVendorOrdersResponse}
 */
proto.cbv1.ListApprovedByVendorOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.BillingListOrder;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.BillingListOrder.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanapprove(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListApprovedByVendorOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListApprovedByVendorOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListApprovedByVendorOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListApprovedByVendorOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.BillingListOrder.serializeBinaryToWriter
    );
  }
  f = message.getCanapprove();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated BillingListOrder orders = 1;
 * @return {!Array<!proto.cbv1.BillingListOrder>}
 */
proto.cbv1.ListApprovedByVendorOrdersResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.cbv1.BillingListOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_billing_billing_pb.BillingListOrder, 1));
};


/** @param {!Array<!proto.cbv1.BillingListOrder>} value */
proto.cbv1.ListApprovedByVendorOrdersResponse.prototype.setOrdersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.BillingListOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.BillingListOrder}
 */
proto.cbv1.ListApprovedByVendorOrdersResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.BillingListOrder, opt_index);
};


proto.cbv1.ListApprovedByVendorOrdersResponse.prototype.clearOrdersList = function() {
  this.setOrdersList([]);
};


/**
 * optional bool CanApprove = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.ListApprovedByVendorOrdersResponse.prototype.getCanapprove = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.ListApprovedByVendorOrdersResponse.prototype.setCanapprove = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int64 total_count = 3;
 * @return {number}
 */
proto.cbv1.ListApprovedByVendorOrdersResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.ListApprovedByVendorOrdersResponse.prototype.setTotalCount = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListApprovedByLMOOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListApprovedByLMOOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListApprovedByLMOOrdersRequest.displayName = 'proto.cbv1.ListApprovedByLMOOrdersRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListApprovedByLMOOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListApprovedByLMOOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListApprovedByLMOOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListApprovedByLMOOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    commonFilters: (f = msg.getCommonFilters()) && proto_cybertron_billing_billing_pb.CommonFilters.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListApprovedByLMOOrdersRequest}
 */
proto.cbv1.ListApprovedByLMOOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListApprovedByLMOOrdersRequest;
  return proto.cbv1.ListApprovedByLMOOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListApprovedByLMOOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListApprovedByLMOOrdersRequest}
 */
proto.cbv1.ListApprovedByLMOOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.CommonFilters;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.CommonFilters.deserializeBinaryFromReader);
      msg.setCommonFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListApprovedByLMOOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListApprovedByLMOOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListApprovedByLMOOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListApprovedByLMOOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommonFilters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.CommonFilters.serializeBinaryToWriter
    );
  }
};


/**
 * optional CommonFilters common_filters = 1;
 * @return {?proto.cbv1.CommonFilters}
 */
proto.cbv1.ListApprovedByLMOOrdersRequest.prototype.getCommonFilters = function() {
  return /** @type{?proto.cbv1.CommonFilters} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billing_billing_pb.CommonFilters, 1));
};


/** @param {?proto.cbv1.CommonFilters|undefined} value */
proto.cbv1.ListApprovedByLMOOrdersRequest.prototype.setCommonFilters = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.ListApprovedByLMOOrdersRequest.prototype.clearCommonFilters = function() {
  this.setCommonFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.ListApprovedByLMOOrdersRequest.prototype.hasCommonFilters = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListApprovedByLMOOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListApprovedByLMOOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListApprovedByLMOOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListApprovedByLMOOrdersResponse.displayName = 'proto.cbv1.ListApprovedByLMOOrdersResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListApprovedByLMOOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListApprovedByLMOOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListApprovedByLMOOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListApprovedByLMOOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListApprovedByLMOOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto_cybertron_billing_billing_pb.BillingListOrder.toObject, includeInstance),
    totalCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListApprovedByLMOOrdersResponse}
 */
proto.cbv1.ListApprovedByLMOOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListApprovedByLMOOrdersResponse;
  return proto.cbv1.ListApprovedByLMOOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListApprovedByLMOOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListApprovedByLMOOrdersResponse}
 */
proto.cbv1.ListApprovedByLMOOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.BillingListOrder;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.BillingListOrder.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListApprovedByLMOOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListApprovedByLMOOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListApprovedByLMOOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListApprovedByLMOOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.BillingListOrder.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated BillingListOrder orders = 1;
 * @return {!Array<!proto.cbv1.BillingListOrder>}
 */
proto.cbv1.ListApprovedByLMOOrdersResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.cbv1.BillingListOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_billing_billing_pb.BillingListOrder, 1));
};


/** @param {!Array<!proto.cbv1.BillingListOrder>} value */
proto.cbv1.ListApprovedByLMOOrdersResponse.prototype.setOrdersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.BillingListOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.BillingListOrder}
 */
proto.cbv1.ListApprovedByLMOOrdersResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.BillingListOrder, opt_index);
};


proto.cbv1.ListApprovedByLMOOrdersResponse.prototype.clearOrdersList = function() {
  this.setOrdersList([]);
};


/**
 * optional int64 total_count = 3;
 * @return {number}
 */
proto.cbv1.ListApprovedByLMOOrdersResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.ListApprovedByLMOOrdersResponse.prototype.setTotalCount = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListInvoicedOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListInvoicedOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListInvoicedOrdersRequest.displayName = 'proto.cbv1.ListInvoicedOrdersRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListInvoicedOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListInvoicedOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListInvoicedOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListInvoicedOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    commonFilters: (f = msg.getCommonFilters()) && proto_cybertron_billing_billing_pb.CommonFilters.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListInvoicedOrdersRequest}
 */
proto.cbv1.ListInvoicedOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListInvoicedOrdersRequest;
  return proto.cbv1.ListInvoicedOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListInvoicedOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListInvoicedOrdersRequest}
 */
proto.cbv1.ListInvoicedOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.CommonFilters;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.CommonFilters.deserializeBinaryFromReader);
      msg.setCommonFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListInvoicedOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListInvoicedOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListInvoicedOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListInvoicedOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommonFilters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.CommonFilters.serializeBinaryToWriter
    );
  }
};


/**
 * optional CommonFilters common_filters = 1;
 * @return {?proto.cbv1.CommonFilters}
 */
proto.cbv1.ListInvoicedOrdersRequest.prototype.getCommonFilters = function() {
  return /** @type{?proto.cbv1.CommonFilters} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billing_billing_pb.CommonFilters, 1));
};


/** @param {?proto.cbv1.CommonFilters|undefined} value */
proto.cbv1.ListInvoicedOrdersRequest.prototype.setCommonFilters = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.ListInvoicedOrdersRequest.prototype.clearCommonFilters = function() {
  this.setCommonFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.ListInvoicedOrdersRequest.prototype.hasCommonFilters = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListInvoicedOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListInvoicedOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListInvoicedOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListInvoicedOrdersResponse.displayName = 'proto.cbv1.ListInvoicedOrdersResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListInvoicedOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListInvoicedOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListInvoicedOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListInvoicedOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListInvoicedOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto_cybertron_billing_billing_pb.BillingListOrder.toObject, includeInstance),
    canapprove: jspb.Message.getFieldWithDefault(msg, 2, false),
    totalCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListInvoicedOrdersResponse}
 */
proto.cbv1.ListInvoicedOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListInvoicedOrdersResponse;
  return proto.cbv1.ListInvoicedOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListInvoicedOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListInvoicedOrdersResponse}
 */
proto.cbv1.ListInvoicedOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.BillingListOrder;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.BillingListOrder.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanapprove(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListInvoicedOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListInvoicedOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListInvoicedOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListInvoicedOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.BillingListOrder.serializeBinaryToWriter
    );
  }
  f = message.getCanapprove();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * repeated BillingListOrder orders = 1;
 * @return {!Array<!proto.cbv1.BillingListOrder>}
 */
proto.cbv1.ListInvoicedOrdersResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.cbv1.BillingListOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_billing_billing_pb.BillingListOrder, 1));
};


/** @param {!Array<!proto.cbv1.BillingListOrder>} value */
proto.cbv1.ListInvoicedOrdersResponse.prototype.setOrdersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.BillingListOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.BillingListOrder}
 */
proto.cbv1.ListInvoicedOrdersResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.BillingListOrder, opt_index);
};


proto.cbv1.ListInvoicedOrdersResponse.prototype.clearOrdersList = function() {
  this.setOrdersList([]);
};


/**
 * optional bool CanApprove = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.ListInvoicedOrdersResponse.prototype.getCanapprove = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.ListInvoicedOrdersResponse.prototype.setCanapprove = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional int64 total_count = 3;
 * @return {number}
 */
proto.cbv1.ListInvoicedOrdersResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.ListInvoicedOrdersResponse.prototype.setTotalCount = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListApprovedOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListApprovedOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListApprovedOrdersRequest.displayName = 'proto.cbv1.ListApprovedOrdersRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListApprovedOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListApprovedOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListApprovedOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListApprovedOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    commonFilters: (f = msg.getCommonFilters()) && proto_cybertron_billing_billing_pb.CommonFilters.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListApprovedOrdersRequest}
 */
proto.cbv1.ListApprovedOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListApprovedOrdersRequest;
  return proto.cbv1.ListApprovedOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListApprovedOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListApprovedOrdersRequest}
 */
proto.cbv1.ListApprovedOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.CommonFilters;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.CommonFilters.deserializeBinaryFromReader);
      msg.setCommonFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListApprovedOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListApprovedOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListApprovedOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListApprovedOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommonFilters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.CommonFilters.serializeBinaryToWriter
    );
  }
};


/**
 * optional CommonFilters common_filters = 1;
 * @return {?proto.cbv1.CommonFilters}
 */
proto.cbv1.ListApprovedOrdersRequest.prototype.getCommonFilters = function() {
  return /** @type{?proto.cbv1.CommonFilters} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billing_billing_pb.CommonFilters, 1));
};


/** @param {?proto.cbv1.CommonFilters|undefined} value */
proto.cbv1.ListApprovedOrdersRequest.prototype.setCommonFilters = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.ListApprovedOrdersRequest.prototype.clearCommonFilters = function() {
  this.setCommonFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.ListApprovedOrdersRequest.prototype.hasCommonFilters = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListApprovedOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListApprovedOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListApprovedOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListApprovedOrdersResponse.displayName = 'proto.cbv1.ListApprovedOrdersResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListApprovedOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListApprovedOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListApprovedOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListApprovedOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListApprovedOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto_cybertron_billing_billing_pb.BillingCompleteListOrder.toObject, includeInstance),
    totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListApprovedOrdersResponse}
 */
proto.cbv1.ListApprovedOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListApprovedOrdersResponse;
  return proto.cbv1.ListApprovedOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListApprovedOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListApprovedOrdersResponse}
 */
proto.cbv1.ListApprovedOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.BillingCompleteListOrder;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.BillingCompleteListOrder.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListApprovedOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListApprovedOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListApprovedOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListApprovedOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.BillingCompleteListOrder.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated BillingCompleteListOrder orders = 1;
 * @return {!Array<!proto.cbv1.BillingCompleteListOrder>}
 */
proto.cbv1.ListApprovedOrdersResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.cbv1.BillingCompleteListOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_billing_billing_pb.BillingCompleteListOrder, 1));
};


/** @param {!Array<!proto.cbv1.BillingCompleteListOrder>} value */
proto.cbv1.ListApprovedOrdersResponse.prototype.setOrdersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.BillingCompleteListOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.BillingCompleteListOrder}
 */
proto.cbv1.ListApprovedOrdersResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.BillingCompleteListOrder, opt_index);
};


proto.cbv1.ListApprovedOrdersResponse.prototype.clearOrdersList = function() {
  this.setOrdersList([]);
};


/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.cbv1.ListApprovedOrdersResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.ListApprovedOrdersResponse.prototype.setTotalCount = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetBillingOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetBillingOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetBillingOrderRequest.displayName = 'proto.cbv1.GetBillingOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetBillingOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetBillingOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetBillingOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetBillingOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetBillingOrderRequest}
 */
proto.cbv1.GetBillingOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetBillingOrderRequest;
  return proto.cbv1.GetBillingOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetBillingOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetBillingOrderRequest}
 */
proto.cbv1.GetBillingOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetBillingOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetBillingOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetBillingOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetBillingOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 order_id = 1;
 * @return {number}
 */
proto.cbv1.GetBillingOrderRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.GetBillingOrderRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetBillingOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetBillingOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetBillingOrderResponse.displayName = 'proto.cbv1.GetBillingOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetBillingOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetBillingOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetBillingOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetBillingOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_billing_billing_pb.BillingOrder.toObject(includeInstance, f),
    canApproveTicket: jspb.Message.getFieldWithDefault(msg, 2, false),
    canApproveInvoice: jspb.Message.getFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetBillingOrderResponse}
 */
proto.cbv1.GetBillingOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetBillingOrderResponse;
  return proto.cbv1.GetBillingOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetBillingOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetBillingOrderResponse}
 */
proto.cbv1.GetBillingOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.BillingOrder;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.BillingOrder.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanApproveTicket(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanApproveInvoice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetBillingOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetBillingOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetBillingOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetBillingOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.BillingOrder.serializeBinaryToWriter
    );
  }
  f = message.getCanApproveTicket();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getCanApproveInvoice();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional BillingOrder order = 1;
 * @return {?proto.cbv1.BillingOrder}
 */
proto.cbv1.GetBillingOrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.BillingOrder} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billing_billing_pb.BillingOrder, 1));
};


/** @param {?proto.cbv1.BillingOrder|undefined} value */
proto.cbv1.GetBillingOrderResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.GetBillingOrderResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.GetBillingOrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool can_approve_ticket = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.GetBillingOrderResponse.prototype.getCanApproveTicket = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.GetBillingOrderResponse.prototype.setCanApproveTicket = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool can_approve_invoice = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.GetBillingOrderResponse.prototype.getCanApproveInvoice = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.cbv1.GetBillingOrderResponse.prototype.setCanApproveInvoice = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SendToInvoicingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.SendToInvoicingRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.SendToInvoicingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SendToInvoicingRequest.displayName = 'proto.cbv1.SendToInvoicingRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.SendToInvoicingRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SendToInvoicingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SendToInvoicingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SendToInvoicingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SendToInvoicingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderIdsList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SendToInvoicingRequest}
 */
proto.cbv1.SendToInvoicingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SendToInvoicingRequest;
  return proto.cbv1.SendToInvoicingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SendToInvoicingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SendToInvoicingRequest}
 */
proto.cbv1.SendToInvoicingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setOrderIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SendToInvoicingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SendToInvoicingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SendToInvoicingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SendToInvoicingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 order_ids = 1;
 * @return {!Array<number>}
 */
proto.cbv1.SendToInvoicingRequest.prototype.getOrderIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<number>} value */
proto.cbv1.SendToInvoicingRequest.prototype.setOrderIdsList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.SendToInvoicingRequest.prototype.addOrderIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.SendToInvoicingRequest.prototype.clearOrderIdsList = function() {
  this.setOrderIdsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SendToInvoicingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SendToInvoicingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SendToInvoicingResponse.displayName = 'proto.cbv1.SendToInvoicingResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SendToInvoicingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SendToInvoicingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SendToInvoicingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SendToInvoicingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getFieldWithDefault(msg, 1, false),
    status: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SendToInvoicingResponse}
 */
proto.cbv1.SendToInvoicingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SendToInvoicingResponse;
  return proto.cbv1.SendToInvoicingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SendToInvoicingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SendToInvoicingResponse}
 */
proto.cbv1.SendToInvoicingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SendToInvoicingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SendToInvoicingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SendToInvoicingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SendToInvoicingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.SendToInvoicingResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.SendToInvoicingResponse.prototype.setSuccess = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.cbv1.SendToInvoicingResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.SendToInvoicingResponse.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.BillOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.BillOrdersRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.BillOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.BillOrdersRequest.displayName = 'proto.cbv1.BillOrdersRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.BillOrdersRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.BillOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.BillOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.BillOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BillOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderIdsList: jspb.Message.getRepeatedField(msg, 1),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.BillOrdersRequest}
 */
proto.cbv1.BillOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.BillOrdersRequest;
  return proto.cbv1.BillOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.BillOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.BillOrdersRequest}
 */
proto.cbv1.BillOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setOrderIdsList(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.BillOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.BillOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.BillOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BillOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated int64 order_ids = 1;
 * @return {!Array<number>}
 */
proto.cbv1.BillOrdersRequest.prototype.getOrderIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<number>} value */
proto.cbv1.BillOrdersRequest.prototype.setOrderIdsList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.BillOrdersRequest.prototype.addOrderIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.BillOrdersRequest.prototype.clearOrderIdsList = function() {
  this.setOrderIdsList([]);
};


/**
 * optional string invoice_number = 2;
 * @return {string}
 */
proto.cbv1.BillOrdersRequest.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.BillOrdersRequest.prototype.setInvoiceNumber = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.BillOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.BillOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.BillOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.BillOrdersResponse.displayName = 'proto.cbv1.BillOrdersResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.BillOrdersResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.BillOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.BillOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.BillOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BillOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    singleInvoiceListList: jspb.Message.getRepeatedField(msg, 1),
    failedOrderIdsList: jspb.Message.getRepeatedField(msg, 2),
    status: jspb.Message.getFieldWithDefault(msg, 3, false),
    reason: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.BillOrdersResponse}
 */
proto.cbv1.BillOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.BillOrdersResponse;
  return proto.cbv1.BillOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.BillOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.BillOrdersResponse}
 */
proto.cbv1.BillOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSingleInvoiceList(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFailedOrderIds(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.BillOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.BillOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.BillOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BillOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSingleInvoiceListList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getFailedOrderIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * repeated string single_invoice_list = 1;
 * @return {!Array<string>}
 */
proto.cbv1.BillOrdersResponse.prototype.getSingleInvoiceListList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.cbv1.BillOrdersResponse.prototype.setSingleInvoiceListList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.BillOrdersResponse.prototype.addSingleInvoiceList = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.BillOrdersResponse.prototype.clearSingleInvoiceListList = function() {
  this.setSingleInvoiceListList([]);
};


/**
 * repeated string failed_order_ids = 2;
 * @return {!Array<string>}
 */
proto.cbv1.BillOrdersResponse.prototype.getFailedOrderIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<string>} value */
proto.cbv1.BillOrdersResponse.prototype.setFailedOrderIdsList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.BillOrdersResponse.prototype.addFailedOrderIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.cbv1.BillOrdersResponse.prototype.clearFailedOrderIdsList = function() {
  this.setFailedOrderIdsList([]);
};


/**
 * optional bool status = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.BillOrdersResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.cbv1.BillOrdersResponse.prototype.setStatus = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string reason = 4;
 * @return {string}
 */
proto.cbv1.BillOrdersResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.BillOrdersResponse.prototype.setReason = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateBillingOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UpdateBillingOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateBillingOrderRequest.displayName = 'proto.cbv1.UpdateBillingOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateBillingOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateBillingOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateBillingOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateBillingOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fields: (f = msg.getFields()) && proto_cybertron_billing_billing_pb.UpdateNeededFields.toObject(includeInstance, f),
    sendToLmo: jspb.Message.getFieldWithDefault(msg, 2, false),
    isPushbackNeeded: jspb.Message.getFieldWithDefault(msg, 3, false),
    pushbackReason: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateBillingOrderRequest}
 */
proto.cbv1.UpdateBillingOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateBillingOrderRequest;
  return proto.cbv1.UpdateBillingOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateBillingOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateBillingOrderRequest}
 */
proto.cbv1.UpdateBillingOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.UpdateNeededFields;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.UpdateNeededFields.deserializeBinaryFromReader);
      msg.setFields(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSendToLmo(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPushbackNeeded(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPushbackReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateBillingOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateBillingOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateBillingOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateBillingOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFields();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.UpdateNeededFields.serializeBinaryToWriter
    );
  }
  f = message.getSendToLmo();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsPushbackNeeded();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getPushbackReason();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional UpdateNeededFields fields = 1;
 * @return {?proto.cbv1.UpdateNeededFields}
 */
proto.cbv1.UpdateBillingOrderRequest.prototype.getFields = function() {
  return /** @type{?proto.cbv1.UpdateNeededFields} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billing_billing_pb.UpdateNeededFields, 1));
};


/** @param {?proto.cbv1.UpdateNeededFields|undefined} value */
proto.cbv1.UpdateBillingOrderRequest.prototype.setFields = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.UpdateBillingOrderRequest.prototype.clearFields = function() {
  this.setFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.UpdateBillingOrderRequest.prototype.hasFields = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool send_to_lmo = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UpdateBillingOrderRequest.prototype.getSendToLmo = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.UpdateBillingOrderRequest.prototype.setSendToLmo = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool is_pushback_needed = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UpdateBillingOrderRequest.prototype.getIsPushbackNeeded = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.cbv1.UpdateBillingOrderRequest.prototype.setIsPushbackNeeded = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string pushback_reason = 4;
 * @return {string}
 */
proto.cbv1.UpdateBillingOrderRequest.prototype.getPushbackReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.UpdateBillingOrderRequest.prototype.setPushbackReason = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateBillingOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UpdateBillingOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateBillingOrderResponse.displayName = 'proto.cbv1.UpdateBillingOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateBillingOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateBillingOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateBillingOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateBillingOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_billing_billing_pb.BillingOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateBillingOrderResponse}
 */
proto.cbv1.UpdateBillingOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateBillingOrderResponse;
  return proto.cbv1.UpdateBillingOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateBillingOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateBillingOrderResponse}
 */
proto.cbv1.UpdateBillingOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.BillingOrder;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.BillingOrder.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateBillingOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateBillingOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateBillingOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateBillingOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.BillingOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional BillingOrder order = 1;
 * @return {?proto.cbv1.BillingOrder}
 */
proto.cbv1.UpdateBillingOrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.BillingOrder} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billing_billing_pb.BillingOrder, 1));
};


/** @param {?proto.cbv1.BillingOrder|undefined} value */
proto.cbv1.UpdateBillingOrderResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.UpdateBillingOrderResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.UpdateBillingOrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdatedChargeObjListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UpdatedChargeObjListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdatedChargeObjListRequest.displayName = 'proto.cbv1.UpdatedChargeObjListRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdatedChargeObjListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdatedChargeObjListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdatedChargeObjListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdatedChargeObjListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fields: (f = msg.getFields()) && proto_cybertron_billing_billing_pb.UpdateNeededFields.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdatedChargeObjListRequest}
 */
proto.cbv1.UpdatedChargeObjListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdatedChargeObjListRequest;
  return proto.cbv1.UpdatedChargeObjListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdatedChargeObjListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdatedChargeObjListRequest}
 */
proto.cbv1.UpdatedChargeObjListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.UpdateNeededFields;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.UpdateNeededFields.deserializeBinaryFromReader);
      msg.setFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdatedChargeObjListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdatedChargeObjListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdatedChargeObjListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdatedChargeObjListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFields();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.UpdateNeededFields.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateNeededFields fields = 1;
 * @return {?proto.cbv1.UpdateNeededFields}
 */
proto.cbv1.UpdatedChargeObjListRequest.prototype.getFields = function() {
  return /** @type{?proto.cbv1.UpdateNeededFields} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billing_billing_pb.UpdateNeededFields, 1));
};


/** @param {?proto.cbv1.UpdateNeededFields|undefined} value */
proto.cbv1.UpdatedChargeObjListRequest.prototype.setFields = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.UpdatedChargeObjListRequest.prototype.clearFields = function() {
  this.setFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.UpdatedChargeObjListRequest.prototype.hasFields = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdatedChargeObjListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.UpdatedChargeObjListResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.UpdatedChargeObjListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdatedChargeObjListResponse.displayName = 'proto.cbv1.UpdatedChargeObjListResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.UpdatedChargeObjListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdatedChargeObjListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdatedChargeObjListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdatedChargeObjListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdatedChargeObjListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chargesList: jspb.Message.toObjectList(msg.getChargesList(),
    proto_cybertron_billing_billing_pb.Charge.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdatedChargeObjListResponse}
 */
proto.cbv1.UpdatedChargeObjListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdatedChargeObjListResponse;
  return proto.cbv1.UpdatedChargeObjListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdatedChargeObjListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdatedChargeObjListResponse}
 */
proto.cbv1.UpdatedChargeObjListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.Charge;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.Charge.deserializeBinaryFromReader);
      msg.addCharges(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdatedChargeObjListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdatedChargeObjListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdatedChargeObjListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdatedChargeObjListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChargesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.Charge.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Charge charges = 1;
 * @return {!Array<!proto.cbv1.Charge>}
 */
proto.cbv1.UpdatedChargeObjListResponse.prototype.getChargesList = function() {
  return /** @type{!Array<!proto.cbv1.Charge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_billing_billing_pb.Charge, 1));
};


/** @param {!Array<!proto.cbv1.Charge>} value */
proto.cbv1.UpdatedChargeObjListResponse.prototype.setChargesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.Charge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.Charge}
 */
proto.cbv1.UpdatedChargeObjListResponse.prototype.addCharges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.Charge, opt_index);
};


proto.cbv1.UpdatedChargeObjListResponse.prototype.clearChargesList = function() {
  this.setChargesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListOrderChargeTypesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListOrderChargeTypesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListOrderChargeTypesRequest.displayName = 'proto.cbv1.ListOrderChargeTypesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListOrderChargeTypesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListOrderChargeTypesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListOrderChargeTypesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListOrderChargeTypesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListOrderChargeTypesRequest}
 */
proto.cbv1.ListOrderChargeTypesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListOrderChargeTypesRequest;
  return proto.cbv1.ListOrderChargeTypesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListOrderChargeTypesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListOrderChargeTypesRequest}
 */
proto.cbv1.ListOrderChargeTypesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListOrderChargeTypesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListOrderChargeTypesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListOrderChargeTypesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListOrderChargeTypesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 order_id = 1;
 * @return {number}
 */
proto.cbv1.ListOrderChargeTypesRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.ListOrderChargeTypesRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListOrderChargeTypesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListOrderChargeTypesResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListOrderChargeTypesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListOrderChargeTypesResponse.displayName = 'proto.cbv1.ListOrderChargeTypesResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListOrderChargeTypesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListOrderChargeTypesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListOrderChargeTypesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListOrderChargeTypesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListOrderChargeTypesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chargeTypeList: jspb.Message.toObjectList(msg.getChargeTypeList(),
    proto_cybertron_billingsettings_settings_pb.LMOChargeType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListOrderChargeTypesResponse}
 */
proto.cbv1.ListOrderChargeTypesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListOrderChargeTypesResponse;
  return proto.cbv1.ListOrderChargeTypesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListOrderChargeTypesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListOrderChargeTypesResponse}
 */
proto.cbv1.ListOrderChargeTypesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billingsettings_settings_pb.LMOChargeType;
      reader.readMessage(value,proto_cybertron_billingsettings_settings_pb.LMOChargeType.deserializeBinaryFromReader);
      msg.addChargeType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListOrderChargeTypesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListOrderChargeTypesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListOrderChargeTypesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListOrderChargeTypesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChargeTypeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_billingsettings_settings_pb.LMOChargeType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LMOChargeType charge_type = 1;
 * @return {!Array<!proto.cbv1.LMOChargeType>}
 */
proto.cbv1.ListOrderChargeTypesResponse.prototype.getChargeTypeList = function() {
  return /** @type{!Array<!proto.cbv1.LMOChargeType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_billingsettings_settings_pb.LMOChargeType, 1));
};


/** @param {!Array<!proto.cbv1.LMOChargeType>} value */
proto.cbv1.ListOrderChargeTypesResponse.prototype.setChargeTypeList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.LMOChargeType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.LMOChargeType}
 */
proto.cbv1.ListOrderChargeTypesResponse.prototype.addChargeType = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.LMOChargeType, opt_index);
};


proto.cbv1.ListOrderChargeTypesResponse.prototype.clearChargeTypeList = function() {
  this.setChargeTypeList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.BackToDispatcherRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.BackToDispatcherRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.BackToDispatcherRequest.displayName = 'proto.cbv1.BackToDispatcherRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.BackToDispatcherRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.BackToDispatcherRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.BackToDispatcherRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BackToDispatcherRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pushbackReason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.BackToDispatcherRequest}
 */
proto.cbv1.BackToDispatcherRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.BackToDispatcherRequest;
  return proto.cbv1.BackToDispatcherRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.BackToDispatcherRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.BackToDispatcherRequest}
 */
proto.cbv1.BackToDispatcherRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPushbackReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.BackToDispatcherRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.BackToDispatcherRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.BackToDispatcherRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BackToDispatcherRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getPushbackReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 order_id = 1;
 * @return {number}
 */
proto.cbv1.BackToDispatcherRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.BackToDispatcherRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string pushback_reason = 2;
 * @return {string}
 */
proto.cbv1.BackToDispatcherRequest.prototype.getPushbackReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.BackToDispatcherRequest.prototype.setPushbackReason = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.BackToDispatcherResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.BackToDispatcherResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.BackToDispatcherResponse.displayName = 'proto.cbv1.BackToDispatcherResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.BackToDispatcherResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.BackToDispatcherResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.BackToDispatcherResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BackToDispatcherResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getFieldWithDefault(msg, 1, false),
    status: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.BackToDispatcherResponse}
 */
proto.cbv1.BackToDispatcherResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.BackToDispatcherResponse;
  return proto.cbv1.BackToDispatcherResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.BackToDispatcherResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.BackToDispatcherResponse}
 */
proto.cbv1.BackToDispatcherResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.BackToDispatcherResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.BackToDispatcherResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.BackToDispatcherResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BackToDispatcherResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.BackToDispatcherResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.BackToDispatcherResponse.prototype.setSuccess = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string status = 2;
 * @return {string}
 */
proto.cbv1.BackToDispatcherResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.BackToDispatcherResponse.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListBatchInvoicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListBatchInvoicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListBatchInvoicesRequest.displayName = 'proto.cbv1.ListBatchInvoicesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListBatchInvoicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListBatchInvoicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListBatchInvoicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListBatchInvoicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    commonFilters: (f = msg.getCommonFilters()) && proto_cybertron_billing_billing_pb.CommonFilters.toObject(includeInstance, f),
    approved: jspb.Message.getFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListBatchInvoicesRequest}
 */
proto.cbv1.ListBatchInvoicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListBatchInvoicesRequest;
  return proto.cbv1.ListBatchInvoicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListBatchInvoicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListBatchInvoicesRequest}
 */
proto.cbv1.ListBatchInvoicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.CommonFilters;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.CommonFilters.deserializeBinaryFromReader);
      msg.setCommonFilters(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApproved(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListBatchInvoicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListBatchInvoicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListBatchInvoicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListBatchInvoicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCommonFilters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.CommonFilters.serializeBinaryToWriter
    );
  }
  f = message.getApproved();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional CommonFilters common_filters = 1;
 * @return {?proto.cbv1.CommonFilters}
 */
proto.cbv1.ListBatchInvoicesRequest.prototype.getCommonFilters = function() {
  return /** @type{?proto.cbv1.CommonFilters} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billing_billing_pb.CommonFilters, 1));
};


/** @param {?proto.cbv1.CommonFilters|undefined} value */
proto.cbv1.ListBatchInvoicesRequest.prototype.setCommonFilters = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.ListBatchInvoicesRequest.prototype.clearCommonFilters = function() {
  this.setCommonFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.ListBatchInvoicesRequest.prototype.hasCommonFilters = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool approved = 2;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.ListBatchInvoicesRequest.prototype.getApproved = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 2, false));
};


/** @param {boolean} value */
proto.cbv1.ListBatchInvoicesRequest.prototype.setApproved = function(value) {
  jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListBatchInvoicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListBatchInvoicesResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListBatchInvoicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListBatchInvoicesResponse.displayName = 'proto.cbv1.ListBatchInvoicesResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListBatchInvoicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListBatchInvoicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListBatchInvoicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListBatchInvoicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListBatchInvoicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoicesList: jspb.Message.toObjectList(msg.getInvoicesList(),
    proto_cybertron_billing_billing_pb.batchInvoice.toObject, includeInstance),
    totalCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListBatchInvoicesResponse}
 */
proto.cbv1.ListBatchInvoicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListBatchInvoicesResponse;
  return proto.cbv1.ListBatchInvoicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListBatchInvoicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListBatchInvoicesResponse}
 */
proto.cbv1.ListBatchInvoicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.batchInvoice;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.batchInvoice.deserializeBinaryFromReader);
      msg.addInvoices(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListBatchInvoicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListBatchInvoicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListBatchInvoicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListBatchInvoicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.batchInvoice.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated batchInvoice invoices = 1;
 * @return {!Array<!proto.cbv1.batchInvoice>}
 */
proto.cbv1.ListBatchInvoicesResponse.prototype.getInvoicesList = function() {
  return /** @type{!Array<!proto.cbv1.batchInvoice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_billing_billing_pb.batchInvoice, 1));
};


/** @param {!Array<!proto.cbv1.batchInvoice>} value */
proto.cbv1.ListBatchInvoicesResponse.prototype.setInvoicesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.batchInvoice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.batchInvoice}
 */
proto.cbv1.ListBatchInvoicesResponse.prototype.addInvoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.batchInvoice, opt_index);
};


proto.cbv1.ListBatchInvoicesResponse.prototype.clearInvoicesList = function() {
  this.setInvoicesList([]);
};


/**
 * optional int64 total_count = 2;
 * @return {number}
 */
proto.cbv1.ListBatchInvoicesResponse.prototype.getTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.ListBatchInvoicesResponse.prototype.setTotalCount = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListBatchedOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListBatchedOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListBatchedOrdersRequest.displayName = 'proto.cbv1.ListBatchedOrdersRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListBatchedOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListBatchedOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListBatchedOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListBatchedOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vendorId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lmoId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListBatchedOrdersRequest}
 */
proto.cbv1.ListBatchedOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListBatchedOrdersRequest;
  return proto.cbv1.ListBatchedOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListBatchedOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListBatchedOrdersRequest}
 */
proto.cbv1.ListBatchedOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVendorId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLmoId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListBatchedOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListBatchedOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListBatchedOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListBatchedOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVendorId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLmoId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 vendor_id = 1;
 * @return {number}
 */
proto.cbv1.ListBatchedOrdersRequest.prototype.getVendorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.ListBatchedOrdersRequest.prototype.setVendorId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string invoice_number = 2;
 * @return {string}
 */
proto.cbv1.ListBatchedOrdersRequest.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.ListBatchedOrdersRequest.prototype.setInvoiceNumber = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 lmo_id = 3;
 * @return {number}
 */
proto.cbv1.ListBatchedOrdersRequest.prototype.getLmoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.ListBatchedOrdersRequest.prototype.setLmoId = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListBatchedOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListBatchedOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListBatchedOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListBatchedOrdersResponse.displayName = 'proto.cbv1.ListBatchedOrdersResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListBatchedOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListBatchedOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListBatchedOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListBatchedOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListBatchedOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ordersList: jspb.Message.toObjectList(msg.getOrdersList(),
    proto_cybertron_billing_billing_pb.BillingListOrder.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListBatchedOrdersResponse}
 */
proto.cbv1.ListBatchedOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListBatchedOrdersResponse;
  return proto.cbv1.ListBatchedOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListBatchedOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListBatchedOrdersResponse}
 */
proto.cbv1.ListBatchedOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.BillingListOrder;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.BillingListOrder.deserializeBinaryFromReader);
      msg.addOrders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListBatchedOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListBatchedOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListBatchedOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListBatchedOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrdersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.BillingListOrder.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BillingListOrder orders = 1;
 * @return {!Array<!proto.cbv1.BillingListOrder>}
 */
proto.cbv1.ListBatchedOrdersResponse.prototype.getOrdersList = function() {
  return /** @type{!Array<!proto.cbv1.BillingListOrder>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_billing_billing_pb.BillingListOrder, 1));
};


/** @param {!Array<!proto.cbv1.BillingListOrder>} value */
proto.cbv1.ListBatchedOrdersResponse.prototype.setOrdersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.BillingListOrder=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.BillingListOrder}
 */
proto.cbv1.ListBatchedOrdersResponse.prototype.addOrders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.BillingListOrder, opt_index);
};


proto.cbv1.ListBatchedOrdersResponse.prototype.clearOrdersList = function() {
  this.setOrdersList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.Invoice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.Invoice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.Invoice.displayName = 'proto.cbv1.Invoice';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.Invoice.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.Invoice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.Invoice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Invoice.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    vendorId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.Invoice}
 */
proto.cbv1.Invoice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.Invoice;
  return proto.cbv1.Invoice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.Invoice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.Invoice}
 */
proto.cbv1.Invoice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVendorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.Invoice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.Invoice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.Invoice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Invoice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getVendorId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional string invoice_number = 1;
 * @return {string}
 */
proto.cbv1.Invoice.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.Invoice.prototype.setInvoiceNumber = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 vendor_id = 2;
 * @return {number}
 */
proto.cbv1.Invoice.prototype.getVendorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.Invoice.prototype.setVendorId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ApproveInvoicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ApproveInvoicesRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ApproveInvoicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ApproveInvoicesRequest.displayName = 'proto.cbv1.ApproveInvoicesRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ApproveInvoicesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ApproveInvoicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ApproveInvoicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ApproveInvoicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ApproveInvoicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoicesList: jspb.Message.toObjectList(msg.getInvoicesList(),
    proto.cbv1.Invoice.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ApproveInvoicesRequest}
 */
proto.cbv1.ApproveInvoicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ApproveInvoicesRequest;
  return proto.cbv1.ApproveInvoicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ApproveInvoicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ApproveInvoicesRequest}
 */
proto.cbv1.ApproveInvoicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cbv1.Invoice;
      reader.readMessage(value,proto.cbv1.Invoice.deserializeBinaryFromReader);
      msg.addInvoices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ApproveInvoicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ApproveInvoicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ApproveInvoicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ApproveInvoicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cbv1.Invoice.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Invoice invoices = 1;
 * @return {!Array<!proto.cbv1.Invoice>}
 */
proto.cbv1.ApproveInvoicesRequest.prototype.getInvoicesList = function() {
  return /** @type{!Array<!proto.cbv1.Invoice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.Invoice, 1));
};


/** @param {!Array<!proto.cbv1.Invoice>} value */
proto.cbv1.ApproveInvoicesRequest.prototype.setInvoicesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.Invoice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.Invoice}
 */
proto.cbv1.ApproveInvoicesRequest.prototype.addInvoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.Invoice, opt_index);
};


proto.cbv1.ApproveInvoicesRequest.prototype.clearInvoicesList = function() {
  this.setInvoicesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ApproveInvoicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ApproveInvoicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ApproveInvoicesResponse.displayName = 'proto.cbv1.ApproveInvoicesResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ApproveInvoicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ApproveInvoicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ApproveInvoicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ApproveInvoicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, false),
    reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ApproveInvoicesResponse}
 */
proto.cbv1.ApproveInvoicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ApproveInvoicesResponse;
  return proto.cbv1.ApproveInvoicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ApproveInvoicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ApproveInvoicesResponse}
 */
proto.cbv1.ApproveInvoicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ApproveInvoicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ApproveInvoicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ApproveInvoicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ApproveInvoicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.ApproveInvoicesResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.ApproveInvoicesResponse.prototype.setStatus = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.cbv1.ApproveInvoicesResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.ApproveInvoicesResponse.prototype.setReason = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UnApproveInvoicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UnApproveInvoicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UnApproveInvoicesRequest.displayName = 'proto.cbv1.UnApproveInvoicesRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UnApproveInvoicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UnApproveInvoicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UnApproveInvoicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UnApproveInvoicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoice: (f = msg.getInvoice()) && proto.cbv1.Invoice.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UnApproveInvoicesRequest}
 */
proto.cbv1.UnApproveInvoicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UnApproveInvoicesRequest;
  return proto.cbv1.UnApproveInvoicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UnApproveInvoicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UnApproveInvoicesRequest}
 */
proto.cbv1.UnApproveInvoicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cbv1.Invoice;
      reader.readMessage(value,proto.cbv1.Invoice.deserializeBinaryFromReader);
      msg.setInvoice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UnApproveInvoicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UnApproveInvoicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UnApproveInvoicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UnApproveInvoicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.cbv1.Invoice.serializeBinaryToWriter
    );
  }
};


/**
 * optional Invoice invoice = 1;
 * @return {?proto.cbv1.Invoice}
 */
proto.cbv1.UnApproveInvoicesRequest.prototype.getInvoice = function() {
  return /** @type{?proto.cbv1.Invoice} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.Invoice, 1));
};


/** @param {?proto.cbv1.Invoice|undefined} value */
proto.cbv1.UnApproveInvoicesRequest.prototype.setInvoice = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.UnApproveInvoicesRequest.prototype.clearInvoice = function() {
  this.setInvoice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.UnApproveInvoicesRequest.prototype.hasInvoice = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UnApproveInvoicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UnApproveInvoicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UnApproveInvoicesResponse.displayName = 'proto.cbv1.UnApproveInvoicesResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UnApproveInvoicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UnApproveInvoicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UnApproveInvoicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UnApproveInvoicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, false),
    reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UnApproveInvoicesResponse}
 */
proto.cbv1.UnApproveInvoicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UnApproveInvoicesResponse;
  return proto.cbv1.UnApproveInvoicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UnApproveInvoicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UnApproveInvoicesResponse}
 */
proto.cbv1.UnApproveInvoicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UnApproveInvoicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UnApproveInvoicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UnApproveInvoicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UnApproveInvoicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.UnApproveInvoicesResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.UnApproveInvoicesResponse.prototype.setStatus = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.cbv1.UnApproveInvoicesResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.UnApproveInvoicesResponse.prototype.setReason = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.RejectInvoicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.RejectInvoicesRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.RejectInvoicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.RejectInvoicesRequest.displayName = 'proto.cbv1.RejectInvoicesRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.RejectInvoicesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.RejectInvoicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.RejectInvoicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.RejectInvoicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.RejectInvoicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    invoicesList: jspb.Message.toObjectList(msg.getInvoicesList(),
    proto.cbv1.Invoice.toObject, includeInstance),
    pushbackReason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.RejectInvoicesRequest}
 */
proto.cbv1.RejectInvoicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.RejectInvoicesRequest;
  return proto.cbv1.RejectInvoicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.RejectInvoicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.RejectInvoicesRequest}
 */
proto.cbv1.RejectInvoicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cbv1.Invoice;
      reader.readMessage(value,proto.cbv1.Invoice.deserializeBinaryFromReader);
      msg.addInvoices(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPushbackReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.RejectInvoicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.RejectInvoicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.RejectInvoicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.RejectInvoicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInvoicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cbv1.Invoice.serializeBinaryToWriter
    );
  }
  f = message.getPushbackReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * repeated Invoice invoices = 1;
 * @return {!Array<!proto.cbv1.Invoice>}
 */
proto.cbv1.RejectInvoicesRequest.prototype.getInvoicesList = function() {
  return /** @type{!Array<!proto.cbv1.Invoice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.Invoice, 1));
};


/** @param {!Array<!proto.cbv1.Invoice>} value */
proto.cbv1.RejectInvoicesRequest.prototype.setInvoicesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.Invoice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.Invoice}
 */
proto.cbv1.RejectInvoicesRequest.prototype.addInvoices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.Invoice, opt_index);
};


proto.cbv1.RejectInvoicesRequest.prototype.clearInvoicesList = function() {
  this.setInvoicesList([]);
};


/**
 * optional string pushback_reason = 2;
 * @return {string}
 */
proto.cbv1.RejectInvoicesRequest.prototype.getPushbackReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.RejectInvoicesRequest.prototype.setPushbackReason = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.RejectInvoicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.RejectInvoicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.RejectInvoicesResponse.displayName = 'proto.cbv1.RejectInvoicesResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.RejectInvoicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.RejectInvoicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.RejectInvoicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.RejectInvoicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, false),
    reason: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.RejectInvoicesResponse}
 */
proto.cbv1.RejectInvoicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.RejectInvoicesResponse;
  return proto.cbv1.RejectInvoicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.RejectInvoicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.RejectInvoicesResponse}
 */
proto.cbv1.RejectInvoicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.RejectInvoicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.RejectInvoicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.RejectInvoicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.RejectInvoicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.RejectInvoicesResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.RejectInvoicesResponse.prototype.setStatus = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string reason = 2;
 * @return {string}
 */
proto.cbv1.RejectInvoicesResponse.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.RejectInvoicesResponse.prototype.setReason = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListAccountsForOrdersRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListAccountsForOrdersRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListAccountsForOrdersRequest.displayName = 'proto.cbv1.ListAccountsForOrdersRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListAccountsForOrdersRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListAccountsForOrdersRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListAccountsForOrdersRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListAccountsForOrdersRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountType: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListAccountsForOrdersRequest}
 */
proto.cbv1.ListAccountsForOrdersRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListAccountsForOrdersRequest;
  return proto.cbv1.ListAccountsForOrdersRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListAccountsForOrdersRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListAccountsForOrdersRequest}
 */
proto.cbv1.ListAccountsForOrdersRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cbv1.AccountType} */ (reader.readEnum());
      msg.setAccountType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListAccountsForOrdersRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListAccountsForOrdersRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListAccountsForOrdersRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListAccountsForOrdersRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional AccountType account_type = 1;
 * @return {!proto.cbv1.AccountType}
 */
proto.cbv1.ListAccountsForOrdersRequest.prototype.getAccountType = function() {
  return /** @type {!proto.cbv1.AccountType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.cbv1.AccountType} value */
proto.cbv1.ListAccountsForOrdersRequest.prototype.setAccountType = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListAccountsForOrdersResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListAccountsForOrdersResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListAccountsForOrdersResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListAccountsForOrdersResponse.displayName = 'proto.cbv1.ListAccountsForOrdersResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListAccountsForOrdersResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListAccountsForOrdersResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListAccountsForOrdersResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListAccountsForOrdersResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListAccountsForOrdersResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto_cybertron_account_account_pb.Account.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListAccountsForOrdersResponse}
 */
proto.cbv1.ListAccountsForOrdersResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListAccountsForOrdersResponse;
  return proto.cbv1.ListAccountsForOrdersResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListAccountsForOrdersResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListAccountsForOrdersResponse}
 */
proto.cbv1.ListAccountsForOrdersResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_account_account_pb.Account;
      reader.readMessage(value,proto_cybertron_account_account_pb.Account.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListAccountsForOrdersResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListAccountsForOrdersResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListAccountsForOrdersResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListAccountsForOrdersResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_account_account_pb.Account.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Account accounts = 1;
 * @return {!Array<!proto.cbv1.Account>}
 */
proto.cbv1.ListAccountsForOrdersResponse.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.cbv1.Account>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_account_account_pb.Account, 1));
};


/** @param {!Array<!proto.cbv1.Account>} value */
proto.cbv1.ListAccountsForOrdersResponse.prototype.setAccountsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.Account=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.Account}
 */
proto.cbv1.ListAccountsForOrdersResponse.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.Account, opt_index);
};


proto.cbv1.ListAccountsForOrdersResponse.prototype.clearAccountsList = function() {
  this.setAccountsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.NeedsPushbackRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.NeedsPushbackRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.NeedsPushbackRequest.displayName = 'proto.cbv1.NeedsPushbackRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.NeedsPushbackRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.NeedsPushbackRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.NeedsPushbackRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NeedsPushbackRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fields: (f = msg.getFields()) && proto_cybertron_billing_billing_pb.UpdateNeededFields.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.NeedsPushbackRequest}
 */
proto.cbv1.NeedsPushbackRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.NeedsPushbackRequest;
  return proto.cbv1.NeedsPushbackRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.NeedsPushbackRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.NeedsPushbackRequest}
 */
proto.cbv1.NeedsPushbackRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.UpdateNeededFields;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.UpdateNeededFields.deserializeBinaryFromReader);
      msg.setFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.NeedsPushbackRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.NeedsPushbackRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.NeedsPushbackRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NeedsPushbackRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFields();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.UpdateNeededFields.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateNeededFields fields = 1;
 * @return {?proto.cbv1.UpdateNeededFields}
 */
proto.cbv1.NeedsPushbackRequest.prototype.getFields = function() {
  return /** @type{?proto.cbv1.UpdateNeededFields} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billing_billing_pb.UpdateNeededFields, 1));
};


/** @param {?proto.cbv1.UpdateNeededFields|undefined} value */
proto.cbv1.NeedsPushbackRequest.prototype.setFields = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.NeedsPushbackRequest.prototype.clearFields = function() {
  this.setFields(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.NeedsPushbackRequest.prototype.hasFields = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.NeedsPushbackResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.NeedsPushbackResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.NeedsPushbackResponse.displayName = 'proto.cbv1.NeedsPushbackResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.NeedsPushbackResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.NeedsPushbackResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.NeedsPushbackResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NeedsPushbackResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    needsPushback: jspb.Message.getFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.NeedsPushbackResponse}
 */
proto.cbv1.NeedsPushbackResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.NeedsPushbackResponse;
  return proto.cbv1.NeedsPushbackResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.NeedsPushbackResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.NeedsPushbackResponse}
 */
proto.cbv1.NeedsPushbackResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNeedsPushback(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.NeedsPushbackResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.NeedsPushbackResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.NeedsPushbackResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NeedsPushbackResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNeedsPushback();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool needs_pushback = 1;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.NeedsPushbackResponse.prototype.getNeedsPushback = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 1, false));
};


/** @param {boolean} value */
proto.cbv1.NeedsPushbackResponse.prototype.setNeedsPushback = function(value) {
  jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.OrdersCountPerStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.OrdersCountPerStatusRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.OrdersCountPerStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.OrdersCountPerStatusRequest.displayName = 'proto.cbv1.OrdersCountPerStatusRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.OrdersCountPerStatusRequest.repeatedFields_ = [1,2,3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.OrdersCountPerStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.OrdersCountPerStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.OrdersCountPerStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrdersCountPerStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    vendorIdsList: jspb.Message.getRepeatedField(msg, 1),
    payloadIdsList: jspb.Message.getRepeatedField(msg, 2),
    districtIdsList: jspb.Message.getRepeatedField(msg, 3),
    costCenterIdsList: jspb.Message.getRepeatedField(msg, 4),
    siteIdsList: jspb.Message.getRepeatedField(msg, 5),
    lmoIdsList: jspb.Message.getRepeatedField(msg, 6),
    orderedAfterUnix: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.OrdersCountPerStatusRequest}
 */
proto.cbv1.OrdersCountPerStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.OrdersCountPerStatusRequest;
  return proto.cbv1.OrdersCountPerStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.OrdersCountPerStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.OrdersCountPerStatusRequest}
 */
proto.cbv1.OrdersCountPerStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setVendorIdsList(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setPayloadIdsList(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setDistrictIdsList(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setCostCenterIdsList(value);
      break;
    case 5:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setSiteIdsList(value);
      break;
    case 6:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setLmoIdsList(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderedAfterUnix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.OrdersCountPerStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.OrdersCountPerStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.OrdersCountPerStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrdersCountPerStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVendorIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getPayloadIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
  f = message.getDistrictIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      3,
      f
    );
  }
  f = message.getCostCenterIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      4,
      f
    );
  }
  f = message.getSiteIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      5,
      f
    );
  }
  f = message.getLmoIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
  f = message.getOrderedAfterUnix();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * repeated int64 vendor_ids = 1;
 * @return {!Array<number>}
 */
proto.cbv1.OrdersCountPerStatusRequest.prototype.getVendorIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<number>} value */
proto.cbv1.OrdersCountPerStatusRequest.prototype.setVendorIdsList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.OrdersCountPerStatusRequest.prototype.addVendorIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.OrdersCountPerStatusRequest.prototype.clearVendorIdsList = function() {
  this.setVendorIdsList([]);
};


/**
 * repeated int64 payload_ids = 2;
 * @return {!Array<number>}
 */
proto.cbv1.OrdersCountPerStatusRequest.prototype.getPayloadIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<number>} value */
proto.cbv1.OrdersCountPerStatusRequest.prototype.setPayloadIdsList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.OrdersCountPerStatusRequest.prototype.addPayloadIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.cbv1.OrdersCountPerStatusRequest.prototype.clearPayloadIdsList = function() {
  this.setPayloadIdsList([]);
};


/**
 * repeated int64 district_ids = 3;
 * @return {!Array<number>}
 */
proto.cbv1.OrdersCountPerStatusRequest.prototype.getDistrictIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/** @param {!Array<number>} value */
proto.cbv1.OrdersCountPerStatusRequest.prototype.setDistrictIdsList = function(value) {
  jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.OrdersCountPerStatusRequest.prototype.addDistrictIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


proto.cbv1.OrdersCountPerStatusRequest.prototype.clearDistrictIdsList = function() {
  this.setDistrictIdsList([]);
};


/**
 * repeated int64 cost_center_ids = 4;
 * @return {!Array<number>}
 */
proto.cbv1.OrdersCountPerStatusRequest.prototype.getCostCenterIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/** @param {!Array<number>} value */
proto.cbv1.OrdersCountPerStatusRequest.prototype.setCostCenterIdsList = function(value) {
  jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.OrdersCountPerStatusRequest.prototype.addCostCenterIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


proto.cbv1.OrdersCountPerStatusRequest.prototype.clearCostCenterIdsList = function() {
  this.setCostCenterIdsList([]);
};


/**
 * repeated int64 site_ids = 5;
 * @return {!Array<number>}
 */
proto.cbv1.OrdersCountPerStatusRequest.prototype.getSiteIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/** @param {!Array<number>} value */
proto.cbv1.OrdersCountPerStatusRequest.prototype.setSiteIdsList = function(value) {
  jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.OrdersCountPerStatusRequest.prototype.addSiteIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


proto.cbv1.OrdersCountPerStatusRequest.prototype.clearSiteIdsList = function() {
  this.setSiteIdsList([]);
};


/**
 * repeated int64 lmo_ids = 6;
 * @return {!Array<number>}
 */
proto.cbv1.OrdersCountPerStatusRequest.prototype.getLmoIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/** @param {!Array<number>} value */
proto.cbv1.OrdersCountPerStatusRequest.prototype.setLmoIdsList = function(value) {
  jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.OrdersCountPerStatusRequest.prototype.addLmoIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


proto.cbv1.OrdersCountPerStatusRequest.prototype.clearLmoIdsList = function() {
  this.setLmoIdsList([]);
};


/**
 * optional int64 ordered_after_unix = 7;
 * @return {number}
 */
proto.cbv1.OrdersCountPerStatusRequest.prototype.getOrderedAfterUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.OrdersCountPerStatusRequest.prototype.setOrderedAfterUnix = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.OrdersCountPerStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.OrdersCountPerStatusResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.OrdersCountPerStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.OrdersCountPerStatusResponse.displayName = 'proto.cbv1.OrdersCountPerStatusResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.OrdersCountPerStatusResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.OrdersCountPerStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.OrdersCountPerStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.OrdersCountPerStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrdersCountPerStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderCountsList: jspb.Message.toObjectList(msg.getOrderCountsList(),
    proto.cbv1.OrdersCountPerStatusResponse.OrderCount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.OrdersCountPerStatusResponse}
 */
proto.cbv1.OrdersCountPerStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.OrdersCountPerStatusResponse;
  return proto.cbv1.OrdersCountPerStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.OrdersCountPerStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.OrdersCountPerStatusResponse}
 */
proto.cbv1.OrdersCountPerStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.cbv1.OrdersCountPerStatusResponse.OrderCount;
      reader.readMessage(value,proto.cbv1.OrdersCountPerStatusResponse.OrderCount.deserializeBinaryFromReader);
      msg.addOrderCounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.OrdersCountPerStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.OrdersCountPerStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.OrdersCountPerStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrdersCountPerStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderCountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.cbv1.OrdersCountPerStatusResponse.OrderCount.serializeBinaryToWriter
    );
  }
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.OrdersCountPerStatusResponse.OrderCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.OrdersCountPerStatusResponse.OrderCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.OrdersCountPerStatusResponse.OrderCount.displayName = 'proto.cbv1.OrdersCountPerStatusResponse.OrderCount';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.OrdersCountPerStatusResponse.OrderCount.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.OrdersCountPerStatusResponse.OrderCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.OrdersCountPerStatusResponse.OrderCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrdersCountPerStatusResponse.OrderCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.OrdersCountPerStatusResponse.OrderCount}
 */
proto.cbv1.OrdersCountPerStatusResponse.OrderCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.OrdersCountPerStatusResponse.OrderCount;
  return proto.cbv1.OrdersCountPerStatusResponse.OrderCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.OrdersCountPerStatusResponse.OrderCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.OrdersCountPerStatusResponse.OrderCount}
 */
proto.cbv1.OrdersCountPerStatusResponse.OrderCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cbv1.BillingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.OrdersCountPerStatusResponse.OrderCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.OrdersCountPerStatusResponse.OrderCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.OrdersCountPerStatusResponse.OrderCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrdersCountPerStatusResponse.OrderCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional BillingStatus status = 1;
 * @return {!proto.cbv1.BillingStatus}
 */
proto.cbv1.OrdersCountPerStatusResponse.OrderCount.prototype.getStatus = function() {
  return /** @type {!proto.cbv1.BillingStatus} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.cbv1.BillingStatus} value */
proto.cbv1.OrdersCountPerStatusResponse.OrderCount.prototype.setStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 count = 2;
 * @return {number}
 */
proto.cbv1.OrdersCountPerStatusResponse.OrderCount.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.OrdersCountPerStatusResponse.OrderCount.prototype.setCount = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated OrderCount order_counts = 1;
 * @return {!Array<!proto.cbv1.OrdersCountPerStatusResponse.OrderCount>}
 */
proto.cbv1.OrdersCountPerStatusResponse.prototype.getOrderCountsList = function() {
  return /** @type{!Array<!proto.cbv1.OrdersCountPerStatusResponse.OrderCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.OrdersCountPerStatusResponse.OrderCount, 1));
};


/** @param {!Array<!proto.cbv1.OrdersCountPerStatusResponse.OrderCount>} value */
proto.cbv1.OrdersCountPerStatusResponse.prototype.setOrderCountsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.OrdersCountPerStatusResponse.OrderCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.OrdersCountPerStatusResponse.OrderCount}
 */
proto.cbv1.OrdersCountPerStatusResponse.prototype.addOrderCounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.OrdersCountPerStatusResponse.OrderCount, opt_index);
};


proto.cbv1.OrdersCountPerStatusResponse.prototype.clearOrderCountsList = function() {
  this.setOrderCountsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListContractsForOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListContractsForOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListContractsForOrderRequest.displayName = 'proto.cbv1.ListContractsForOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListContractsForOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListContractsForOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListContractsForOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListContractsForOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListContractsForOrderRequest}
 */
proto.cbv1.ListContractsForOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListContractsForOrderRequest;
  return proto.cbv1.ListContractsForOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListContractsForOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListContractsForOrderRequest}
 */
proto.cbv1.ListContractsForOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListContractsForOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListContractsForOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListContractsForOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListContractsForOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 order_id = 1;
 * @return {number}
 */
proto.cbv1.ListContractsForOrderRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.ListContractsForOrderRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListContractsForOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListContractsForOrderResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListContractsForOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListContractsForOrderResponse.displayName = 'proto.cbv1.ListContractsForOrderResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListContractsForOrderResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListContractsForOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListContractsForOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListContractsForOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListContractsForOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    currentContractList: jspb.Message.toObjectList(msg.getCurrentContractList(),
    proto_cybertron_contracts_contracts_pb.VendorContract.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListContractsForOrderResponse}
 */
proto.cbv1.ListContractsForOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListContractsForOrderResponse;
  return proto.cbv1.ListContractsForOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListContractsForOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListContractsForOrderResponse}
 */
proto.cbv1.ListContractsForOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_contracts_contracts_pb.VendorContract;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.VendorContract.deserializeBinaryFromReader);
      msg.addCurrentContract(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListContractsForOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListContractsForOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListContractsForOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListContractsForOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrentContractList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_contracts_contracts_pb.VendorContract.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VendorContract current_contract = 1;
 * @return {!Array<!proto.cbv1.VendorContract>}
 */
proto.cbv1.ListContractsForOrderResponse.prototype.getCurrentContractList = function() {
  return /** @type{!Array<!proto.cbv1.VendorContract>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_contracts_contracts_pb.VendorContract, 1));
};


/** @param {!Array<!proto.cbv1.VendorContract>} value */
proto.cbv1.ListContractsForOrderResponse.prototype.setCurrentContractList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.VendorContract=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.VendorContract}
 */
proto.cbv1.ListContractsForOrderResponse.prototype.addCurrentContract = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.VendorContract, opt_index);
};


proto.cbv1.ListContractsForOrderResponse.prototype.clearCurrentContractList = function() {
  this.setCurrentContractList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SwitchContractForOrderRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SwitchContractForOrderRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SwitchContractForOrderRequest.displayName = 'proto.cbv1.SwitchContractForOrderRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SwitchContractForOrderRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SwitchContractForOrderRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SwitchContractForOrderRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SwitchContractForOrderRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    contractId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    canUpdate: jspb.Message.getFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SwitchContractForOrderRequest}
 */
proto.cbv1.SwitchContractForOrderRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SwitchContractForOrderRequest;
  return proto.cbv1.SwitchContractForOrderRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SwitchContractForOrderRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SwitchContractForOrderRequest}
 */
proto.cbv1.SwitchContractForOrderRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setContractId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanUpdate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SwitchContractForOrderRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SwitchContractForOrderRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SwitchContractForOrderRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SwitchContractForOrderRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getContractId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getCanUpdate();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int64 order_id = 1;
 * @return {number}
 */
proto.cbv1.SwitchContractForOrderRequest.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.SwitchContractForOrderRequest.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 contract_id = 2;
 * @return {number}
 */
proto.cbv1.SwitchContractForOrderRequest.prototype.getContractId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.SwitchContractForOrderRequest.prototype.setContractId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool can_update = 3;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.SwitchContractForOrderRequest.prototype.getCanUpdate = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 3, false));
};


/** @param {boolean} value */
proto.cbv1.SwitchContractForOrderRequest.prototype.setCanUpdate = function(value) {
  jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SwitchContractForOrderResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SwitchContractForOrderResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SwitchContractForOrderResponse.displayName = 'proto.cbv1.SwitchContractForOrderResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SwitchContractForOrderResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SwitchContractForOrderResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SwitchContractForOrderResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SwitchContractForOrderResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    order: (f = msg.getOrder()) && proto_cybertron_billing_billing_pb.BillingOrder.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SwitchContractForOrderResponse}
 */
proto.cbv1.SwitchContractForOrderResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SwitchContractForOrderResponse;
  return proto.cbv1.SwitchContractForOrderResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SwitchContractForOrderResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SwitchContractForOrderResponse}
 */
proto.cbv1.SwitchContractForOrderResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billing_billing_pb.BillingOrder;
      reader.readMessage(value,proto_cybertron_billing_billing_pb.BillingOrder.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SwitchContractForOrderResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SwitchContractForOrderResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SwitchContractForOrderResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SwitchContractForOrderResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_billing_billing_pb.BillingOrder.serializeBinaryToWriter
    );
  }
};


/**
 * optional BillingOrder order = 1;
 * @return {?proto.cbv1.BillingOrder}
 */
proto.cbv1.SwitchContractForOrderResponse.prototype.getOrder = function() {
  return /** @type{?proto.cbv1.BillingOrder} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billing_billing_pb.BillingOrder, 1));
};


/** @param {?proto.cbv1.BillingOrder|undefined} value */
proto.cbv1.SwitchContractForOrderResponse.prototype.setOrder = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.SwitchContractForOrderResponse.prototype.clearOrder = function() {
  this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.SwitchContractForOrderResponse.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.cbv1);
