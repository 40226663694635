import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, share, tap } from 'rxjs/operators';
import { environment } from '~environments/environment';
import {
  ArchiveFuelSurchargeContractRequest,
  ArchiveFuelSurchargeContractResponse,
  DownloadFuelCostFileRequest,
  DownloadFuelCostFileResponse,
  ListContractTypeRequest,
  ListContractTypeResponse,
  ListFuelSurchargeBracketsRequest,
  ListFuelSurchargeBracketsResponse,
  ListFuelSurchargeContractsRequest,
  ListFuelSurchargeContractsResponse,
} from '~proto/contracts/contracts_api_pb';
import { ContractsAPI } from '~proto/contracts/contracts_api_pb_service';
import { FuelSurchargeContract } from '~proto/contracts/contracts_pb';
import { AuthService } from '~services/auth.service';
import { GrpcService } from '~services/grpc.service';

@Injectable({
  providedIn: 'root',
})
export class FuelSurchargeService {
  private fuelBrackets$$ = new BehaviorSubject<ListFuelSurchargeBracketsResponse.AsObject>(null);
  private fuelContracts$$ = new BehaviorSubject<ListFuelSurchargeContractsResponse.AsObject>(null);
  private vendorContractsList$$ = new BehaviorSubject<ListContractTypeResponse.AsObject>(null);
  private token: string;

  constructor(private authService: AuthService, private http: HttpClient, private grpcService: GrpcService) {
    this.loadFuelContracts();
    this.authService.jwt$.subscribe((jwt) => {
      this.token = `bearer ${jwt}`;
    });
  }

  public get fuelContracts$(): Observable<ListFuelSurchargeContractsResponse.AsObject> {
    this.loadFuelContracts();
    return this.fuelContracts$$.asObservable().pipe(
      filter((object) => !!object),
      share(),
    );
  }

  public get vendorContractsList$(): Observable<ListContractTypeResponse.AsObject> {
    this.loadVendorContracts();
    return this.vendorContractsList$$.asObservable().pipe(
      filter((object) => !!object),
      share(),
    );
  }

  public fuelBrackets$(
    contract: FuelSurchargeContract.AsObject,
  ): Observable<ListFuelSurchargeBracketsResponse.AsObject> {
    this.fuelBrackets$$.next(null);
    this.loadFuelSettings(contract);
    return this.fuelBrackets$$.asObservable().pipe(
      filter((object) => !!object),
      share(),
    );
  }

  public loadFuelContracts() {
    const request = new ListFuelSurchargeContractsRequest();
    return (this.grpcService.invoke$(ContractsAPI.ListFuelSurchargeContracts, request) as Observable<
      ListFuelSurchargeContractsResponse
    >).subscribe((response) => {
      this.fuelContracts$$.next(response.toObject());
    });
  }

  public loadVendorContracts() {
    const request = new ListContractTypeRequest();
    return (this.grpcService.invoke$(ContractsAPI.ListVendorContractTypes, request) as Observable<
      ListContractTypeResponse
    >).subscribe((response) => {
      this.vendorContractsList$$.next(response.toObject());
    });
  }

  public loadFuelSettings(contract: FuelSurchargeContract.AsObject) {
    const request = new ListFuelSurchargeBracketsRequest();
    request.setFuelmatrixcontractid(contract.id);
    return (this.grpcService.invoke$(ContractsAPI.ListFuelSurchargeBrackets, request) as Observable<
      ListFuelSurchargeBracketsResponse
    >).subscribe((response) => {
      this.fuelBrackets$$.next(response.toObject());
    });
  }

  public uploadFuelSurchargeFile$(fileToUpload: File, matrixType: string, name: string, contracts): Observable<any> {
    const formData = new FormData();
    formData.append('file', fileToUpload);
    formData.append('matrixType', matrixType);
    formData.append('name', name);
    formData.append('vendorContractTypeIds', contracts);
    const header = new HttpHeaders({
      Authorization: this.token,
    });
    return this.http
      .post(`${environment.api}/upload_fuel_matrix`, formData, {
        headers: header,
      })
      .pipe(
        tap((_) => {
          this.loadFuelContracts();
        }),
      );
  }

  public downloadFuelCostFile$(): Observable<DownloadFuelCostFileResponse> {
    const request = new DownloadFuelCostFileRequest();
    return this.grpcService.invoke$(ContractsAPI.DownloadFuelCostFile, request) as Observable<
      DownloadFuelCostFileResponse
    >;
  }

  public archiveFuelContract$(
    contract: FuelSurchargeContract.AsObject,
  ): Observable<ArchiveFuelSurchargeContractResponse> {
    const request = new ArchiveFuelSurchargeContractRequest();
    request.setContractid(contract.id);
    return (this.grpcService.invoke$(ContractsAPI.ArchiveFuelSurchargeContract, request) as Observable<
      ArchiveFuelSurchargeContractResponse
    >).pipe(
      tap((_) => {
        this.loadFuelContracts();
      }),
    );
  }
}
