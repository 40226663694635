import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';
import {
  UnassignTrailerOrderRequest,
  UnassignTrailerOrderResponse,
  UnassignTruckOrderRequest,
} from '~proto/order/order_api_pb';
import { OrderAPI } from '~proto/order/order_api_pb_service';
import {
  CreateTrailerRequest,
  CreateTrailerResponse,
  ListTrailersRequest,
  ListTrailersResponse,
} from '~proto/trailer/trailer_api_pb';
import { TrailerAPI } from '~proto/trailer/trailer_api_pb_service';
import { Trailer } from '~proto/trailer/trailer_pb';
import {
  CreateTruckRequest,
  CreateTruckResponse,
  ListTrucksRequest,
  ListTrucksResponse,
} from '~proto/truck/truck_api_pb';
import { TruckAPI } from '~proto/truck/truck_api_pb_service';
import { Truck } from '~proto/truck/truck_pb';
import { GrpcService } from '~services/grpc.service';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { observableArrayFromRecordGetter$ } from '~utilities/observableGetter';
import { sortByName } from '~utilities/sortByName';

@Injectable({
  providedIn: 'root',
})
export class TruckAndTrailerService {
  private trailers$$: BehaviorSubject<Record<string, Trailer.AsObject>> = new BehaviorSubject({});
  private trucks$$: BehaviorSubject<Record<string, Truck.AsObject>> = new BehaviorSubject({});
  private trailerThrottle$$ = new Subject();
  private truckThrottle$$ = new Subject();

  public get trailers$(): Observable<Trailer.AsObject[]> {
    this.loadTrailers();
    return observableArrayFromRecordGetter$(this.trailers$$, sortByName);
  }

  public get trucks$(): Observable<Truck.AsObject[]> {
    this.loadTrucks();
    return observableArrayFromRecordGetter$(this.trucks$$, sortByName);
  }

  constructor(private grpcService: GrpcService) {
    this.truckThrottle$$.pipe(throttleTime(100)).subscribe(() => this._loadTrucks());
    this.trailerThrottle$$.pipe(throttleTime(100)).subscribe(() => this._loadTrailers());
  }

  public addTrailer$(request: CreateTrailerRequest): Observable<Trailer.AsObject> {
    return this.grpcService.invoke$(TrailerAPI.CreateTrailer, request).pipe(
      map((trailer: CreateTrailerResponse) => {
        const trailerObject = trailer.toObject().trailer;
        this.trailers$$.next({
          ...this.trailers$$.value,
          [trailerObject.id]: trailerObject,
        });
        return trailerObject;
      }),
    );
  }

  public addTruck$(request: CreateTruckRequest): Observable<Truck.AsObject> {
    return this.grpcService.invoke$(TruckAPI.CreateTruck, request).pipe(
      map((trailer: CreateTruckResponse) => {
        const truckObject = trailer.toObject().truck;
        this.trucks$$.next({
          ...this.trucks$$.value,
          [truckObject.id]: truckObject,
        });
        return truckObject;
      }),
    );
  }

  public unassignTrailer$(id: number, complete: boolean): Observable<{}> {
    const request = new UnassignTrailerOrderRequest();
    request.setTrailerId(id);
    request.setCompleteOrder(complete);

    return this.grpcService.invoke$(OrderAPI.UnassignTrailerOrder, request).pipe(
      map((response: UnassignTrailerOrderResponse) => {
        return response.toObject();
      }),
    );
  }

  public unassignTruck$(id: number, complete: boolean): Observable<{}> {
    const request = new UnassignTruckOrderRequest();
    request.setTruckId(id);
    request.setCompleteOrder(complete);

    return this.grpcService.invoke$(OrderAPI.UnassignTruckOrder, request).pipe(
      map((response: UnassignTruckOrderRequest) => {
        return response.toObject();
      }),
    );
  }

  private loadTrucks() {
    this.truckThrottle$$.next();
  }

  private loadTrailers() {
    this.trailerThrottle$$.next();
  }

  private _loadTrailers() {
    const request = new ListTrailersRequest();
    this.grpcService.invoke$(TrailerAPI.ListTrailers, request).subscribe((trucks: ListTrailersResponse) => {
      this.trailers$$.next(idArrayToRecord(trucks.toObject().trailersList));
    });
  }

  private _loadTrucks() {
    const request = new ListTrucksRequest();
    this.grpcService.invoke$(TruckAPI.ListTrucks, request).subscribe((trucks: ListTrucksResponse) => {
      this.trucks$$.next(idArrayToRecord(trucks.toObject().trucksList));
    });
  }
}
