// package: cbv1
// file: proto/cybertron/truck/truck_api.proto

var proto_cybertron_truck_truck_api_pb = require("../../../proto/cybertron/truck/truck_api_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var TruckAPI = (function () {
  function TruckAPI() {}
  TruckAPI.serviceName = "cbv1.TruckAPI";
  return TruckAPI;
}());

TruckAPI.CreateTruck = {
  methodName: "CreateTruck",
  service: TruckAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_truck_truck_api_pb.CreateTruckRequest,
  responseType: proto_cybertron_truck_truck_api_pb.CreateTruckResponse
};

TruckAPI.ListTrucks = {
  methodName: "ListTrucks",
  service: TruckAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_truck_truck_api_pb.ListTrucksRequest,
  responseType: proto_cybertron_truck_truck_api_pb.ListTrucksResponse
};

exports.TruckAPI = TruckAPI;

function TruckAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

TruckAPIClient.prototype.createTruck = function createTruck(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TruckAPI.CreateTruck, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

TruckAPIClient.prototype.listTrucks = function listTrucks(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(TruckAPI.ListTrucks, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.TruckAPIClient = TruckAPIClient;

