/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_cybertron_order_order_pb = require('../../../proto/cybertron/order/order_pb.js');
var proto_cybertron_contracts_contracts_pb = require('../../../proto/cybertron/contracts/contracts_pb.js');
var proto_cybertron_billingsettings_settings_pb = require('../../../proto/cybertron/billingsettings/settings_pb.js');
var proto_cybertron_types_types_pb = require('../../../proto/cybertron/types/types_pb.js');
goog.exportSymbol('proto.cbv1.BillingCompleteListOrder', null, global);
goog.exportSymbol('proto.cbv1.BillingListOrder', null, global);
goog.exportSymbol('proto.cbv1.BillingOrder', null, global);
goog.exportSymbol('proto.cbv1.Charge', null, global);
goog.exportSymbol('proto.cbv1.ChargeHistory', null, global);
goog.exportSymbol('proto.cbv1.CommonFilters', null, global);
goog.exportSymbol('proto.cbv1.NameAndId', null, global);
goog.exportSymbol('proto.cbv1.NameAndUid', null, global);
goog.exportSymbol('proto.cbv1.OrderBillingHistory', null, global);
goog.exportSymbol('proto.cbv1.OrderBillingStatusHistory', null, global);
goog.exportSymbol('proto.cbv1.SubTaskDetailsList', null, global);
goog.exportSymbol('proto.cbv1.TaskBillingSummary', null, global);
goog.exportSymbol('proto.cbv1.UpdateNeededFields', null, global);
goog.exportSymbol('proto.cbv1.batchInvoice', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.BillingListOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.BillingListOrder.repeatedFields_, null);
};
goog.inherits(proto.cbv1.BillingListOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.BillingListOrder.displayName = 'proto.cbv1.BillingListOrder';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.BillingListOrder.repeatedFields_ = [4,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.BillingListOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.BillingListOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.BillingListOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BillingListOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    truck: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sitesList: jspb.Message.getRepeatedField(msg, 4),
    vendor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lmo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dispatchTimeUnix: jspb.Message.getFieldWithDefault(msg, 7, 0),
    payloadsList: jspb.Message.getRepeatedField(msg, 8),
    totalCost: jspb.Message.getFieldWithDefault(msg, 9, 0),
    changedString: jspb.Message.getFieldWithDefault(msg, 10, ""),
    driver: jspb.Message.getFieldWithDefault(msg, 11, ""),
    isPushedBack: jspb.Message.getFieldWithDefault(msg, 12, false),
    fastTracked: jspb.Message.getFieldWithDefault(msg, 13, false),
    trailer: jspb.Message.getFieldWithDefault(msg, 14, ""),
    useShaleappInvoiceNumber: jspb.Message.getFieldWithDefault(msg, 15, false),
    singleInvoiceSetting: jspb.Message.getFieldWithDefault(msg, 16, false),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 17, ""),
    vendorId: jspb.Message.getFieldWithDefault(msg, 18, 0),
    isAutoApproved: jspb.Message.getFieldWithDefault(msg, 19, false),
    createdByName: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.BillingListOrder}
 */
proto.cbv1.BillingListOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.BillingListOrder;
  return proto.cbv1.BillingListOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.BillingListOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.BillingListOrder}
 */
proto.cbv1.BillingListOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTruck(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSites(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendor(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLmo(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDispatchTimeUnix(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addPayloads(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCost(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedString(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriver(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPushedBack(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFastTracked(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailer(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseShaleappInvoiceNumber(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSingleInvoiceSetting(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVendorId(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAutoApproved(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedByName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.BillingListOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.BillingListOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.BillingListOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BillingListOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDisplayId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTruck();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getVendor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLmo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDispatchTimeUnix();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPayloadsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getTotalCost();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getChangedString();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDriver();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIsPushedBack();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getFastTracked();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getTrailer();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getUseShaleappInvoiceNumber();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getSingleInvoiceSetting();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getVendorId();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getIsAutoApproved();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getCreatedByName();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional int64 order_id = 1;
 * @return {number}
 */
proto.cbv1.BillingListOrder.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.BillingListOrder.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_id = 2;
 * @return {string}
 */
proto.cbv1.BillingListOrder.prototype.getDisplayId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.BillingListOrder.prototype.setDisplayId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string truck = 3;
 * @return {string}
 */
proto.cbv1.BillingListOrder.prototype.getTruck = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.BillingListOrder.prototype.setTruck = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string sites = 4;
 * @return {!Array<string>}
 */
proto.cbv1.BillingListOrder.prototype.getSitesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/** @param {!Array<string>} value */
proto.cbv1.BillingListOrder.prototype.setSitesList = function(value) {
  jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.BillingListOrder.prototype.addSites = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


proto.cbv1.BillingListOrder.prototype.clearSitesList = function() {
  this.setSitesList([]);
};


/**
 * optional string vendor = 5;
 * @return {string}
 */
proto.cbv1.BillingListOrder.prototype.getVendor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.BillingListOrder.prototype.setVendor = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string lmo = 6;
 * @return {string}
 */
proto.cbv1.BillingListOrder.prototype.getLmo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cbv1.BillingListOrder.prototype.setLmo = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 dispatch_time_unix = 7;
 * @return {number}
 */
proto.cbv1.BillingListOrder.prototype.getDispatchTimeUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.BillingListOrder.prototype.setDispatchTimeUnix = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated string payloads = 8;
 * @return {!Array<string>}
 */
proto.cbv1.BillingListOrder.prototype.getPayloadsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/** @param {!Array<string>} value */
proto.cbv1.BillingListOrder.prototype.setPayloadsList = function(value) {
  jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.BillingListOrder.prototype.addPayloads = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


proto.cbv1.BillingListOrder.prototype.clearPayloadsList = function() {
  this.setPayloadsList([]);
};


/**
 * optional int64 total_cost = 9;
 * @return {number}
 */
proto.cbv1.BillingListOrder.prototype.getTotalCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cbv1.BillingListOrder.prototype.setTotalCost = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string changed_string = 10;
 * @return {string}
 */
proto.cbv1.BillingListOrder.prototype.getChangedString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.cbv1.BillingListOrder.prototype.setChangedString = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string driver = 11;
 * @return {string}
 */
proto.cbv1.BillingListOrder.prototype.getDriver = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.cbv1.BillingListOrder.prototype.setDriver = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool is_pushed_back = 12;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.BillingListOrder.prototype.getIsPushedBack = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 12, false));
};


/** @param {boolean} value */
proto.cbv1.BillingListOrder.prototype.setIsPushedBack = function(value) {
  jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool fast_tracked = 13;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.BillingListOrder.prototype.getFastTracked = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 13, false));
};


/** @param {boolean} value */
proto.cbv1.BillingListOrder.prototype.setFastTracked = function(value) {
  jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string trailer = 14;
 * @return {string}
 */
proto.cbv1.BillingListOrder.prototype.getTrailer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.cbv1.BillingListOrder.prototype.setTrailer = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool use_shaleapp_invoice_number = 15;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.BillingListOrder.prototype.getUseShaleappInvoiceNumber = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 15, false));
};


/** @param {boolean} value */
proto.cbv1.BillingListOrder.prototype.setUseShaleappInvoiceNumber = function(value) {
  jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool single_invoice_setting = 16;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.BillingListOrder.prototype.getSingleInvoiceSetting = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 16, false));
};


/** @param {boolean} value */
proto.cbv1.BillingListOrder.prototype.setSingleInvoiceSetting = function(value) {
  jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string invoice_number = 17;
 * @return {string}
 */
proto.cbv1.BillingListOrder.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.cbv1.BillingListOrder.prototype.setInvoiceNumber = function(value) {
  jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int64 vendor_id = 18;
 * @return {number}
 */
proto.cbv1.BillingListOrder.prototype.getVendorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {number} value */
proto.cbv1.BillingListOrder.prototype.setVendorId = function(value) {
  jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional bool is_auto_approved = 19;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.BillingListOrder.prototype.getIsAutoApproved = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 19, false));
};


/** @param {boolean} value */
proto.cbv1.BillingListOrder.prototype.setIsAutoApproved = function(value) {
  jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional string created_by_name = 20;
 * @return {string}
 */
proto.cbv1.BillingListOrder.prototype.getCreatedByName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/** @param {string} value */
proto.cbv1.BillingListOrder.prototype.setCreatedByName = function(value) {
  jspb.Message.setProto3StringField(this, 20, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.BillingCompleteListOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.BillingCompleteListOrder.repeatedFields_, null);
};
goog.inherits(proto.cbv1.BillingCompleteListOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.BillingCompleteListOrder.displayName = 'proto.cbv1.BillingCompleteListOrder';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.BillingCompleteListOrder.repeatedFields_ = [4,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.BillingCompleteListOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.BillingCompleteListOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.BillingCompleteListOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BillingCompleteListOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    truck: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sitesList: jspb.Message.getRepeatedField(msg, 4),
    vendor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lmo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dispatchTimeUnix: jspb.Message.getFieldWithDefault(msg, 7, 0),
    payloadsList: jspb.Message.getRepeatedField(msg, 8),
    totalCost: jspb.Message.getFieldWithDefault(msg, 9, 0),
    changedString: jspb.Message.getFieldWithDefault(msg, 10, ""),
    driver: jspb.Message.getFieldWithDefault(msg, 11, ""),
    approvedBy: jspb.Message.getFieldWithDefault(msg, 12, ""),
    fastTracked: jspb.Message.getFieldWithDefault(msg, 13, false),
    trailer: jspb.Message.getFieldWithDefault(msg, 14, ""),
    approveTimeUnix: jspb.Message.getFieldWithDefault(msg, 15, 0),
    canUnapprove: jspb.Message.getFieldWithDefault(msg, 16, false),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 17, ""),
    vendorId: jspb.Message.getFieldWithDefault(msg, 18, 0),
    isAutoApproved: jspb.Message.getFieldWithDefault(msg, 19, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.BillingCompleteListOrder}
 */
proto.cbv1.BillingCompleteListOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.BillingCompleteListOrder;
  return proto.cbv1.BillingCompleteListOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.BillingCompleteListOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.BillingCompleteListOrder}
 */
proto.cbv1.BillingCompleteListOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTruck(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSites(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendor(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLmo(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDispatchTimeUnix(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addPayloads(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCost(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedString(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriver(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setApprovedBy(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFastTracked(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailer(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setApproveTimeUnix(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanUnapprove(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVendorId(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAutoApproved(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.BillingCompleteListOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.BillingCompleteListOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.BillingCompleteListOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BillingCompleteListOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDisplayId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTruck();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getVendor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLmo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDispatchTimeUnix();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPayloadsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getTotalCost();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getChangedString();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDriver();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getApprovedBy();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getFastTracked();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getTrailer();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getApproveTimeUnix();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getCanUnapprove();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getVendorId();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getIsAutoApproved();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
};


/**
 * optional int64 order_id = 1;
 * @return {number}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.BillingCompleteListOrder.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_id = 2;
 * @return {string}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getDisplayId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.BillingCompleteListOrder.prototype.setDisplayId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string truck = 3;
 * @return {string}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getTruck = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.BillingCompleteListOrder.prototype.setTruck = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string sites = 4;
 * @return {!Array<string>}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getSitesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/** @param {!Array<string>} value */
proto.cbv1.BillingCompleteListOrder.prototype.setSitesList = function(value) {
  jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.BillingCompleteListOrder.prototype.addSites = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


proto.cbv1.BillingCompleteListOrder.prototype.clearSitesList = function() {
  this.setSitesList([]);
};


/**
 * optional string vendor = 5;
 * @return {string}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getVendor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.BillingCompleteListOrder.prototype.setVendor = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string lmo = 6;
 * @return {string}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getLmo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cbv1.BillingCompleteListOrder.prototype.setLmo = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 dispatch_time_unix = 7;
 * @return {number}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getDispatchTimeUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.BillingCompleteListOrder.prototype.setDispatchTimeUnix = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated string payloads = 8;
 * @return {!Array<string>}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getPayloadsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/** @param {!Array<string>} value */
proto.cbv1.BillingCompleteListOrder.prototype.setPayloadsList = function(value) {
  jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.BillingCompleteListOrder.prototype.addPayloads = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


proto.cbv1.BillingCompleteListOrder.prototype.clearPayloadsList = function() {
  this.setPayloadsList([]);
};


/**
 * optional int64 total_cost = 9;
 * @return {number}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getTotalCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cbv1.BillingCompleteListOrder.prototype.setTotalCost = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string changed_string = 10;
 * @return {string}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getChangedString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.cbv1.BillingCompleteListOrder.prototype.setChangedString = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string driver = 11;
 * @return {string}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getDriver = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.cbv1.BillingCompleteListOrder.prototype.setDriver = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string approved_by = 12;
 * @return {string}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getApprovedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/** @param {string} value */
proto.cbv1.BillingCompleteListOrder.prototype.setApprovedBy = function(value) {
  jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool fast_tracked = 13;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getFastTracked = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 13, false));
};


/** @param {boolean} value */
proto.cbv1.BillingCompleteListOrder.prototype.setFastTracked = function(value) {
  jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string trailer = 14;
 * @return {string}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getTrailer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/** @param {string} value */
proto.cbv1.BillingCompleteListOrder.prototype.setTrailer = function(value) {
  jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int64 approve_time_unix = 15;
 * @return {number}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getApproveTimeUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.cbv1.BillingCompleteListOrder.prototype.setApproveTimeUnix = function(value) {
  jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional bool can_unapprove = 16;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getCanUnapprove = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 16, false));
};


/** @param {boolean} value */
proto.cbv1.BillingCompleteListOrder.prototype.setCanUnapprove = function(value) {
  jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string invoice_number = 17;
 * @return {string}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/** @param {string} value */
proto.cbv1.BillingCompleteListOrder.prototype.setInvoiceNumber = function(value) {
  jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int64 vendor_id = 18;
 * @return {number}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getVendorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/** @param {number} value */
proto.cbv1.BillingCompleteListOrder.prototype.setVendorId = function(value) {
  jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional bool is_auto_approved = 19;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.BillingCompleteListOrder.prototype.getIsAutoApproved = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 19, false));
};


/** @param {boolean} value */
proto.cbv1.BillingCompleteListOrder.prototype.setIsAutoApproved = function(value) {
  jspb.Message.setProto3BooleanField(this, 19, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.OrderBillingStatusHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.OrderBillingStatusHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.OrderBillingStatusHistory.displayName = 'proto.cbv1.OrderBillingStatusHistory';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.OrderBillingStatusHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.OrderBillingStatusHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.OrderBillingStatusHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrderBillingStatusHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    changedBy: jspb.Message.getFieldWithDefault(msg, 2, ""),
    changedTimeUnix: jspb.Message.getFieldWithDefault(msg, 3, 0),
    oldStatus: jspb.Message.getFieldWithDefault(msg, 4, 0),
    newStatus: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pushBackReason: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.OrderBillingStatusHistory}
 */
proto.cbv1.OrderBillingStatusHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.OrderBillingStatusHistory;
  return proto.cbv1.OrderBillingStatusHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.OrderBillingStatusHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.OrderBillingStatusHistory}
 */
proto.cbv1.OrderBillingStatusHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedBy(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChangedTimeUnix(value);
      break;
    case 4:
      var value = /** @type {!proto.cbv1.BillingStatus} */ (reader.readEnum());
      msg.setOldStatus(value);
      break;
    case 5:
      var value = /** @type {!proto.cbv1.BillingStatus} */ (reader.readEnum());
      msg.setNewStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPushBackReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.OrderBillingStatusHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.OrderBillingStatusHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.OrderBillingStatusHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrderBillingStatusHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getChangedBy();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChangedTimeUnix();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOldStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getNewStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPushBackReason();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 order_id = 1;
 * @return {number}
 */
proto.cbv1.OrderBillingStatusHistory.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.OrderBillingStatusHistory.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string changed_by = 2;
 * @return {string}
 */
proto.cbv1.OrderBillingStatusHistory.prototype.getChangedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.OrderBillingStatusHistory.prototype.setChangedBy = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 changed_time_unix = 3;
 * @return {number}
 */
proto.cbv1.OrderBillingStatusHistory.prototype.getChangedTimeUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.OrderBillingStatusHistory.prototype.setChangedTimeUnix = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional BillingStatus old_status = 4;
 * @return {!proto.cbv1.BillingStatus}
 */
proto.cbv1.OrderBillingStatusHistory.prototype.getOldStatus = function() {
  return /** @type {!proto.cbv1.BillingStatus} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {!proto.cbv1.BillingStatus} value */
proto.cbv1.OrderBillingStatusHistory.prototype.setOldStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional BillingStatus new_status = 5;
 * @return {!proto.cbv1.BillingStatus}
 */
proto.cbv1.OrderBillingStatusHistory.prototype.getNewStatus = function() {
  return /** @type {!proto.cbv1.BillingStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {!proto.cbv1.BillingStatus} value */
proto.cbv1.OrderBillingStatusHistory.prototype.setNewStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string push_back_reason = 6;
 * @return {string}
 */
proto.cbv1.OrderBillingStatusHistory.prototype.getPushBackReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cbv1.OrderBillingStatusHistory.prototype.setPushBackReason = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.OrderBillingHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.OrderBillingHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.OrderBillingHistory.displayName = 'proto.cbv1.OrderBillingHistory';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.OrderBillingHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.OrderBillingHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.OrderBillingHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrderBillingHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    changedBy: jspb.Message.getFieldWithDefault(msg, 2, ""),
    changedTimeUnix: jspb.Message.getFieldWithDefault(msg, 3, 0),
    changedField: jspb.Message.getFieldWithDefault(msg, 4, ""),
    oldValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    newValue: jspb.Message.getFieldWithDefault(msg, 6, ""),
    approved: jspb.Message.getFieldWithDefault(msg, 7, false),
    changeType: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.OrderBillingHistory}
 */
proto.cbv1.OrderBillingHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.OrderBillingHistory;
  return proto.cbv1.OrderBillingHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.OrderBillingHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.OrderBillingHistory}
 */
proto.cbv1.OrderBillingHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedBy(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChangedTimeUnix(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedField(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldValue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewValue(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setApproved(value);
      break;
    case 8:
      var value = /** @type {!proto.cbv1.ManualChangeType} */ (reader.readEnum());
      msg.setChangeType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.OrderBillingHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.OrderBillingHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.OrderBillingHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.OrderBillingHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getChangedBy();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChangedTimeUnix();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getChangedField();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOldValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNewValue();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getApproved();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getChangeType();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional int64 order_id = 1;
 * @return {number}
 */
proto.cbv1.OrderBillingHistory.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.OrderBillingHistory.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string changed_by = 2;
 * @return {string}
 */
proto.cbv1.OrderBillingHistory.prototype.getChangedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.OrderBillingHistory.prototype.setChangedBy = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 changed_time_unix = 3;
 * @return {number}
 */
proto.cbv1.OrderBillingHistory.prototype.getChangedTimeUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.OrderBillingHistory.prototype.setChangedTimeUnix = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string changed_field = 4;
 * @return {string}
 */
proto.cbv1.OrderBillingHistory.prototype.getChangedField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.OrderBillingHistory.prototype.setChangedField = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string old_value = 5;
 * @return {string}
 */
proto.cbv1.OrderBillingHistory.prototype.getOldValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.OrderBillingHistory.prototype.setOldValue = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string new_value = 6;
 * @return {string}
 */
proto.cbv1.OrderBillingHistory.prototype.getNewValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cbv1.OrderBillingHistory.prototype.setNewValue = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool approved = 7;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.OrderBillingHistory.prototype.getApproved = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 7, false));
};


/** @param {boolean} value */
proto.cbv1.OrderBillingHistory.prototype.setApproved = function(value) {
  jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional ManualChangeType change_type = 8;
 * @return {!proto.cbv1.ManualChangeType}
 */
proto.cbv1.OrderBillingHistory.prototype.getChangeType = function() {
  return /** @type {!proto.cbv1.ManualChangeType} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {!proto.cbv1.ManualChangeType} value */
proto.cbv1.OrderBillingHistory.prototype.setChangeType = function(value) {
  jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ChargeHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ChargeHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ChargeHistory.displayName = 'proto.cbv1.ChargeHistory';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ChargeHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ChargeHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ChargeHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ChargeHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    chargeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    changedBy: jspb.Message.getFieldWithDefault(msg, 2, ""),
    changedTimeUnix: jspb.Message.getFieldWithDefault(msg, 3, 0),
    changedField: jspb.Message.getFieldWithDefault(msg, 4, ""),
    oldValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    newValue: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ChargeHistory}
 */
proto.cbv1.ChargeHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ChargeHistory;
  return proto.cbv1.ChargeHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ChargeHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ChargeHistory}
 */
proto.cbv1.ChargeHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChargeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedBy(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChangedTimeUnix(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedField(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldValue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ChargeHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ChargeHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ChargeHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ChargeHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChargeId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getChangedBy();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChangedTimeUnix();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getChangedField();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOldValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNewValue();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int64 charge_id = 1;
 * @return {number}
 */
proto.cbv1.ChargeHistory.prototype.getChargeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.ChargeHistory.prototype.setChargeId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string changed_by = 2;
 * @return {string}
 */
proto.cbv1.ChargeHistory.prototype.getChangedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.ChargeHistory.prototype.setChangedBy = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 changed_time_unix = 3;
 * @return {number}
 */
proto.cbv1.ChargeHistory.prototype.getChangedTimeUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.ChargeHistory.prototype.setChangedTimeUnix = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string changed_field = 4;
 * @return {string}
 */
proto.cbv1.ChargeHistory.prototype.getChangedField = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.ChargeHistory.prototype.setChangedField = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string old_value = 5;
 * @return {string}
 */
proto.cbv1.ChargeHistory.prototype.getOldValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.ChargeHistory.prototype.setOldValue = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string new_value = 6;
 * @return {string}
 */
proto.cbv1.ChargeHistory.prototype.getNewValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cbv1.ChargeHistory.prototype.setNewValue = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.Charge = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.Charge, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.Charge.displayName = 'proto.cbv1.Charge';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.Charge.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.Charge.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.Charge} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Charge.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    orderId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    chargeType: (f = msg.getChargeType()) && proto_cybertron_billingsettings_settings_pb.LMOChargeType.toObject(includeInstance, f),
    updateUnix: jspb.Message.getFieldWithDefault(msg, 4, 0),
    numberOfUnits: +jspb.Message.getFieldWithDefault(msg, 5, 0.0),
    unitCost: jspb.Message.getFieldWithDefault(msg, 6, 0),
    defaultShaleappsCharge: jspb.Message.getFieldWithDefault(msg, 7, ""),
    unitOnQuantity: jspb.Message.getFieldWithDefault(msg, 8, ""),
    unitOnCost: jspb.Message.getFieldWithDefault(msg, 9, ""),
    totalAmount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    deleted: jspb.Message.getFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.Charge}
 */
proto.cbv1.Charge.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.Charge;
  return proto.cbv1.Charge.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.Charge} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.Charge}
 */
proto.cbv1.Charge.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    case 3:
      var value = new proto_cybertron_billingsettings_settings_pb.LMOChargeType;
      reader.readMessage(value,proto_cybertron_billingsettings_settings_pb.LMOChargeType.deserializeBinaryFromReader);
      msg.setChargeType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdateUnix(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setNumberOfUnits(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitCost(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefaultShaleappsCharge(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitOnQuantity(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnitOnCost(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalAmount(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleted(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.Charge.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.Charge.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.Charge} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.Charge.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getChargeType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto_cybertron_billingsettings_settings_pb.LMOChargeType.serializeBinaryToWriter
    );
  }
  f = message.getUpdateUnix();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getNumberOfUnits();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getUnitCost();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getDefaultShaleappsCharge();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUnitOnQuantity();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUnitOnCost();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTotalAmount();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getDeleted();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.Charge.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.Charge.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 order_id = 2;
 * @return {number}
 */
proto.cbv1.Charge.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.Charge.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional LMOChargeType charge_type = 3;
 * @return {?proto.cbv1.LMOChargeType}
 */
proto.cbv1.Charge.prototype.getChargeType = function() {
  return /** @type{?proto.cbv1.LMOChargeType} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billingsettings_settings_pb.LMOChargeType, 3));
};


/** @param {?proto.cbv1.LMOChargeType|undefined} value */
proto.cbv1.Charge.prototype.setChargeType = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.Charge.prototype.clearChargeType = function() {
  this.setChargeType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.Charge.prototype.hasChargeType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 update_unix = 4;
 * @return {number}
 */
proto.cbv1.Charge.prototype.getUpdateUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.Charge.prototype.setUpdateUnix = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional float number_of_units = 5;
 * @return {number}
 */
proto.cbv1.Charge.prototype.getNumberOfUnits = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 5, 0.0));
};


/** @param {number} value */
proto.cbv1.Charge.prototype.setNumberOfUnits = function(value) {
  jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional int64 unit_cost = 6;
 * @return {number}
 */
proto.cbv1.Charge.prototype.getUnitCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.Charge.prototype.setUnitCost = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string default_shaleapps_charge = 7;
 * @return {string}
 */
proto.cbv1.Charge.prototype.getDefaultShaleappsCharge = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv1.Charge.prototype.setDefaultShaleappsCharge = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string unit_on_quantity = 8;
 * @return {string}
 */
proto.cbv1.Charge.prototype.getUnitOnQuantity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/** @param {string} value */
proto.cbv1.Charge.prototype.setUnitOnQuantity = function(value) {
  jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string unit_on_cost = 9;
 * @return {string}
 */
proto.cbv1.Charge.prototype.getUnitOnCost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/** @param {string} value */
proto.cbv1.Charge.prototype.setUnitOnCost = function(value) {
  jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int64 total_amount = 10;
 * @return {number}
 */
proto.cbv1.Charge.prototype.getTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cbv1.Charge.prototype.setTotalAmount = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool deleted = 11;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.Charge.prototype.getDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 11, false));
};


/** @param {boolean} value */
proto.cbv1.Charge.prototype.setDeleted = function(value) {
  jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.BillingOrder = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.BillingOrder.repeatedFields_, null);
};
goog.inherits(proto.cbv1.BillingOrder, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.BillingOrder.displayName = 'proto.cbv1.BillingOrder';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.BillingOrder.repeatedFields_ = [4,8,15,17,18,19,21,32,33];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.BillingOrder.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.BillingOrder.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.BillingOrder} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BillingOrder.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    displayId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    truck: (f = msg.getTruck()) && proto.cbv1.NameAndId.toObject(includeInstance, f),
    sitesList: jspb.Message.getRepeatedField(msg, 4),
    vendor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    lmo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    dispatchTimeUnix: jspb.Message.getFieldWithDefault(msg, 7, 0),
    payloadsList: jspb.Message.getRepeatedField(msg, 8),
    totalCost: jspb.Message.getFieldWithDefault(msg, 9, 0),
    changedString: jspb.Message.getFieldWithDefault(msg, 10, ""),
    driver: (f = msg.getDriver()) && proto.cbv1.NameAndUid.toObject(includeInstance, f),
    waitAtPickUpSite: jspb.Message.getFieldWithDefault(msg, 12, 0),
    waitAtDropOffSite: jspb.Message.getFieldWithDefault(msg, 13, 0),
    isPushedBack: jspb.Message.getFieldWithDefault(msg, 14, false),
    tasksList: jspb.Message.toObjectList(msg.getTasksList(),
    proto_cybertron_order_order_pb.Task.toObject, includeInstance),
    currentContract: (f = msg.getCurrentContract()) && proto_cybertron_contracts_contracts_pb.VendorContract.toObject(includeInstance, f),
    billingHistoryList: jspb.Message.toObjectList(msg.getBillingHistoryList(),
    proto.cbv1.OrderBillingHistory.toObject, includeInstance),
    billingStatusHistoryList: jspb.Message.toObjectList(msg.getBillingStatusHistoryList(),
    proto.cbv1.OrderBillingStatusHistory.toObject, includeInstance),
    chargesList: jspb.Message.toObjectList(msg.getChargesList(),
    proto.cbv1.Charge.toObject, includeInstance),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 20, ""),
    attachmentsList: jspb.Message.toObjectList(msg.getAttachmentsList(),
    proto_cybertron_order_order_pb.OrderAttachment.toObject, includeInstance),
    billingStatus: jspb.Message.getFieldWithDefault(msg, 22, 0),
    trailer: jspb.Message.getFieldWithDefault(msg, 23, ""),
    deadheadDistance: jspb.Message.getFieldWithDefault(msg, 24, 0),
    deadheadDuration: jspb.Message.getFieldWithDefault(msg, 25, 0),
    returnDistance: jspb.Message.getFieldWithDefault(msg, 26, 0),
    returnDuration: jspb.Message.getFieldWithDefault(msg, 27, 0),
    tripMileage: jspb.Message.getFieldWithDefault(msg, 28, 0),
    tripDuration: jspb.Message.getFieldWithDefault(msg, 29, 0),
    pushbackReason: jspb.Message.getFieldWithDefault(msg, 30, ""),
    ticketNumber: jspb.Message.getFieldWithDefault(msg, 31, ""),
    accessorialchargehistoryList: jspb.Message.toObjectList(msg.getAccessorialchargehistoryList(),
    proto.cbv1.ChargeHistory.toObject, includeInstance),
    taskSummaryList: jspb.Message.toObjectList(msg.getTaskSummaryList(),
    proto.cbv1.TaskBillingSummary.toObject, includeInstance),
    estimatedTripDistance: jspb.Message.getFieldWithDefault(msg, 34, 0),
    estimatedTripDuration: jspb.Message.getFieldWithDefault(msg, 35, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 36, ""),
    backHaulDistance: jspb.Message.getFieldWithDefault(msg, 37, 0),
    backHaulDuration: jspb.Message.getFieldWithDefault(msg, 38, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.BillingOrder}
 */
proto.cbv1.BillingOrder.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.BillingOrder;
  return proto.cbv1.BillingOrder.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.BillingOrder} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.BillingOrder}
 */
proto.cbv1.BillingOrder.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayId(value);
      break;
    case 3:
      var value = new proto.cbv1.NameAndId;
      reader.readMessage(value,proto.cbv1.NameAndId.deserializeBinaryFromReader);
      msg.setTruck(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addSites(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendor(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLmo(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDispatchTimeUnix(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addPayloads(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCost(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setChangedString(value);
      break;
    case 11:
      var value = new proto.cbv1.NameAndUid;
      reader.readMessage(value,proto.cbv1.NameAndUid.deserializeBinaryFromReader);
      msg.setDriver(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWaitAtPickUpSite(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWaitAtDropOffSite(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPushedBack(value);
      break;
    case 15:
      var value = new proto_cybertron_order_order_pb.Task;
      reader.readMessage(value,proto_cybertron_order_order_pb.Task.deserializeBinaryFromReader);
      msg.addTasks(value);
      break;
    case 16:
      var value = new proto_cybertron_contracts_contracts_pb.VendorContract;
      reader.readMessage(value,proto_cybertron_contracts_contracts_pb.VendorContract.deserializeBinaryFromReader);
      msg.setCurrentContract(value);
      break;
    case 17:
      var value = new proto.cbv1.OrderBillingHistory;
      reader.readMessage(value,proto.cbv1.OrderBillingHistory.deserializeBinaryFromReader);
      msg.addBillingHistory(value);
      break;
    case 18:
      var value = new proto.cbv1.OrderBillingStatusHistory;
      reader.readMessage(value,proto.cbv1.OrderBillingStatusHistory.deserializeBinaryFromReader);
      msg.addBillingStatusHistory(value);
      break;
    case 19:
      var value = new proto.cbv1.Charge;
      reader.readMessage(value,proto.cbv1.Charge.deserializeBinaryFromReader);
      msg.addCharges(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    case 21:
      var value = new proto_cybertron_order_order_pb.OrderAttachment;
      reader.readMessage(value,proto_cybertron_order_order_pb.OrderAttachment.deserializeBinaryFromReader);
      msg.addAttachments(value);
      break;
    case 22:
      var value = /** @type {!proto.cbv1.BillingStatus} */ (reader.readEnum());
      msg.setBillingStatus(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrailer(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeadheadDistance(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeadheadDuration(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReturnDistance(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReturnDuration(value);
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTripMileage(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTripDuration(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setPushbackReason(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketNumber(value);
      break;
    case 32:
      var value = new proto.cbv1.ChargeHistory;
      reader.readMessage(value,proto.cbv1.ChargeHistory.deserializeBinaryFromReader);
      msg.addAccessorialchargehistory(value);
      break;
    case 33:
      var value = new proto.cbv1.TaskBillingSummary;
      reader.readMessage(value,proto.cbv1.TaskBillingSummary.deserializeBinaryFromReader);
      msg.addTaskSummary(value);
      break;
    case 34:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEstimatedTripDistance(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEstimatedTripDuration(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBackHaulDistance(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBackHaulDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.BillingOrder.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.BillingOrder.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.BillingOrder} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.BillingOrder.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDisplayId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTruck();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.cbv1.NameAndId.serializeBinaryToWriter
    );
  }
  f = message.getSitesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getVendor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getLmo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDispatchTimeUnix();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getPayloadsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getTotalCost();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getChangedString();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDriver();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.cbv1.NameAndUid.serializeBinaryToWriter
    );
  }
  f = message.getWaitAtPickUpSite();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getWaitAtDropOffSite();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getIsPushedBack();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getTasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto_cybertron_order_order_pb.Task.serializeBinaryToWriter
    );
  }
  f = message.getCurrentContract();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto_cybertron_contracts_contracts_pb.VendorContract.serializeBinaryToWriter
    );
  }
  f = message.getBillingHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.cbv1.OrderBillingHistory.serializeBinaryToWriter
    );
  }
  f = message.getBillingStatusHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      18,
      f,
      proto.cbv1.OrderBillingStatusHistory.serializeBinaryToWriter
    );
  }
  f = message.getChargesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.cbv1.Charge.serializeBinaryToWriter
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getAttachmentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto_cybertron_order_order_pb.OrderAttachment.serializeBinaryToWriter
    );
  }
  f = message.getBillingStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      22,
      f
    );
  }
  f = message.getTrailer();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getDeadheadDistance();
  if (f !== 0) {
    writer.writeInt64(
      24,
      f
    );
  }
  f = message.getDeadheadDuration();
  if (f !== 0) {
    writer.writeInt64(
      25,
      f
    );
  }
  f = message.getReturnDistance();
  if (f !== 0) {
    writer.writeInt64(
      26,
      f
    );
  }
  f = message.getReturnDuration();
  if (f !== 0) {
    writer.writeInt64(
      27,
      f
    );
  }
  f = message.getTripMileage();
  if (f !== 0) {
    writer.writeInt64(
      28,
      f
    );
  }
  f = message.getTripDuration();
  if (f !== 0) {
    writer.writeInt64(
      29,
      f
    );
  }
  f = message.getPushbackReason();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getTicketNumber();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getAccessorialchargehistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      32,
      f,
      proto.cbv1.ChargeHistory.serializeBinaryToWriter
    );
  }
  f = message.getTaskSummaryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      33,
      f,
      proto.cbv1.TaskBillingSummary.serializeBinaryToWriter
    );
  }
  f = message.getEstimatedTripDistance();
  if (f !== 0) {
    writer.writeInt64(
      34,
      f
    );
  }
  f = message.getEstimatedTripDuration();
  if (f !== 0) {
    writer.writeInt64(
      35,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getBackHaulDistance();
  if (f !== 0) {
    writer.writeInt32(
      37,
      f
    );
  }
  f = message.getBackHaulDuration();
  if (f !== 0) {
    writer.writeInt32(
      38,
      f
    );
  }
};


/**
 * optional int64 order_id = 1;
 * @return {number}
 */
proto.cbv1.BillingOrder.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.BillingOrder.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string display_id = 2;
 * @return {string}
 */
proto.cbv1.BillingOrder.prototype.getDisplayId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.BillingOrder.prototype.setDisplayId = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional NameAndId truck = 3;
 * @return {?proto.cbv1.NameAndId}
 */
proto.cbv1.BillingOrder.prototype.getTruck = function() {
  return /** @type{?proto.cbv1.NameAndId} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.NameAndId, 3));
};


/** @param {?proto.cbv1.NameAndId|undefined} value */
proto.cbv1.BillingOrder.prototype.setTruck = function(value) {
  jspb.Message.setWrapperField(this, 3, value);
};


proto.cbv1.BillingOrder.prototype.clearTruck = function() {
  this.setTruck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.BillingOrder.prototype.hasTruck = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string sites = 4;
 * @return {!Array<string>}
 */
proto.cbv1.BillingOrder.prototype.getSitesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/** @param {!Array<string>} value */
proto.cbv1.BillingOrder.prototype.setSitesList = function(value) {
  jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.BillingOrder.prototype.addSites = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


proto.cbv1.BillingOrder.prototype.clearSitesList = function() {
  this.setSitesList([]);
};


/**
 * optional string vendor = 5;
 * @return {string}
 */
proto.cbv1.BillingOrder.prototype.getVendor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.BillingOrder.prototype.setVendor = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string lmo = 6;
 * @return {string}
 */
proto.cbv1.BillingOrder.prototype.getLmo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/** @param {string} value */
proto.cbv1.BillingOrder.prototype.setLmo = function(value) {
  jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 dispatch_time_unix = 7;
 * @return {number}
 */
proto.cbv1.BillingOrder.prototype.getDispatchTimeUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.BillingOrder.prototype.setDispatchTimeUnix = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated string payloads = 8;
 * @return {!Array<string>}
 */
proto.cbv1.BillingOrder.prototype.getPayloadsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/** @param {!Array<string>} value */
proto.cbv1.BillingOrder.prototype.setPayloadsList = function(value) {
  jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.BillingOrder.prototype.addPayloads = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


proto.cbv1.BillingOrder.prototype.clearPayloadsList = function() {
  this.setPayloadsList([]);
};


/**
 * optional int64 total_cost = 9;
 * @return {number}
 */
proto.cbv1.BillingOrder.prototype.getTotalCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cbv1.BillingOrder.prototype.setTotalCost = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string changed_string = 10;
 * @return {string}
 */
proto.cbv1.BillingOrder.prototype.getChangedString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/** @param {string} value */
proto.cbv1.BillingOrder.prototype.setChangedString = function(value) {
  jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional NameAndUid driver = 11;
 * @return {?proto.cbv1.NameAndUid}
 */
proto.cbv1.BillingOrder.prototype.getDriver = function() {
  return /** @type{?proto.cbv1.NameAndUid} */ (
    jspb.Message.getWrapperField(this, proto.cbv1.NameAndUid, 11));
};


/** @param {?proto.cbv1.NameAndUid|undefined} value */
proto.cbv1.BillingOrder.prototype.setDriver = function(value) {
  jspb.Message.setWrapperField(this, 11, value);
};


proto.cbv1.BillingOrder.prototype.clearDriver = function() {
  this.setDriver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.BillingOrder.prototype.hasDriver = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional int64 wait_at_pick_up_site = 12;
 * @return {number}
 */
proto.cbv1.BillingOrder.prototype.getWaitAtPickUpSite = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cbv1.BillingOrder.prototype.setWaitAtPickUpSite = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 wait_at_drop_off_site = 13;
 * @return {number}
 */
proto.cbv1.BillingOrder.prototype.getWaitAtDropOffSite = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.cbv1.BillingOrder.prototype.setWaitAtDropOffSite = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional bool is_pushed_back = 14;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.BillingOrder.prototype.getIsPushedBack = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 14, false));
};


/** @param {boolean} value */
proto.cbv1.BillingOrder.prototype.setIsPushedBack = function(value) {
  jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * repeated Task tasks = 15;
 * @return {!Array<!proto.cbv1.Task>}
 */
proto.cbv1.BillingOrder.prototype.getTasksList = function() {
  return /** @type{!Array<!proto.cbv1.Task>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_order_order_pb.Task, 15));
};


/** @param {!Array<!proto.cbv1.Task>} value */
proto.cbv1.BillingOrder.prototype.setTasksList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.cbv1.Task=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.Task}
 */
proto.cbv1.BillingOrder.prototype.addTasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.cbv1.Task, opt_index);
};


proto.cbv1.BillingOrder.prototype.clearTasksList = function() {
  this.setTasksList([]);
};


/**
 * optional VendorContract current_contract = 16;
 * @return {?proto.cbv1.VendorContract}
 */
proto.cbv1.BillingOrder.prototype.getCurrentContract = function() {
  return /** @type{?proto.cbv1.VendorContract} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_contracts_contracts_pb.VendorContract, 16));
};


/** @param {?proto.cbv1.VendorContract|undefined} value */
proto.cbv1.BillingOrder.prototype.setCurrentContract = function(value) {
  jspb.Message.setWrapperField(this, 16, value);
};


proto.cbv1.BillingOrder.prototype.clearCurrentContract = function() {
  this.setCurrentContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.BillingOrder.prototype.hasCurrentContract = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * repeated OrderBillingHistory billing_history = 17;
 * @return {!Array<!proto.cbv1.OrderBillingHistory>}
 */
proto.cbv1.BillingOrder.prototype.getBillingHistoryList = function() {
  return /** @type{!Array<!proto.cbv1.OrderBillingHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.OrderBillingHistory, 17));
};


/** @param {!Array<!proto.cbv1.OrderBillingHistory>} value */
proto.cbv1.BillingOrder.prototype.setBillingHistoryList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.cbv1.OrderBillingHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.OrderBillingHistory}
 */
proto.cbv1.BillingOrder.prototype.addBillingHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.cbv1.OrderBillingHistory, opt_index);
};


proto.cbv1.BillingOrder.prototype.clearBillingHistoryList = function() {
  this.setBillingHistoryList([]);
};


/**
 * repeated OrderBillingStatusHistory billing_status_history = 18;
 * @return {!Array<!proto.cbv1.OrderBillingStatusHistory>}
 */
proto.cbv1.BillingOrder.prototype.getBillingStatusHistoryList = function() {
  return /** @type{!Array<!proto.cbv1.OrderBillingStatusHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.OrderBillingStatusHistory, 18));
};


/** @param {!Array<!proto.cbv1.OrderBillingStatusHistory>} value */
proto.cbv1.BillingOrder.prototype.setBillingStatusHistoryList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 18, value);
};


/**
 * @param {!proto.cbv1.OrderBillingStatusHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.OrderBillingStatusHistory}
 */
proto.cbv1.BillingOrder.prototype.addBillingStatusHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 18, opt_value, proto.cbv1.OrderBillingStatusHistory, opt_index);
};


proto.cbv1.BillingOrder.prototype.clearBillingStatusHistoryList = function() {
  this.setBillingStatusHistoryList([]);
};


/**
 * repeated Charge charges = 19;
 * @return {!Array<!proto.cbv1.Charge>}
 */
proto.cbv1.BillingOrder.prototype.getChargesList = function() {
  return /** @type{!Array<!proto.cbv1.Charge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.Charge, 19));
};


/** @param {!Array<!proto.cbv1.Charge>} value */
proto.cbv1.BillingOrder.prototype.setChargesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.cbv1.Charge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.Charge}
 */
proto.cbv1.BillingOrder.prototype.addCharges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.cbv1.Charge, opt_index);
};


proto.cbv1.BillingOrder.prototype.clearChargesList = function() {
  this.setChargesList([]);
};


/**
 * optional string invoice_number = 20;
 * @return {string}
 */
proto.cbv1.BillingOrder.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/** @param {string} value */
proto.cbv1.BillingOrder.prototype.setInvoiceNumber = function(value) {
  jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * repeated OrderAttachment attachments = 21;
 * @return {!Array<!proto.cbv1.OrderAttachment>}
 */
proto.cbv1.BillingOrder.prototype.getAttachmentsList = function() {
  return /** @type{!Array<!proto.cbv1.OrderAttachment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_order_order_pb.OrderAttachment, 21));
};


/** @param {!Array<!proto.cbv1.OrderAttachment>} value */
proto.cbv1.BillingOrder.prototype.setAttachmentsList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.cbv1.OrderAttachment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.OrderAttachment}
 */
proto.cbv1.BillingOrder.prototype.addAttachments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.cbv1.OrderAttachment, opt_index);
};


proto.cbv1.BillingOrder.prototype.clearAttachmentsList = function() {
  this.setAttachmentsList([]);
};


/**
 * optional BillingStatus billing_status = 22;
 * @return {!proto.cbv1.BillingStatus}
 */
proto.cbv1.BillingOrder.prototype.getBillingStatus = function() {
  return /** @type {!proto.cbv1.BillingStatus} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/** @param {!proto.cbv1.BillingStatus} value */
proto.cbv1.BillingOrder.prototype.setBillingStatus = function(value) {
  jspb.Message.setProto3EnumField(this, 22, value);
};


/**
 * optional string trailer = 23;
 * @return {string}
 */
proto.cbv1.BillingOrder.prototype.getTrailer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/** @param {string} value */
proto.cbv1.BillingOrder.prototype.setTrailer = function(value) {
  jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional int64 deadhead_distance = 24;
 * @return {number}
 */
proto.cbv1.BillingOrder.prototype.getDeadheadDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/** @param {number} value */
proto.cbv1.BillingOrder.prototype.setDeadheadDistance = function(value) {
  jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int64 deadhead_duration = 25;
 * @return {number}
 */
proto.cbv1.BillingOrder.prototype.getDeadheadDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/** @param {number} value */
proto.cbv1.BillingOrder.prototype.setDeadheadDuration = function(value) {
  jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional int64 return_distance = 26;
 * @return {number}
 */
proto.cbv1.BillingOrder.prototype.getReturnDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/** @param {number} value */
proto.cbv1.BillingOrder.prototype.setReturnDistance = function(value) {
  jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional int64 return_duration = 27;
 * @return {number}
 */
proto.cbv1.BillingOrder.prototype.getReturnDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/** @param {number} value */
proto.cbv1.BillingOrder.prototype.setReturnDuration = function(value) {
  jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional int64 trip_mileage = 28;
 * @return {number}
 */
proto.cbv1.BillingOrder.prototype.getTripMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/** @param {number} value */
proto.cbv1.BillingOrder.prototype.setTripMileage = function(value) {
  jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional int64 trip_duration = 29;
 * @return {number}
 */
proto.cbv1.BillingOrder.prototype.getTripDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/** @param {number} value */
proto.cbv1.BillingOrder.prototype.setTripDuration = function(value) {
  jspb.Message.setProto3IntField(this, 29, value);
};


/**
 * optional string pushback_reason = 30;
 * @return {string}
 */
proto.cbv1.BillingOrder.prototype.getPushbackReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/** @param {string} value */
proto.cbv1.BillingOrder.prototype.setPushbackReason = function(value) {
  jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string ticket_number = 31;
 * @return {string}
 */
proto.cbv1.BillingOrder.prototype.getTicketNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/** @param {string} value */
proto.cbv1.BillingOrder.prototype.setTicketNumber = function(value) {
  jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * repeated ChargeHistory AccessorialChargeHistory = 32;
 * @return {!Array<!proto.cbv1.ChargeHistory>}
 */
proto.cbv1.BillingOrder.prototype.getAccessorialchargehistoryList = function() {
  return /** @type{!Array<!proto.cbv1.ChargeHistory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.ChargeHistory, 32));
};


/** @param {!Array<!proto.cbv1.ChargeHistory>} value */
proto.cbv1.BillingOrder.prototype.setAccessorialchargehistoryList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 32, value);
};


/**
 * @param {!proto.cbv1.ChargeHistory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.ChargeHistory}
 */
proto.cbv1.BillingOrder.prototype.addAccessorialchargehistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 32, opt_value, proto.cbv1.ChargeHistory, opt_index);
};


proto.cbv1.BillingOrder.prototype.clearAccessorialchargehistoryList = function() {
  this.setAccessorialchargehistoryList([]);
};


/**
 * repeated TaskBillingSummary task_summary = 33;
 * @return {!Array<!proto.cbv1.TaskBillingSummary>}
 */
proto.cbv1.BillingOrder.prototype.getTaskSummaryList = function() {
  return /** @type{!Array<!proto.cbv1.TaskBillingSummary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.TaskBillingSummary, 33));
};


/** @param {!Array<!proto.cbv1.TaskBillingSummary>} value */
proto.cbv1.BillingOrder.prototype.setTaskSummaryList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 33, value);
};


/**
 * @param {!proto.cbv1.TaskBillingSummary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.TaskBillingSummary}
 */
proto.cbv1.BillingOrder.prototype.addTaskSummary = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 33, opt_value, proto.cbv1.TaskBillingSummary, opt_index);
};


proto.cbv1.BillingOrder.prototype.clearTaskSummaryList = function() {
  this.setTaskSummaryList([]);
};


/**
 * optional int64 estimated_trip_distance = 34;
 * @return {number}
 */
proto.cbv1.BillingOrder.prototype.getEstimatedTripDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 34, 0));
};


/** @param {number} value */
proto.cbv1.BillingOrder.prototype.setEstimatedTripDistance = function(value) {
  jspb.Message.setProto3IntField(this, 34, value);
};


/**
 * optional int64 estimated_trip_duration = 35;
 * @return {number}
 */
proto.cbv1.BillingOrder.prototype.getEstimatedTripDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/** @param {number} value */
proto.cbv1.BillingOrder.prototype.setEstimatedTripDuration = function(value) {
  jspb.Message.setProto3IntField(this, 35, value);
};


/**
 * optional string comment = 36;
 * @return {string}
 */
proto.cbv1.BillingOrder.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/** @param {string} value */
proto.cbv1.BillingOrder.prototype.setComment = function(value) {
  jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional int32 back_haul_distance = 37;
 * @return {number}
 */
proto.cbv1.BillingOrder.prototype.getBackHaulDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/** @param {number} value */
proto.cbv1.BillingOrder.prototype.setBackHaulDistance = function(value) {
  jspb.Message.setProto3IntField(this, 37, value);
};


/**
 * optional int32 back_haul_duration = 38;
 * @return {number}
 */
proto.cbv1.BillingOrder.prototype.getBackHaulDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 38, 0));
};


/** @param {number} value */
proto.cbv1.BillingOrder.prototype.setBackHaulDuration = function(value) {
  jspb.Message.setProto3IntField(this, 38, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.TaskBillingSummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.TaskBillingSummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.TaskBillingSummary.displayName = 'proto.cbv1.TaskBillingSummary';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.TaskBillingSummary.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.TaskBillingSummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.TaskBillingSummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.TaskBillingSummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    taskId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    siteName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entryTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    exitTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    completeTime: jspb.Message.getFieldWithDefault(msg, 5, 0),
    waitAtSite: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.TaskBillingSummary}
 */
proto.cbv1.TaskBillingSummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.TaskBillingSummary;
  return proto.cbv1.TaskBillingSummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.TaskBillingSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.TaskBillingSummary}
 */
proto.cbv1.TaskBillingSummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTaskId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSiteName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEntryTime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExitTime(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCompleteTime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWaitAtSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.TaskBillingSummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.TaskBillingSummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.TaskBillingSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.TaskBillingSummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaskId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSiteName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntryTime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getExitTime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getCompleteTime();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getWaitAtSite();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional int64 task_id = 1;
 * @return {number}
 */
proto.cbv1.TaskBillingSummary.prototype.getTaskId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.TaskBillingSummary.prototype.setTaskId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string site_name = 2;
 * @return {string}
 */
proto.cbv1.TaskBillingSummary.prototype.getSiteName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.TaskBillingSummary.prototype.setSiteName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 entry_time = 3;
 * @return {number}
 */
proto.cbv1.TaskBillingSummary.prototype.getEntryTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.TaskBillingSummary.prototype.setEntryTime = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 exit_time = 4;
 * @return {number}
 */
proto.cbv1.TaskBillingSummary.prototype.getExitTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.TaskBillingSummary.prototype.setExitTime = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 complete_time = 5;
 * @return {number}
 */
proto.cbv1.TaskBillingSummary.prototype.getCompleteTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/** @param {number} value */
proto.cbv1.TaskBillingSummary.prototype.setCompleteTime = function(value) {
  jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 wait_at_site = 6;
 * @return {number}
 */
proto.cbv1.TaskBillingSummary.prototype.getWaitAtSite = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.TaskBillingSummary.prototype.setWaitAtSite = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.NameAndId = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.NameAndId, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.NameAndId.displayName = 'proto.cbv1.NameAndId';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.NameAndId.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.NameAndId.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.NameAndId} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NameAndId.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.NameAndId}
 */
proto.cbv1.NameAndId.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.NameAndId;
  return proto.cbv1.NameAndId.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.NameAndId} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.NameAndId}
 */
proto.cbv1.NameAndId.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.NameAndId.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.NameAndId.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.NameAndId} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NameAndId.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.NameAndId.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.NameAndId.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.NameAndId.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.NameAndId.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.NameAndUid = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.NameAndUid, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.NameAndUid.displayName = 'proto.cbv1.NameAndUid';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.NameAndUid.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.NameAndUid.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.NameAndUid} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NameAndUid.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.NameAndUid}
 */
proto.cbv1.NameAndUid.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.NameAndUid;
  return proto.cbv1.NameAndUid.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.NameAndUid} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.NameAndUid}
 */
proto.cbv1.NameAndUid.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.NameAndUid.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.NameAndUid.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.NameAndUid} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.NameAndUid.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.cbv1.NameAndUid.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.NameAndUid.prototype.setUid = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.NameAndUid.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.NameAndUid.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CommonFilters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.CommonFilters.repeatedFields_, null);
};
goog.inherits(proto.cbv1.CommonFilters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CommonFilters.displayName = 'proto.cbv1.CommonFilters';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.CommonFilters.repeatedFields_ = [6,8,9,10,11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CommonFilters.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CommonFilters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CommonFilters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CommonFilters.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
    orderedAfterUnix: jspb.Message.getFieldWithDefault(msg, 3, 0),
    orderByColumn: jspb.Message.getFieldWithDefault(msg, 4, ""),
    orderBy: jspb.Message.getFieldWithDefault(msg, 5, ""),
    vendorIdsList: jspb.Message.getRepeatedField(msg, 6),
    searchQuery: jspb.Message.getFieldWithDefault(msg, 7, ""),
    payloadIdsList: jspb.Message.getRepeatedField(msg, 8),
    districtIdsList: jspb.Message.getRepeatedField(msg, 9),
    costCenterIdsList: jspb.Message.getRepeatedField(msg, 10),
    siteIdsList: jspb.Message.getRepeatedField(msg, 11),
    lmoIdsList: jspb.Message.getRepeatedField(msg, 12)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CommonFilters}
 */
proto.cbv1.CommonFilters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CommonFilters;
  return proto.cbv1.CommonFilters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CommonFilters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CommonFilters}
 */
proto.cbv1.CommonFilters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderedAfterUnix(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderByColumn(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderBy(value);
      break;
    case 6:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setVendorIdsList(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchQuery(value);
      break;
    case 8:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setPayloadIdsList(value);
      break;
    case 9:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setDistrictIdsList(value);
      break;
    case 10:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setCostCenterIdsList(value);
      break;
    case 11:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setSiteIdsList(value);
      break;
    case 12:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setLmoIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CommonFilters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CommonFilters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CommonFilters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CommonFilters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOrderedAfterUnix();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOrderByColumn();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOrderBy();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVendorIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      6,
      f
    );
  }
  f = message.getSearchQuery();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPayloadIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      8,
      f
    );
  }
  f = message.getDistrictIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      9,
      f
    );
  }
  f = message.getCostCenterIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      10,
      f
    );
  }
  f = message.getSiteIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      11,
      f
    );
  }
  f = message.getLmoIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      12,
      f
    );
  }
};


/**
 * optional int64 limit = 1;
 * @return {number}
 */
proto.cbv1.CommonFilters.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.CommonFilters.prototype.setLimit = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 offset = 2;
 * @return {number}
 */
proto.cbv1.CommonFilters.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.CommonFilters.prototype.setOffset = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 ordered_after_unix = 3;
 * @return {number}
 */
proto.cbv1.CommonFilters.prototype.getOrderedAfterUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.CommonFilters.prototype.setOrderedAfterUnix = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string order_by_column = 4;
 * @return {string}
 */
proto.cbv1.CommonFilters.prototype.getOrderByColumn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.CommonFilters.prototype.setOrderByColumn = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string order_by = 5;
 * @return {string}
 */
proto.cbv1.CommonFilters.prototype.getOrderBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.CommonFilters.prototype.setOrderBy = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated int64 vendor_ids = 6;
 * @return {!Array<number>}
 */
proto.cbv1.CommonFilters.prototype.getVendorIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/** @param {!Array<number>} value */
proto.cbv1.CommonFilters.prototype.setVendorIdsList = function(value) {
  jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.CommonFilters.prototype.addVendorIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


proto.cbv1.CommonFilters.prototype.clearVendorIdsList = function() {
  this.setVendorIdsList([]);
};


/**
 * optional string search_query = 7;
 * @return {string}
 */
proto.cbv1.CommonFilters.prototype.getSearchQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv1.CommonFilters.prototype.setSearchQuery = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated int64 payload_ids = 8;
 * @return {!Array<number>}
 */
proto.cbv1.CommonFilters.prototype.getPayloadIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/** @param {!Array<number>} value */
proto.cbv1.CommonFilters.prototype.setPayloadIdsList = function(value) {
  jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.CommonFilters.prototype.addPayloadIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


proto.cbv1.CommonFilters.prototype.clearPayloadIdsList = function() {
  this.setPayloadIdsList([]);
};


/**
 * repeated int64 district_ids = 9;
 * @return {!Array<number>}
 */
proto.cbv1.CommonFilters.prototype.getDistrictIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/** @param {!Array<number>} value */
proto.cbv1.CommonFilters.prototype.setDistrictIdsList = function(value) {
  jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.CommonFilters.prototype.addDistrictIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


proto.cbv1.CommonFilters.prototype.clearDistrictIdsList = function() {
  this.setDistrictIdsList([]);
};


/**
 * repeated int64 cost_center_ids = 10;
 * @return {!Array<number>}
 */
proto.cbv1.CommonFilters.prototype.getCostCenterIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/** @param {!Array<number>} value */
proto.cbv1.CommonFilters.prototype.setCostCenterIdsList = function(value) {
  jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.CommonFilters.prototype.addCostCenterIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


proto.cbv1.CommonFilters.prototype.clearCostCenterIdsList = function() {
  this.setCostCenterIdsList([]);
};


/**
 * repeated int64 site_ids = 11;
 * @return {!Array<number>}
 */
proto.cbv1.CommonFilters.prototype.getSiteIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/** @param {!Array<number>} value */
proto.cbv1.CommonFilters.prototype.setSiteIdsList = function(value) {
  jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.CommonFilters.prototype.addSiteIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


proto.cbv1.CommonFilters.prototype.clearSiteIdsList = function() {
  this.setSiteIdsList([]);
};


/**
 * repeated int64 lmo_ids = 12;
 * @return {!Array<number>}
 */
proto.cbv1.CommonFilters.prototype.getLmoIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/** @param {!Array<number>} value */
proto.cbv1.CommonFilters.prototype.setLmoIdsList = function(value) {
  jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.CommonFilters.prototype.addLmoIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


proto.cbv1.CommonFilters.prototype.clearLmoIdsList = function() {
  this.setLmoIdsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.SubTaskDetailsList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.SubTaskDetailsList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.SubTaskDetailsList.displayName = 'proto.cbv1.SubTaskDetailsList';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.SubTaskDetailsList.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.SubTaskDetailsList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.SubTaskDetailsList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SubTaskDetailsList.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quantity: +jspb.Message.getFieldWithDefault(msg, 2, 0.0),
    bolNumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bolChanged: jspb.Message.getFieldWithDefault(msg, 4, false),
    quantityChanged: jspb.Message.getFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.SubTaskDetailsList}
 */
proto.cbv1.SubTaskDetailsList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.SubTaskDetailsList;
  return proto.cbv1.SubTaskDetailsList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.SubTaskDetailsList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.SubTaskDetailsList}
 */
proto.cbv1.SubTaskDetailsList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBolNumber(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBolChanged(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setQuantityChanged(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.SubTaskDetailsList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.SubTaskDetailsList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.SubTaskDetailsList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.SubTaskDetailsList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getBolNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBolChanged();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getQuantityChanged();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 id = 1;
 * @return {number}
 */
proto.cbv1.SubTaskDetailsList.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.SubTaskDetailsList.prototype.setId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional float quantity = 2;
 * @return {number}
 */
proto.cbv1.SubTaskDetailsList.prototype.getQuantity = function() {
  return /** @type {number} */ (+jspb.Message.getFieldWithDefault(this, 2, 0.0));
};


/** @param {number} value */
proto.cbv1.SubTaskDetailsList.prototype.setQuantity = function(value) {
  jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string bol_number = 3;
 * @return {string}
 */
proto.cbv1.SubTaskDetailsList.prototype.getBolNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.SubTaskDetailsList.prototype.setBolNumber = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool bol_changed = 4;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.SubTaskDetailsList.prototype.getBolChanged = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 4, false));
};


/** @param {boolean} value */
proto.cbv1.SubTaskDetailsList.prototype.setBolChanged = function(value) {
  jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool quantity_changed = 5;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.SubTaskDetailsList.prototype.getQuantityChanged = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 5, false));
};


/** @param {boolean} value */
proto.cbv1.SubTaskDetailsList.prototype.setQuantityChanged = function(value) {
  jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateNeededFields = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.UpdateNeededFields.repeatedFields_, null);
};
goog.inherits(proto.cbv1.UpdateNeededFields, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateNeededFields.displayName = 'proto.cbv1.UpdateNeededFields';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.UpdateNeededFields.repeatedFields_ = [17,18,19];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateNeededFields.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateNeededFields.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateNeededFields} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateNeededFields.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    deadheadCostPerMile: jspb.Message.getFieldWithDefault(msg, 2, 0),
    deadheadFreeMileage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    deadheadMileage: jspb.Message.getFieldWithDefault(msg, 4, 0),
    driverId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dropOffFreeTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    hourlyDetentionRate: jspb.Message.getFieldWithDefault(msg, 7, 0),
    minimumCost: jspb.Message.getFieldWithDefault(msg, 8, 0),
    perHourTravelCost: jspb.Message.getFieldWithDefault(msg, 9, 0),
    perMileTravelCost: jspb.Message.getFieldWithDefault(msg, 10, 0),
    pickupFreeTime: jspb.Message.getFieldWithDefault(msg, 11, 0),
    tripDuration: jspb.Message.getFieldWithDefault(msg, 12, 0),
    tripMileage: jspb.Message.getFieldWithDefault(msg, 13, 0),
    truckId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    waitAtDropOffSite: jspb.Message.getFieldWithDefault(msg, 15, 0),
    waitAtPickUpSite: jspb.Message.getFieldWithDefault(msg, 16, 0),
    subtasksList: jspb.Message.toObjectList(msg.getSubtasksList(),
    proto.cbv1.SubTaskDetailsList.toObject, includeInstance),
    pointToPointCostsList: jspb.Message.getRepeatedField(msg, 18),
    chargesList: jspb.Message.toObjectList(msg.getChargesList(),
    proto.cbv1.Charge.toObject, includeInstance),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 20, ""),
    ticketNumber: jspb.Message.getFieldWithDefault(msg, 21, ""),
    maxDetention: jspb.Message.getFieldWithDefault(msg, 22, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 23, ""),
    backHaulDistance: jspb.Message.getFieldWithDefault(msg, 24, 0),
    backHaulDuration: jspb.Message.getFieldWithDefault(msg, 25, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateNeededFields}
 */
proto.cbv1.UpdateNeededFields.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateNeededFields;
  return proto.cbv1.UpdateNeededFields.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateNeededFields} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateNeededFields}
 */
proto.cbv1.UpdateNeededFields.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeadheadCostPerMile(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeadheadFreeMileage(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeadheadMileage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDriverId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDropOffFreeTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setHourlyDetentionRate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinimumCost(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPerHourTravelCost(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPerMileTravelCost(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPickupFreeTime(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTripDuration(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTripMileage(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTruckId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWaitAtDropOffSite(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setWaitAtPickUpSite(value);
      break;
    case 17:
      var value = new proto.cbv1.SubTaskDetailsList;
      reader.readMessage(value,proto.cbv1.SubTaskDetailsList.deserializeBinaryFromReader);
      msg.addSubtasks(value);
      break;
    case 18:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setPointToPointCostsList(value);
      break;
    case 19:
      var value = new proto.cbv1.Charge;
      reader.readMessage(value,proto.cbv1.Charge.deserializeBinaryFromReader);
      msg.addCharges(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setTicketNumber(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMaxDetention(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBackHaulDistance(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBackHaulDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateNeededFields.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateNeededFields.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateNeededFields} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateNeededFields.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getDeadheadCostPerMile();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getDeadheadFreeMileage();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getDeadheadMileage();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDriverId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDropOffFreeTime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getHourlyDetentionRate();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getMinimumCost();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getPerHourTravelCost();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getPerMileTravelCost();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getPickupFreeTime();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getTripDuration();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getTripMileage();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getTruckId();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getWaitAtDropOffSite();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getWaitAtPickUpSite();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getSubtasksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      17,
      f,
      proto.cbv1.SubTaskDetailsList.serializeBinaryToWriter
    );
  }
  f = message.getPointToPointCostsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      18,
      f
    );
  }
  f = message.getChargesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      19,
      f,
      proto.cbv1.Charge.serializeBinaryToWriter
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getTicketNumber();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getMaxDetention();
  if (f !== 0) {
    writer.writeInt64(
      22,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getBackHaulDistance();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getBackHaulDuration();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
};


/**
 * optional int64 order_id = 1;
 * @return {number}
 */
proto.cbv1.UpdateNeededFields.prototype.getOrderId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNeededFields.prototype.setOrderId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 deadhead_cost_per_mile = 2;
 * @return {number}
 */
proto.cbv1.UpdateNeededFields.prototype.getDeadheadCostPerMile = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNeededFields.prototype.setDeadheadCostPerMile = function(value) {
  jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 deadhead_free_mileage = 3;
 * @return {number}
 */
proto.cbv1.UpdateNeededFields.prototype.getDeadheadFreeMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNeededFields.prototype.setDeadheadFreeMileage = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 deadhead_mileage = 4;
 * @return {number}
 */
proto.cbv1.UpdateNeededFields.prototype.getDeadheadMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNeededFields.prototype.setDeadheadMileage = function(value) {
  jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string driver_id = 5;
 * @return {string}
 */
proto.cbv1.UpdateNeededFields.prototype.getDriverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.UpdateNeededFields.prototype.setDriverId = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 drop_off_free_time = 6;
 * @return {number}
 */
proto.cbv1.UpdateNeededFields.prototype.getDropOffFreeTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNeededFields.prototype.setDropOffFreeTime = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 hourly_detention_rate = 7;
 * @return {number}
 */
proto.cbv1.UpdateNeededFields.prototype.getHourlyDetentionRate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNeededFields.prototype.setHourlyDetentionRate = function(value) {
  jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int64 minimum_cost = 8;
 * @return {number}
 */
proto.cbv1.UpdateNeededFields.prototype.getMinimumCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNeededFields.prototype.setMinimumCost = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 per_hour_travel_cost = 9;
 * @return {number}
 */
proto.cbv1.UpdateNeededFields.prototype.getPerHourTravelCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNeededFields.prototype.setPerHourTravelCost = function(value) {
  jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 per_mile_travel_cost = 10;
 * @return {number}
 */
proto.cbv1.UpdateNeededFields.prototype.getPerMileTravelCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNeededFields.prototype.setPerMileTravelCost = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 pickup_free_time = 11;
 * @return {number}
 */
proto.cbv1.UpdateNeededFields.prototype.getPickupFreeTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNeededFields.prototype.setPickupFreeTime = function(value) {
  jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int64 trip_duration = 12;
 * @return {number}
 */
proto.cbv1.UpdateNeededFields.prototype.getTripDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNeededFields.prototype.setTripDuration = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 trip_mileage = 13;
 * @return {number}
 */
proto.cbv1.UpdateNeededFields.prototype.getTripMileage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNeededFields.prototype.setTripMileage = function(value) {
  jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 truck_id = 14;
 * @return {number}
 */
proto.cbv1.UpdateNeededFields.prototype.getTruckId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNeededFields.prototype.setTruckId = function(value) {
  jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 wait_at_drop_off_site = 15;
 * @return {number}
 */
proto.cbv1.UpdateNeededFields.prototype.getWaitAtDropOffSite = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNeededFields.prototype.setWaitAtDropOffSite = function(value) {
  jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 wait_at_pick_up_site = 16;
 * @return {number}
 */
proto.cbv1.UpdateNeededFields.prototype.getWaitAtPickUpSite = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNeededFields.prototype.setWaitAtPickUpSite = function(value) {
  jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * repeated SubTaskDetailsList subtasks = 17;
 * @return {!Array<!proto.cbv1.SubTaskDetailsList>}
 */
proto.cbv1.UpdateNeededFields.prototype.getSubtasksList = function() {
  return /** @type{!Array<!proto.cbv1.SubTaskDetailsList>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.SubTaskDetailsList, 17));
};


/** @param {!Array<!proto.cbv1.SubTaskDetailsList>} value */
proto.cbv1.UpdateNeededFields.prototype.setSubtasksList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 17, value);
};


/**
 * @param {!proto.cbv1.SubTaskDetailsList=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.SubTaskDetailsList}
 */
proto.cbv1.UpdateNeededFields.prototype.addSubtasks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 17, opt_value, proto.cbv1.SubTaskDetailsList, opt_index);
};


proto.cbv1.UpdateNeededFields.prototype.clearSubtasksList = function() {
  this.setSubtasksList([]);
};


/**
 * repeated int64 point_to_point_costs = 18;
 * @return {!Array<number>}
 */
proto.cbv1.UpdateNeededFields.prototype.getPointToPointCostsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 18));
};


/** @param {!Array<number>} value */
proto.cbv1.UpdateNeededFields.prototype.setPointToPointCostsList = function(value) {
  jspb.Message.setField(this, 18, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.cbv1.UpdateNeededFields.prototype.addPointToPointCosts = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 18, value, opt_index);
};


proto.cbv1.UpdateNeededFields.prototype.clearPointToPointCostsList = function() {
  this.setPointToPointCostsList([]);
};


/**
 * repeated Charge charges = 19;
 * @return {!Array<!proto.cbv1.Charge>}
 */
proto.cbv1.UpdateNeededFields.prototype.getChargesList = function() {
  return /** @type{!Array<!proto.cbv1.Charge>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.cbv1.Charge, 19));
};


/** @param {!Array<!proto.cbv1.Charge>} value */
proto.cbv1.UpdateNeededFields.prototype.setChargesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 19, value);
};


/**
 * @param {!proto.cbv1.Charge=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.Charge}
 */
proto.cbv1.UpdateNeededFields.prototype.addCharges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 19, opt_value, proto.cbv1.Charge, opt_index);
};


proto.cbv1.UpdateNeededFields.prototype.clearChargesList = function() {
  this.setChargesList([]);
};


/**
 * optional string invoice_number = 20;
 * @return {string}
 */
proto.cbv1.UpdateNeededFields.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/** @param {string} value */
proto.cbv1.UpdateNeededFields.prototype.setInvoiceNumber = function(value) {
  jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string ticket_number = 21;
 * @return {string}
 */
proto.cbv1.UpdateNeededFields.prototype.getTicketNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/** @param {string} value */
proto.cbv1.UpdateNeededFields.prototype.setTicketNumber = function(value) {
  jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional int64 max_detention = 22;
 * @return {number}
 */
proto.cbv1.UpdateNeededFields.prototype.getMaxDetention = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNeededFields.prototype.setMaxDetention = function(value) {
  jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional string comment = 23;
 * @return {string}
 */
proto.cbv1.UpdateNeededFields.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/** @param {string} value */
proto.cbv1.UpdateNeededFields.prototype.setComment = function(value) {
  jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional int32 back_haul_distance = 24;
 * @return {number}
 */
proto.cbv1.UpdateNeededFields.prototype.getBackHaulDistance = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNeededFields.prototype.setBackHaulDistance = function(value) {
  jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 back_haul_duration = 25;
 * @return {number}
 */
proto.cbv1.UpdateNeededFields.prototype.getBackHaulDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/** @param {number} value */
proto.cbv1.UpdateNeededFields.prototype.setBackHaulDuration = function(value) {
  jspb.Message.setProto3IntField(this, 25, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.batchInvoice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.batchInvoice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.batchInvoice.displayName = 'proto.cbv1.batchInvoice';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.batchInvoice.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.batchInvoice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.batchInvoice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.batchInvoice.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    invoiceNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    totalCost: jspb.Message.getFieldWithDefault(msg, 3, 0),
    approvedById: jspb.Message.getFieldWithDefault(msg, 4, ""),
    approvedByName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    vendorId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    vendorName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    rejectionTimeUnix: jspb.Message.getFieldWithDefault(msg, 8, 0),
    canUnapprove: jspb.Message.getFieldWithDefault(msg, 9, false),
    statusChangedTimeUnix: jspb.Message.getFieldWithDefault(msg, 10, 0),
    lmoName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    lmoId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    isAutoApproved: jspb.Message.getFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.batchInvoice}
 */
proto.cbv1.batchInvoice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.batchInvoice;
  return proto.cbv1.batchInvoice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.batchInvoice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.batchInvoice}
 */
proto.cbv1.batchInvoice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOrderCount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInvoiceNumber(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalCost(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setApprovedById(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setApprovedByName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setVendorId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendorName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRejectionTimeUnix(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanUnapprove(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStatusChangedTimeUnix(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLmoName(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLmoId(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAutoApproved(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.batchInvoice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.batchInvoice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.batchInvoice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.batchInvoice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getInvoiceNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTotalCost();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getApprovedById();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getApprovedByName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getVendorId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getVendorName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRejectionTimeUnix();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getCanUnapprove();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getStatusChangedTimeUnix();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getLmoName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLmoId();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getIsAutoApproved();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional int64 order_count = 1;
 * @return {number}
 */
proto.cbv1.batchInvoice.prototype.getOrderCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.batchInvoice.prototype.setOrderCount = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string invoice_number = 2;
 * @return {string}
 */
proto.cbv1.batchInvoice.prototype.getInvoiceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.batchInvoice.prototype.setInvoiceNumber = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 total_cost = 3;
 * @return {number}
 */
proto.cbv1.batchInvoice.prototype.getTotalCost = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/** @param {number} value */
proto.cbv1.batchInvoice.prototype.setTotalCost = function(value) {
  jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string approved_by_id = 4;
 * @return {string}
 */
proto.cbv1.batchInvoice.prototype.getApprovedById = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.batchInvoice.prototype.setApprovedById = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string approved_by_name = 5;
 * @return {string}
 */
proto.cbv1.batchInvoice.prototype.getApprovedByName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/** @param {string} value */
proto.cbv1.batchInvoice.prototype.setApprovedByName = function(value) {
  jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 vendor_id = 6;
 * @return {number}
 */
proto.cbv1.batchInvoice.prototype.getVendorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/** @param {number} value */
proto.cbv1.batchInvoice.prototype.setVendorId = function(value) {
  jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string vendor_name = 7;
 * @return {string}
 */
proto.cbv1.batchInvoice.prototype.getVendorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/** @param {string} value */
proto.cbv1.batchInvoice.prototype.setVendorName = function(value) {
  jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int64 rejection_time_unix = 8;
 * @return {number}
 */
proto.cbv1.batchInvoice.prototype.getRejectionTimeUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/** @param {number} value */
proto.cbv1.batchInvoice.prototype.setRejectionTimeUnix = function(value) {
  jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional bool can_unapprove = 9;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.batchInvoice.prototype.getCanUnapprove = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 9, false));
};


/** @param {boolean} value */
proto.cbv1.batchInvoice.prototype.setCanUnapprove = function(value) {
  jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int64 status_changed_time_unix = 10;
 * @return {number}
 */
proto.cbv1.batchInvoice.prototype.getStatusChangedTimeUnix = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/** @param {number} value */
proto.cbv1.batchInvoice.prototype.setStatusChangedTimeUnix = function(value) {
  jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string lmo_name = 11;
 * @return {string}
 */
proto.cbv1.batchInvoice.prototype.getLmoName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/** @param {string} value */
proto.cbv1.batchInvoice.prototype.setLmoName = function(value) {
  jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 lmo_id = 12;
 * @return {number}
 */
proto.cbv1.batchInvoice.prototype.getLmoId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/** @param {number} value */
proto.cbv1.batchInvoice.prototype.setLmoId = function(value) {
  jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool is_auto_approved = 13;
 * Note that Boolean fields may be set to 0/1 when serialized from a Java server.
 * You should avoid comparisons like {@code val === true/false} in those cases.
 * @return {boolean}
 */
proto.cbv1.batchInvoice.prototype.getIsAutoApproved = function() {
  return /** @type {boolean} */ (jspb.Message.getFieldWithDefault(this, 13, false));
};


/** @param {boolean} value */
proto.cbv1.batchInvoice.prototype.setIsAutoApproved = function(value) {
  jspb.Message.setProto3BooleanField(this, 13, value);
};


goog.object.extend(exports, proto.cbv1);
