import { Component, Input, OnInit } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DriverReplayService } from '~services/driver-replay.service';
import { Waypoint } from '../../../proto/user/user/user_pb';

export interface WaypointStats {
  totalWaypoints: number;
  goodWaypoints: number;
}

@Component({
  selector: 'ct-order-map-controls',
  styleUrls: ['./order-map-controls.component.scss'],
  templateUrl: './order-map-controls.component.html',
})
export class OrderMapControlsComponent implements OnInit {
  @Input() public orderId: number;

  public waypoints$: Observable<Waypoint.AsObject[]>;
  public waypointStats$: Observable<WaypointStats>;
  public selectedWaypoint$: Observable<Waypoint.AsObject>;

  constructor(public driverReplay: DriverReplayService) {}

  public ngOnInit() {
    this.waypoints$ = this.driverReplay.waypoints$;
    this.selectedWaypoint$ = this.driverReplay.selectedWaypoint$;
    this.waypointStats$ = this.waypoints$.pipe(
      map((waypoints) => {
        if (!waypoints || waypoints.length === 0) {
          return null;
        }
        const stats: WaypointStats = {
          goodWaypoints: 0,
          totalWaypoints: 0,
        };
        waypoints.forEach((waypoint) => {
          stats.totalWaypoints++;
          if (waypoint.accuracy < 100) {
            stats.goodWaypoints++;
          }
        });
        return stats;
      }),
      filter((stats) => !!stats),
    );
  }

  public sliderChange(event: any) {
    this.driverReplay.setActiveWaypoint(+event.target.value);
  }

  public speedChange(event: MatSliderChange) {
    this.driverReplay.setSpeed(event.value);
  }

  public speedFormatter(value: number | null) {
    if (!value) {
      return 0;
    }
    return `${value}x`;
  }

  public getTime(unixTime: number) {
    return new Date(unixTime * 1000);
  }
}
