import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, interval, Observable, Subject } from 'rxjs';
import { map, switchMap, take, tap, throttleTime } from 'rxjs/operators';
import { Alarm } from '~proto/alarm/alarm_pb';
import { LMOSiteActivityFeedRequest, LMOSiteActivityFeedResponse } from '~proto/site/site_api_pb';
import { SiteAPI } from '~proto/site/site_api_pb_service';
import { AuthService } from '~services/auth.service';
import { FeatureFlagService } from '~services/feature-flag.service';
import { GrpcService } from '~services/grpc.service';
import { RouterStateService } from '~services/router-state.service';
import * as fromRouterConstants from '../../app-routing.constants';

const pollingTime = 60 * 1000; // one minute

@Injectable({
  providedIn: 'root',
})
export class LmoSiteAlarmsService {
  private refreshAlarms$ = new Subject<null>();
  private alarms$$: BehaviorSubject<Alarm.AsObject[]> = new BehaviorSubject([]);

  public get alarms$(): Observable<Alarm.AsObject[]> {
    this.refreshAlarms$.next();
    return this.alarms$$.asObservable();
  }

  constructor(
    private grpcService: GrpcService,
    private routerState: RouterStateService,
    private featureFlagService: FeatureFlagService,
    private authService: AuthService,
  ) {
    this.refreshAlarms$.pipe(throttleTime(2000)).subscribe(() => {
      this.getAlarms();
    });
    this.alarmPolling();
  }

  private getAlarms(backgroundRequest = false) {
    this.routerState
      .listenForParamChange$(fromRouterConstants.JOB_ID)
      .pipe(take(1))
      .subscribe((jobId) => {
        if (!jobId) {
          return;
        }
        const request = new LMOSiteActivityFeedRequest();
        request.setId(+jobId);
        this.grpcService
          .invoke$(SiteAPI.LMOSiteActivityFeed, request, backgroundRequest)
          .subscribe((response: LMOSiteActivityFeedResponse) => {
            const alarms = response.toObject().alarmsList;
            this.alarms$$.next(alarms);
          });
      });
  }

  private alarmPolling() {
    interval(pollingTime)
      .pipe(
        switchMap(() =>
          combineLatest([
            this.featureFlagService.isFlagActive$('pollingLMO').pipe(take(1)),
            this.authService.isLMO$(),
          ]).pipe(map(([isFlagActive, isLmo]) => isFlagActive && isLmo)),
        ),
        tap((shouldPoll) => {
          if (shouldPoll) {
            this.getAlarms(true);
          }
        }),
      )
      .subscribe();
  }
}
