import { Pipe, PipeTransform } from '@angular/core';
import { DriverStatusType } from '~proto/types/types_pb';

@Pipe({
  name: 'driverStatus',
})
export class DriverStatusPipe implements PipeTransform {
  public transform(value: number): any {
    switch (value) {
      case DriverStatusType.DRIVER_STATUS_TYPE_ACTION_NEEDED:
        return 'Action Needed';
      case DriverStatusType.DRIVER_STATUS_TYPE_INVALID:
        return 'Invalid';
      case DriverStatusType.DRIVER_STATUS_TYPE_OFFLINE:
        return 'Offline';
      case DriverStatusType.DRIVER_STATUS_TYPE_ONLINE:
        return 'Online';
      case DriverStatusType.DRIVER_STATUS_TYPE_UNREACHABLE:
        return 'Unreachable';
      default:
        return '';
    }
  }
}
