import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, switchMap, throttleTime } from 'rxjs/operators';
import {
  CreatePayloadRequest,
  CreatePayloadResponse,
  FavoritePayloadRequest,
  FavoritePayloadResponse,
  ListAllPayloadsRequest,
  ListAllPayloadsResponse,
  ListPayloadsRequest,
  ListPayloadsResponse,
  SearchPayloadsRequest,
  SearchPayloadsResponse,
  UpdatePayloadRequest,
  UpdatePayloadResponse,
} from '~proto/payload/payload_api_pb';
import { PayloadAPI } from '~proto/payload/payload_api_pb_service';
import { Payload, PayloadGroup } from '~proto/payload/payload_pb';
import { GrpcService } from '~services/grpc.service';
import { RouterStateService } from '~services/router-state.service';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { observableArrayFromArrayGetter$, observableArrayFromRecordGetter$ } from '~utilities/observableGetter';
import * as fromRouterConstants from '../../app-routing.constants';

@Injectable({
  providedIn: 'root',
})
export class PayloadService {
  private searching$$ = new BehaviorSubject(false);

  private loadAllPayloadsThrottle$$ = new Subject();
  private loadAllPayloadGroupsThrottle$$ = new Subject();

  private allPayloads$$: BehaviorSubject<Record<number, Payload.AsObject>> = new BehaviorSubject([]);
  private allPayloadGroups$$: BehaviorSubject<PayloadGroup.AsObject[]> = new BehaviorSubject([]);
  private currentSitePayloads$$: BehaviorSubject<PayloadGroup.AsObject[]> = new BehaviorSubject([]);

  public get searching$(): Observable<boolean> {
    return this.searching$$.asObservable();
  }

  public get allPayloads$(): Observable<Payload.AsObject[]> {
    this.loadAllPayloads();
    return observableArrayFromRecordGetter$(this.allPayloads$$);
  }

  public get allPayloadGroups$(): Observable<PayloadGroup.AsObject[]> {
    this.loadAllPayloadGroups();
    return observableArrayFromArrayGetter$(this.allPayloadGroups$$);
  }

  public get currentPayload$(): Observable<Payload.AsObject> {
    this.loadAllPayloads();
    return combineLatest([
      this.routerState.listenForParamChange$(fromRouterConstants.PAYLOAD_ID),
      this.allPayloads$$,
    ]).pipe(map(([payloadId, payloads]) => payloads[payloadId]));
  }

  constructor(private routerState: RouterStateService, private grpc: GrpcService, private snackBar: MatSnackBar) {
    this.loadAllPayloadsThrottle$$.pipe(throttleTime(100)).subscribe(() => this._loadAllPayloads());
    this.loadAllPayloadGroupsThrottle$$.pipe(throttleTime(100)).subscribe(() => this._loadAllPayloadGroups());
  }

  private loadAllPayloads() {
    this.loadAllPayloadsThrottle$$.next();
  }

  private _loadAllPayloads() {
    const request = new ListAllPayloadsRequest();
    this.grpc.invoke$(PayloadAPI.ListAllPayloads, request).subscribe((response: ListAllPayloadsResponse) => {
      this.allPayloads$$.next(idArrayToRecord(response.toObject().payloadsList));
    });
  }

  private loadAllPayloadGroups() {
    this.loadAllPayloadGroupsThrottle$$.next();
  }

  private _loadAllPayloadGroups() {
    const payloadGroupRequest = new ListPayloadsRequest();
    this.grpc.invoke$(PayloadAPI.ListPayloads, payloadGroupRequest).subscribe((message: ListPayloadsResponse) => {
      this.allPayloadGroups$$.next(message.toObject().payloadGroupsList);
    });
  }

  public get currentSitePayloads$(): Observable<PayloadGroup.AsObject[]> {
    this.getPayloadsForSite();
    return observableArrayFromArrayGetter$(this.currentSitePayloads$$);
  }

  private getPayloadsForSite() {
    this.routerState
      .listenForParamChange$(fromRouterConstants.JOB_ID)
      .pipe(
        switchMap((jobID) => {
          const payloadRequest = new ListPayloadsRequest();
          payloadRequest.setSiteId(+jobID);
          return this.grpc.invoke$(PayloadAPI.ListPayloads, payloadRequest) as Observable<ListPayloadsResponse>;
        }),
      )
      .subscribe((message: ListPayloadsResponse) => {
        this.currentSitePayloads$$.next(message.toObject().payloadGroupsList);
      });
  }

  public updatePayloadFavorites(payloadID: number, isFavorited: boolean) {
    this.routerState
      .listenForParamChange$(fromRouterConstants.JOB_ID)
      .pipe(
        switchMap((jobId) => {
          const request = new FavoritePayloadRequest();
          request.setSiteId(+jobId);
          request.setPayloadId(payloadID);
          request.setFavorited(isFavorited);
          return this.grpc.invoke$(PayloadAPI.FavoritePayload, request) as Observable<FavoritePayloadResponse>;
        }),
      )
      .subscribe((response) => {
        this.getPayloadsForSite();
        const snackbarMsg = response.toObject().favorited ? 'Payload Pinned' : 'Payload Unpinned';
        this.snackBar.open(snackbarMsg, null, { duration: 5000 });
      });
  }

  public createPayload$(createPayloadRequest: CreatePayloadRequest): Observable<Payload.AsObject> {
    return this.grpc
      .invoke$(PayloadAPI.CreatePayload, createPayloadRequest)
      .pipe(map((response: CreatePayloadResponse) => response && response.toObject().payload));
  }

  public updatePayload$(updatePayloadRequest: UpdatePayloadRequest): Observable<Payload.AsObject> {
    return this.grpc
      .invoke$(PayloadAPI.UpdatePayload, updatePayloadRequest)
      .pipe(map((response: UpdatePayloadResponse) => response && response.toObject().payload));
  }

  public searchPayloads$(searchTerm: string): Observable<Payload.AsObject[]> {
    this.searching$$.next(true);
    const request = new SearchPayloadsRequest();
    request.setPayloadName(searchTerm);
    return this.grpc.invoke$(PayloadAPI.SearchPayloads, request).pipe(
      map((results: SearchPayloadsResponse) => {
        this.searching$$.next(false);
        return results.toObject().payloadsList;
      }),
    );
  }
}
