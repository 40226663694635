import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Order, SubTask } from '~proto/order/order_pb';
import { OrderType } from '~proto/types/types_pb';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-subtask',
  styleUrls: ['./subtask.component.scss'],
  templateUrl: './subtask.component.html',
})
export class SubtaskComponent implements OnInit {
  @Input() public order: Order.AsObject;
  @Input() public subtask: SubTask.AsObject;

  constructor() {}

  public ngOnInit() {}

  public isCustomOrder(): Boolean {
    return this.order != null && this.order.type === OrderType.ORDER_TYPE_CUSTOM;
  }
}
