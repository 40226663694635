/// <reference types="@types/googlemaps" />
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { MappableOrder, Order } from '~proto/order/order_pb';
import { SiteShort, VendorSiteSummary } from '~proto/site/site_pb';
import { TaskStatus, TaskType, TaskTypeMap } from '~proto/types/types_pb';
import { Driver } from '~proto/user/user_pb';
import { idArrayToRecord, uidArrayToRecord } from '~utilities/idArrayToRecord';
import { InfoBox } from './infobox.js';

const orderTaskColors: Record<TaskTypeMap[keyof TaskTypeMap], string> = {
  [TaskType.TASK_TYPE_INVALID]: '#999999',
  [TaskType.TASK_TYPE_DROPOFF]: '#448AFF',
  [TaskType.TASK_TYPE_PICKUP]: '#5F9A7E',
  [TaskType.TASK_TYPE_OTHER]: '#999999',
  [TaskType.TASK_TYPE_RETURN_MATERIALS]: '#448AFF',
  [TaskType.TASK_TYPE_AD_HOC]: '#448AFF',
  [TaskType.TASK_TYPE_ASSET_PICKUP]: '#448AFF',
};

interface SiteInfo {
  id: number;
  lat: number;
  lng: number;
  name: string;
  radius: number;
  type: string;
}

interface SiteOnMap {
  info: SiteInfo;
  circle: google.maps.Circle;
  nameBanner: InfoBox;
}

interface OrderInfo {
  id: number;
  driverName: string;
  truckName: string;
  trailerName?: string;
  lastUpdated?: number;
  lat: number;
  lng: number;
  currentTaskType: TaskTypeMap[keyof TaskTypeMap];
}

interface DriverInfo {
  id: string;
  lat: number;
  lng: number;
  name: string;
}

interface DriverOnMap {
  info: DriverInfo;
  marker: google.maps.Marker;
  nameBanner: InfoBox;
}

interface OrderOnMap {
  info: OrderInfo;
  marker: google.maps.Marker;
  nameBanner: InfoBox;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-orders-map',
  styleUrls: ['./orders-map.component.scss'],
  templateUrl: './orders-map.component.html',
})
export class OrdersMapComponent implements OnInit {
  @ViewChild('map', { static: true }) private mapElement: ElementRef;

  @Input() set orders(orders: (MappableOrder.AsObject | Order.AsObject)[]) {
    if (orders) {
      this._orders = idArrayToRecord(orders);
      this.updateMap();
    } else {
      this.clearMap();
    }
  }

  @Input() set driver(driver: Driver.AsObject) {
    this._driver = driver;
    this.handleDriverChange();
  }
  @Input() set sites(sites: VendorSiteSummary.AsObject[]) {
    if (sites) {
      this._sites = idArrayToRecord(sites);
      this.updateMap();
    } else {
      this.clearMap();
    }
  }

  @Input() set drivers(drivers: Driver.AsObject[]) {
    this._drivers = uidArrayToRecord(drivers);
    this.updateMap();
  }

  // all sites or sites corresponding to order(s) being viewed
  private sitesOnMap: Record<string, SiteOnMap> = {};
  private ordersOnMap: Record<string, OrderOnMap> = {};
  // all drivers or driver(s) corresponding to order(s) being viewed
  // tslint:disable-next-line
  private driversOnMap: Record<string, DriverOnMap> = {};
  private driverOnMap: DriverOnMap;
  private map: google.maps.Map;
  private _orders: Record<string, Order.AsObject | MappableOrder.AsObject>;
  private _sites: Record<string, VendorSiteSummary.AsObject>;
  private _drivers: Record<string, Driver.AsObject>;
  private _driver: Driver.AsObject;

  constructor() {}

  private initMap() {
    const mapConfig: google.maps.MapOptions = {
      center: { lat: 39.7392, lng: -104.9903 },
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: 3,
      },
      scaleControl: true,
      streetViewControl: false,
      zoom: 3,
      zoomControlOptions: {
        position: 3,
      },
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapConfig);
    this.updateMap();
  }

  public ngOnInit() {
    this.initMap();
  }

  private clearMap() {}

  private updateMap() {
    if (!this.map || !this._orders) {
      return;
    }
    this.handleSiteUpdates();
    this.handleOrderUpdates();
    this.updateMapBounds();
  }

  private handleSiteUpdates() {
    const sitesInvolvedInCurrentOrders = getSitesForOrders(this._orders);
    // Remove sites from the map that should no longer be there
    Object.entries(this.sitesOnMap).forEach((siteCurrentlyOnMap) => {
      if (!sitesInvolvedInCurrentOrders[siteCurrentlyOnMap[0]]) {
        siteCurrentlyOnMap[1].circle.setMap(null);
        siteCurrentlyOnMap[1].nameBanner.close();
        delete this.sitesOnMap[siteCurrentlyOnMap[0]];
      }
    });

    // Upsert sites on the map
    Object.entries(sitesInvolvedInCurrentOrders).forEach((siteNeedsToBeOnMap) => {
      // Insert new sites
      if (!this.sitesOnMap[siteNeedsToBeOnMap[0]]) {
        const info = protoSiteToSiteInfo(siteNeedsToBeOnMap[1]);
        const { circle, nameBanner } = getSiteMapComponents(info);
        circle.setMap(this.map);
        nameBanner.open(this.map);
        nameBanner.setPosition(
          new google.maps.LatLng(
            circle
              .getBounds()
              .getNorthEast()
              .lat(),
            circle.getCenter().lng(),
          ),
        );
        this.sitesOnMap[siteNeedsToBeOnMap[0]] = { info, circle, nameBanner };
        return;
      }

      // Update existing sites if needed
      const existingMapData = this.sitesOnMap[siteNeedsToBeOnMap[0]];
      const newInfo = protoSiteToSiteInfo(siteNeedsToBeOnMap[1]);
      // Something has changed, replace the marker and banner
      if (!shallowEquals(newInfo, existingMapData.info)) {
        const { circle, nameBanner } = getSiteMapComponents(newInfo);
        circle.setMap(this.map);
        nameBanner.open(this.map);
        existingMapData.circle.setMap(null);
        existingMapData.nameBanner.close();
        existingMapData.info = newInfo;
        existingMapData.circle = circle;
        existingMapData.nameBanner = nameBanner;
      }
    });
  }

  private handleOrderUpdates() {
    // Remove orders that should no longer be there
    Object.entries(this.ordersOnMap).forEach((orderCurrentlyOnMap) => {
      if (!this._orders[orderCurrentlyOnMap[0]]) {
        orderCurrentlyOnMap[1].marker.setMap(null);
        delete this.ordersOnMap[orderCurrentlyOnMap[0]];
      }
    });

    // Upsert orders on the map
    Object.entries(this._orders).forEach((orderNeedsToBeOnMap) => {
      // Insert new orders
      if (!this.ordersOnMap[orderNeedsToBeOnMap[0]]) {
        const info = protoOrderToOrderInfo(orderNeedsToBeOnMap[1]);
        // Don't know where this order is right now.
        if (!info.lat || !info.lng) {
          return;
        }
        const { marker, nameBanner } = getOrderMapComponents(info);
        marker.addListener('mouseover', () => {
          nameBanner.setPosition(marker.getPosition());
          nameBanner.open(this.map);
        });

        marker.addListener('mouseout', () => {
          nameBanner.close();
        });
        marker.setMap(this.map);
        this.ordersOnMap[orderNeedsToBeOnMap[0]] = { info, marker, nameBanner };
        return;
      }

      // Update existing orders if needed
      const existingMapData = this.ordersOnMap[orderNeedsToBeOnMap[0]];
      const newInfo = protoOrderToOrderInfo(orderNeedsToBeOnMap[1]);
      // Don't know where this order is right now.
      if (!newInfo.lat || !newInfo.lng) {
        return;
      }
      // Something has changed
      if (!shallowEquals(newInfo, existingMapData.info)) {
        const { marker, nameBanner } = getOrderMapComponents(newInfo);
        marker.addListener('mouseover', () => {
          nameBanner.setPosition(marker.getPosition());
          nameBanner.open(this.map);
        });
        // set correct order on map
        marker.setMap(this.map);

        marker.addListener('mouseout', () => {
          nameBanner.close();
        });
        // remove irrelevant order from map
        existingMapData.marker.setMap(null);
        existingMapData.info = newInfo;
        existingMapData.marker = marker;
      }
    });
  }

  private handleDriverChange() {
    const info = protoDriverToDriverInfo(this._driver);
    if (!info) {
      // Clean up if needed
      if (this.driverOnMap) {
        this.driverOnMap.marker.setMap(null);
        this.driverOnMap.nameBanner.close();
        this.driverOnMap = null;
        this.updateMapBounds();
      }
      return;
    }

    const { marker, nameBanner } = getDriverMapComponents(info);
    marker.setMap(this.map);
    nameBanner.open(this.map);
    nameBanner.setPosition(new google.maps.LatLng(marker.getPosition().lat(), marker.getPosition().lng()));
    if (this.driverOnMap) {
      this.driverOnMap.marker.setMap(null);
      this.driverOnMap.nameBanner.close();
      this.driverOnMap.marker = marker;
      this.driverOnMap.nameBanner = nameBanner;
      this.driverOnMap.info = info;
    } else {
      this.driverOnMap = {
        info,
        marker,
        nameBanner,
      };
    }
    this.updateMapBounds();
  }

  private updateMapBounds() {
    const bounds = new google.maps.LatLngBounds();
    if (this.sitesOnMap) {
      Object.entries(this.sitesOnMap).forEach(([_, siteOnMap]) => {
        bounds.union(siteOnMap.circle.getBounds());
      });
    }

    if (this.ordersOnMap) {
      Object.entries(this.ordersOnMap).forEach(([_, orderOnMap]) => {
        bounds.extend(orderOnMap.marker.getPosition());
      });
    }

    if (this.driverOnMap) {
      bounds.extend(this.driverOnMap.marker.getPosition());
    }

    if (bounds.isEmpty()) {
      const unitedStatesBounds = {
        east: -66.9513812,
        north: 49.3457868,
        south: 24.7433195,
        west: -124.7844079,
      };
      bounds.union(
        new google.maps.LatLngBounds(
          { lat: unitedStatesBounds.south, lng: unitedStatesBounds.west },
          { lat: unitedStatesBounds.north, lng: unitedStatesBounds.east },
        ),
      );
    }

    this.map.fitBounds(bounds);
  }
}

function getSiteMapComponents(siteInfo: SiteInfo): { circle: google.maps.Circle; nameBanner: InfoBox } {
  let siteColor = '';
  if (siteInfo.type === 'yard') {
    siteColor = '#00FF00';
  } else if (siteInfo.type === 'well') {
    siteColor = '#0000FF';
  }
  const circleOptions: google.maps.CircleOptions = {
    center: { lat: siteInfo.lat, lng: siteInfo.lng },
    fillColor: siteColor,
    fillOpacity: 0.25,
    // 1609 meters in a mile
    radius: siteInfo.radius,
    strokeColor: siteColor,
    strokeOpacity: 0.5,
    strokeWeight: 2,
  };
  const circle = new google.maps.Circle(circleOptions);
  const nameBanner = new InfoBox({
    alignBottom: true,
    boxStyle: {
      'background-color': 'rgba(62, 62, 82, 0.9)',
      'border-radius': '4px',
      color: 'white',
      'font-family': 'Averta-Regular, Roboto, serif',
      'font-size': '13px',
      width: '240px',
    },
    closeBoxURL: '',
    content: `
    <div style="display: flex; align-items: center; justify-items: center; padding: 4px">
      <div style="flex: 1; text-align: center;">${siteInfo.name}</div>
    </div>
    `,
    disableAutoPan: true,
    maxWidth: 240,
    pixelOffset: new google.maps.Size(-120, -5),
  });
  return { circle, nameBanner };
}

function getOrderMapComponents(orderInfo: OrderInfo): { marker: google.maps.Marker; nameBanner: InfoBox } {
  const orderColor = orderTaskColors[orderInfo.currentTaskType];
  const options: google.maps.MarkerOptions = {
    icon: {
      anchor: new google.maps.Point(30, 30),
      labelOrigin: new google.maps.Point(30, 50),
      scaledSize: new google.maps.Size(60, 60),
      url: getDataSvg(orderInfo.id, orderColor),
    },
    position: { lat: orderInfo.lat, lng: orderInfo.lng },
  };
  const nameBanner = new InfoBox({
    alignBottom: true,
    boxStyle: {
      'background-color': 'rgba(0,0,0,0.6)',
      'border-radius': '4px',
      color: 'white',
      'font-family': 'Averta-Regular, Roboto, serif',
      'font-size': '13px',
      width: '240px',
    },
    closeBoxURL: '',
    content: `
    <div style="display: flex; align-items: center; justify-items: center; padding: 4px">
      <div style="flex: 1; text-align: center;">${orderInfo.driverName}, ${orderInfo.truckName} ${
      orderInfo.trailerName ? ', ' + orderInfo.trailerName : ''
    } (${moment(orderInfo.lastUpdated * 1000).fromNow()})</div>
    </div>
    `,
    disableAutoPan: true,
    maxWidth: 240,
    pixelOffset: new google.maps.Size(-120, -15),
  });
  const marker = new google.maps.Marker(options);
  return { marker, nameBanner };
}

function getDriverMapComponents(driverInfo: DriverInfo): { marker: google.maps.Marker; nameBanner: InfoBox } {
  const options: google.maps.MarkerOptions = {
    position: { lat: driverInfo.lat, lng: driverInfo.lng },
  };
  const marker = new google.maps.Marker(options);
  const nameBanner = new InfoBox({
    alignBottom: true,
    boxStyle: {
      'background-color': 'rgba(14, 78, 173, 0.9)',
      'border-radius': '4px',
      color: 'white',
      'font-family': 'Averta-Regular, Roboto, serif',
      'font-size': '13px',
      width: '240px',
    },
    closeBoxURL: '',
    content: `
    <div style="display: flex; align-items: center; justify-items: center; padding: 4px">
      <div style="flex: 1; text-align: center;">${driverInfo.name}</div>
    </div>
    `,
    disableAutoPan: true,
    maxWidth: 240,
    pixelOffset: new google.maps.Size(-120, 25),
  });
  return { marker, nameBanner };
}

// TODO: Currently, only sites attached to orders (SiteShort vs SiteSummary) have geofence information for mapping.
// Once this has changed, will need to update the sites that are mapped - not just sites attached to orders
function getSitesForOrders(
  orders: Record<string, Order.AsObject | MappableOrder.AsObject>,
): Record<string, SiteShort.AsObject> {
  const sitesInvolvedInCurrentOrders: Record<string, SiteShort.AsObject> = {};
  Object.values(orders).forEach((order) => {
    if (isOrder(order)) {
      order.tasksList.forEach((task) => {
        if (task && task.site && task.site.id) {
          sitesInvolvedInCurrentOrders[task.site.id] = task.site;
        }
      });
    } else {
      order.taskSummariesList.forEach((task) => {
        if (task && task.site && task.site.id) {
          sitesInvolvedInCurrentOrders[task.site.id] = task.site;
        }
      });
    }
  });
  return sitesInvolvedInCurrentOrders;
}

function protoSiteToSiteInfo(site: SiteShort.AsObject): SiteInfo {
  return {
    id: site.id,
    lat: site.geofence && site.geofence.center ? site.geofence.center.lat : null,
    lng: site.geofence && site.geofence.center ? site.geofence.center.lon : null,
    name: site.name,
    radius: site.geofence && site.geofence.radius,
    type: site.siteType,
  };
}

function protoOrderToOrderInfo(order: Order.AsObject | MappableOrder.AsObject): OrderInfo {
  if (isOrder(order)) {
    let currentTaskType: TaskTypeMap[keyof TaskTypeMap];
    const inProgressTask = order.tasksList.find((task) => task.status === TaskStatus.TASK_STATUS_IN_PROGRESS);
    if (inProgressTask) {
      currentTaskType = inProgressTask.type;
    } else {
      currentTaskType = order.tasksList.length ? order.tasksList[0].type : TaskType.TASK_TYPE_INVALID;
    }
    return {
      currentTaskType,
      driverName: order.driver && order.driver.user.name,
      id: order.id,
      lastUpdated:
        order.driver && order.driver.lastWaypoint ? order.driver.lastWaypoint.clientCreatedUnix : order.updatedUnix,
      lat:
        order.driver && order.driver.lastWaypoint && order.driver.lastWaypoint.coordinates
          ? order.driver.lastWaypoint.coordinates.lat
          : null,
      lng:
        order.driver && order.driver.lastWaypoint && order.driver.lastWaypoint.coordinates
          ? order.driver.lastWaypoint.coordinates.lon
          : null,
      trailerName: order.trailer && order.trailer.name,
      truckName: order.truck && order.truck.name,
    };
  } else {
    let currentTaskType: TaskTypeMap[keyof TaskTypeMap];
    const inProgressTask = order.taskSummariesList.find((task) => task.status === TaskStatus.TASK_STATUS_IN_PROGRESS);
    if (inProgressTask) {
      currentTaskType = inProgressTask.type;
    } else {
      currentTaskType = order.taskSummariesList.length ? order.taskSummariesList[0].type : TaskType.TASK_TYPE_INVALID;
    }
    return {
      currentTaskType,
      driverName: order.driver && order.driver.user.name,
      id: order.id,
      lastUpdated:
        order.driver && order.driver.lastWaypoint ? order.driver.lastWaypoint.clientCreatedUnix : order.updatedUnix,
      lat:
        order.driver && order.driver.lastWaypoint && order.driver.lastWaypoint.coordinates
          ? order.driver.lastWaypoint.coordinates.lat
          : null,
      lng:
        order.driver && order.driver.lastWaypoint && order.driver.lastWaypoint.coordinates
          ? order.driver.lastWaypoint.coordinates.lon
          : null,
      trailerName: order.trailerName,
      truckName: order.truckName,
    };
  }
}

function protoDriverToDriverInfo(driver: Driver.AsObject): DriverInfo {
  if (!driver || !driver.lastWaypoint || driver.lastWaypoint.coordinates.lat === 0) {
    return null;
  }
  return {
    id: driver.user.firebaseUid,
    lat: driver.lastWaypoint.coordinates.lat,
    lng: driver.lastWaypoint.coordinates.lon,
    name: driver.user.name,
  };
}

function shallowEquals(a: Record<string, any>, b: Record<string, any>): boolean {
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  if (aKeys.length !== bKeys.length) {
    return false;
  }
  return aKeys.every((key) => a[key] === b[key]) && bKeys.every((key) => b[key] === a[key]);
}

function getDataSvg(innerText: string | number, color: string): string {
  const svg = `<svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">\
  <g filter="url(#filter0_d)">\
  <path d="M41 59.8889C51.432 59.8889 59.8889 51.432 59.8889 41C59.8889 30.5679 51.432 22.1111 41 22.1111C30.5679 22.1111 22.1111 30.5679 22.1111 41C22.1111 51.432 30.5679 59.8889 41 59.8889Z" fill="${color}" stroke="white" stroke-width="2"/>\
  <text font-family="sans-serif" font-size="14" text-anchor="middle" x="41" y="46" fill="white">${innerText}</text>
  </g>\
  <defs>\
  <filter id="filter0_d" x="0" y="0" width="82" height="82" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">\
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>\
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>\
  <feOffset/>\
  <feGaussianBlur stdDeviation="10"/>\
  <feColorMatrix type="matrix" values="0 0 0 0 0.266667 0 0 0 0 0.541176 0 0 0 0 1 0 0 0 0.5 0"/>\
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>\
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>\
  </filter>\
  </defs>\
  </svg>`;
  return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svg)}`;
}

function isOrder(order: Order.AsObject | MappableOrder.AsObject): order is Order.AsObject {
  return (order as Order.AsObject).description !== undefined;
}
