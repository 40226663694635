/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var proto_cybertron_types_types_pb = require('../../../proto/cybertron/types/types_pb.js');
var proto_cybertron_billingsettings_settings_pb = require('../../../proto/cybertron/billingsettings/settings_pb.js');
var proto_cybertron_user_user_pb = require('../../../proto/cybertron/user/user_pb.js');
var google_protobuf_field_mask_pb = require('google-protobuf/google/protobuf/field_mask_pb.js');
goog.exportSymbol('proto.cbv1.AddChargeTypeRequest', null, global);
goog.exportSymbol('proto.cbv1.AddChargeTypeResponse', null, global);
goog.exportSymbol('proto.cbv1.AddUserPermissionRequest', null, global);
goog.exportSymbol('proto.cbv1.AddUserPermissionResponse', null, global);
goog.exportSymbol('proto.cbv1.CreateLMOBillingSettingRequest', null, global);
goog.exportSymbol('proto.cbv1.CreateLMOBillingSettingResponse', null, global);
goog.exportSymbol('proto.cbv1.DeleteChargeTypeRequest', null, global);
goog.exportSymbol('proto.cbv1.DeleteChargeTypeResponse', null, global);
goog.exportSymbol('proto.cbv1.GetLMOBillingSettingRequest', null, global);
goog.exportSymbol('proto.cbv1.GetLMOBillingSettingResponse', null, global);
goog.exportSymbol('proto.cbv1.ListChargeTypeRequest', null, global);
goog.exportSymbol('proto.cbv1.ListChargeTypeResponse', null, global);
goog.exportSymbol('proto.cbv1.ListCurrentUserPermissionsRequest', null, global);
goog.exportSymbol('proto.cbv1.ListCurrentUserPermissionsResponse', null, global);
goog.exportSymbol('proto.cbv1.ListUsersFromAuthIdsRequest', null, global);
goog.exportSymbol('proto.cbv1.ListUsersFromAuthIdsResponse', null, global);
goog.exportSymbol('proto.cbv1.UpdateChargeTypeRequest', null, global);
goog.exportSymbol('proto.cbv1.UpdateChargeTypeResponse', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateLMOBillingSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateLMOBillingSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateLMOBillingSettingRequest.displayName = 'proto.cbv1.CreateLMOBillingSettingRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateLMOBillingSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateLMOBillingSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateLMOBillingSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateLMOBillingSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    setting: (f = msg.getSetting()) && proto_cybertron_billingsettings_settings_pb.LMOBillingSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateLMOBillingSettingRequest}
 */
proto.cbv1.CreateLMOBillingSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateLMOBillingSettingRequest;
  return proto.cbv1.CreateLMOBillingSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateLMOBillingSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateLMOBillingSettingRequest}
 */
proto.cbv1.CreateLMOBillingSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billingsettings_settings_pb.LMOBillingSetting;
      reader.readMessage(value,proto_cybertron_billingsettings_settings_pb.LMOBillingSetting.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateLMOBillingSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateLMOBillingSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateLMOBillingSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateLMOBillingSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_billingsettings_settings_pb.LMOBillingSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional LMOBillingSetting setting = 1;
 * @return {?proto.cbv1.LMOBillingSetting}
 */
proto.cbv1.CreateLMOBillingSettingRequest.prototype.getSetting = function() {
  return /** @type{?proto.cbv1.LMOBillingSetting} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billingsettings_settings_pb.LMOBillingSetting, 1));
};


/** @param {?proto.cbv1.LMOBillingSetting|undefined} value */
proto.cbv1.CreateLMOBillingSettingRequest.prototype.setSetting = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.CreateLMOBillingSettingRequest.prototype.clearSetting = function() {
  this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CreateLMOBillingSettingRequest.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.CreateLMOBillingSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.CreateLMOBillingSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.CreateLMOBillingSettingResponse.displayName = 'proto.cbv1.CreateLMOBillingSettingResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.CreateLMOBillingSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.CreateLMOBillingSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.CreateLMOBillingSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateLMOBillingSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    setting: (f = msg.getSetting()) && proto_cybertron_billingsettings_settings_pb.LMOBillingSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.CreateLMOBillingSettingResponse}
 */
proto.cbv1.CreateLMOBillingSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.CreateLMOBillingSettingResponse;
  return proto.cbv1.CreateLMOBillingSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.CreateLMOBillingSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.CreateLMOBillingSettingResponse}
 */
proto.cbv1.CreateLMOBillingSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billingsettings_settings_pb.LMOBillingSetting;
      reader.readMessage(value,proto_cybertron_billingsettings_settings_pb.LMOBillingSetting.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.CreateLMOBillingSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.CreateLMOBillingSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.CreateLMOBillingSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.CreateLMOBillingSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_billingsettings_settings_pb.LMOBillingSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional LMOBillingSetting setting = 1;
 * @return {?proto.cbv1.LMOBillingSetting}
 */
proto.cbv1.CreateLMOBillingSettingResponse.prototype.getSetting = function() {
  return /** @type{?proto.cbv1.LMOBillingSetting} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billingsettings_settings_pb.LMOBillingSetting, 1));
};


/** @param {?proto.cbv1.LMOBillingSetting|undefined} value */
proto.cbv1.CreateLMOBillingSettingResponse.prototype.setSetting = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.CreateLMOBillingSettingResponse.prototype.clearSetting = function() {
  this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.CreateLMOBillingSettingResponse.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetLMOBillingSettingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetLMOBillingSettingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetLMOBillingSettingRequest.displayName = 'proto.cbv1.GetLMOBillingSettingRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetLMOBillingSettingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetLMOBillingSettingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetLMOBillingSettingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetLMOBillingSettingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetLMOBillingSettingRequest}
 */
proto.cbv1.GetLMOBillingSettingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetLMOBillingSettingRequest;
  return proto.cbv1.GetLMOBillingSettingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetLMOBillingSettingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetLMOBillingSettingRequest}
 */
proto.cbv1.GetLMOBillingSettingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetLMOBillingSettingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetLMOBillingSettingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetLMOBillingSettingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetLMOBillingSettingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.GetLMOBillingSettingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.GetLMOBillingSettingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.GetLMOBillingSettingResponse.displayName = 'proto.cbv1.GetLMOBillingSettingResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.GetLMOBillingSettingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.GetLMOBillingSettingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.GetLMOBillingSettingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetLMOBillingSettingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    setting: (f = msg.getSetting()) && proto_cybertron_billingsettings_settings_pb.LMOBillingSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.GetLMOBillingSettingResponse}
 */
proto.cbv1.GetLMOBillingSettingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.GetLMOBillingSettingResponse;
  return proto.cbv1.GetLMOBillingSettingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.GetLMOBillingSettingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.GetLMOBillingSettingResponse}
 */
proto.cbv1.GetLMOBillingSettingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billingsettings_settings_pb.LMOBillingSetting;
      reader.readMessage(value,proto_cybertron_billingsettings_settings_pb.LMOBillingSetting.deserializeBinaryFromReader);
      msg.setSetting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.GetLMOBillingSettingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.GetLMOBillingSettingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.GetLMOBillingSettingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.GetLMOBillingSettingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSetting();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_billingsettings_settings_pb.LMOBillingSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional LMOBillingSetting setting = 1;
 * @return {?proto.cbv1.LMOBillingSetting}
 */
proto.cbv1.GetLMOBillingSettingResponse.prototype.getSetting = function() {
  return /** @type{?proto.cbv1.LMOBillingSetting} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billingsettings_settings_pb.LMOBillingSetting, 1));
};


/** @param {?proto.cbv1.LMOBillingSetting|undefined} value */
proto.cbv1.GetLMOBillingSettingResponse.prototype.setSetting = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.GetLMOBillingSettingResponse.prototype.clearSetting = function() {
  this.setSetting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.GetLMOBillingSettingResponse.prototype.hasSetting = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.AddUserPermissionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.AddUserPermissionRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.AddUserPermissionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.AddUserPermissionRequest.displayName = 'proto.cbv1.AddUserPermissionRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.AddUserPermissionRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.AddUserPermissionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.AddUserPermissionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.AddUserPermissionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AddUserPermissionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    permission: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userIdsList: jspb.Message.getRepeatedField(msg, 2)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.AddUserPermissionRequest}
 */
proto.cbv1.AddUserPermissionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.AddUserPermissionRequest;
  return proto.cbv1.AddUserPermissionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.AddUserPermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.AddUserPermissionRequest}
 */
proto.cbv1.AddUserPermissionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cbv1.UserPermissions} */ (reader.readEnum());
      msg.setPermission(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addUserIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.AddUserPermissionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.AddUserPermissionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.AddUserPermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AddUserPermissionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermission();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getUserIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional UserPermissions permission = 1;
 * @return {!proto.cbv1.UserPermissions}
 */
proto.cbv1.AddUserPermissionRequest.prototype.getPermission = function() {
  return /** @type {!proto.cbv1.UserPermissions} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.cbv1.UserPermissions} value */
proto.cbv1.AddUserPermissionRequest.prototype.setPermission = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated string user_ids = 2;
 * @return {!Array<string>}
 */
proto.cbv1.AddUserPermissionRequest.prototype.getUserIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/** @param {!Array<string>} value */
proto.cbv1.AddUserPermissionRequest.prototype.setUserIdsList = function(value) {
  jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.AddUserPermissionRequest.prototype.addUserIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


proto.cbv1.AddUserPermissionRequest.prototype.clearUserIdsList = function() {
  this.setUserIdsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.AddUserPermissionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.AddUserPermissionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.AddUserPermissionResponse.displayName = 'proto.cbv1.AddUserPermissionResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.AddUserPermissionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.AddUserPermissionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.AddUserPermissionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AddUserPermissionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    permission: (f = msg.getPermission()) && proto_cybertron_billingsettings_settings_pb.LMOUserPermission.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.AddUserPermissionResponse}
 */
proto.cbv1.AddUserPermissionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.AddUserPermissionResponse;
  return proto.cbv1.AddUserPermissionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.AddUserPermissionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.AddUserPermissionResponse}
 */
proto.cbv1.AddUserPermissionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billingsettings_settings_pb.LMOUserPermission;
      reader.readMessage(value,proto_cybertron_billingsettings_settings_pb.LMOUserPermission.deserializeBinaryFromReader);
      msg.setPermission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.AddUserPermissionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.AddUserPermissionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.AddUserPermissionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AddUserPermissionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermission();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_billingsettings_settings_pb.LMOUserPermission.serializeBinaryToWriter
    );
  }
};


/**
 * optional LMOUserPermission permission = 1;
 * @return {?proto.cbv1.LMOUserPermission}
 */
proto.cbv1.AddUserPermissionResponse.prototype.getPermission = function() {
  return /** @type{?proto.cbv1.LMOUserPermission} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billingsettings_settings_pb.LMOUserPermission, 1));
};


/** @param {?proto.cbv1.LMOUserPermission|undefined} value */
proto.cbv1.AddUserPermissionResponse.prototype.setPermission = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.AddUserPermissionResponse.prototype.clearPermission = function() {
  this.setPermission(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.AddUserPermissionResponse.prototype.hasPermission = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.AddChargeTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.AddChargeTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.AddChargeTypeRequest.displayName = 'proto.cbv1.AddChargeTypeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.AddChargeTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.AddChargeTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.AddChargeTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AddChargeTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    abbrev: jspb.Message.getFieldWithDefault(msg, 3, ""),
    ediCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.AddChargeTypeRequest}
 */
proto.cbv1.AddChargeTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.AddChargeTypeRequest;
  return proto.cbv1.AddChargeTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.AddChargeTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.AddChargeTypeRequest}
 */
proto.cbv1.AddChargeTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.cbv1.ChargeType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbbrev(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdiCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.AddChargeTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.AddChargeTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.AddChargeTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AddChargeTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAbbrev();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEdiCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional ChargeType type = 1;
 * @return {!proto.cbv1.ChargeType}
 */
proto.cbv1.AddChargeTypeRequest.prototype.getType = function() {
  return /** @type {!proto.cbv1.ChargeType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {!proto.cbv1.ChargeType} value */
proto.cbv1.AddChargeTypeRequest.prototype.setType = function(value) {
  jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.AddChargeTypeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.AddChargeTypeRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string abbrev = 3;
 * @return {string}
 */
proto.cbv1.AddChargeTypeRequest.prototype.getAbbrev = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.AddChargeTypeRequest.prototype.setAbbrev = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string edi_code = 4;
 * @return {string}
 */
proto.cbv1.AddChargeTypeRequest.prototype.getEdiCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.AddChargeTypeRequest.prototype.setEdiCode = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.AddChargeTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.AddChargeTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.AddChargeTypeResponse.displayName = 'proto.cbv1.AddChargeTypeResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.AddChargeTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.AddChargeTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.AddChargeTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AddChargeTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chargeType: (f = msg.getChargeType()) && proto_cybertron_billingsettings_settings_pb.LMOChargeType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.AddChargeTypeResponse}
 */
proto.cbv1.AddChargeTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.AddChargeTypeResponse;
  return proto.cbv1.AddChargeTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.AddChargeTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.AddChargeTypeResponse}
 */
proto.cbv1.AddChargeTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billingsettings_settings_pb.LMOChargeType;
      reader.readMessage(value,proto_cybertron_billingsettings_settings_pb.LMOChargeType.deserializeBinaryFromReader);
      msg.setChargeType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.AddChargeTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.AddChargeTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.AddChargeTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.AddChargeTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChargeType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_billingsettings_settings_pb.LMOChargeType.serializeBinaryToWriter
    );
  }
};


/**
 * optional LMOChargeType charge_type = 1;
 * @return {?proto.cbv1.LMOChargeType}
 */
proto.cbv1.AddChargeTypeResponse.prototype.getChargeType = function() {
  return /** @type{?proto.cbv1.LMOChargeType} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billingsettings_settings_pb.LMOChargeType, 1));
};


/** @param {?proto.cbv1.LMOChargeType|undefined} value */
proto.cbv1.AddChargeTypeResponse.prototype.setChargeType = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.AddChargeTypeResponse.prototype.clearChargeType = function() {
  this.setChargeType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.AddChargeTypeResponse.prototype.hasChargeType = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateChargeTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UpdateChargeTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateChargeTypeRequest.displayName = 'proto.cbv1.UpdateChargeTypeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateChargeTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateChargeTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateChargeTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateChargeTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chargeTypeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    abbrev: jspb.Message.getFieldWithDefault(msg, 3, ""),
    ediCode: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateChargeTypeRequest}
 */
proto.cbv1.UpdateChargeTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateChargeTypeRequest;
  return proto.cbv1.UpdateChargeTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateChargeTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateChargeTypeRequest}
 */
proto.cbv1.UpdateChargeTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChargeTypeId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAbbrev(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEdiCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateChargeTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateChargeTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateChargeTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateChargeTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChargeTypeId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAbbrev();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEdiCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int64 charge_type_id = 1;
 * @return {number}
 */
proto.cbv1.UpdateChargeTypeRequest.prototype.getChargeTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.UpdateChargeTypeRequest.prototype.setChargeTypeId = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.cbv1.UpdateChargeTypeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/** @param {string} value */
proto.cbv1.UpdateChargeTypeRequest.prototype.setName = function(value) {
  jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string abbrev = 3;
 * @return {string}
 */
proto.cbv1.UpdateChargeTypeRequest.prototype.getAbbrev = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/** @param {string} value */
proto.cbv1.UpdateChargeTypeRequest.prototype.setAbbrev = function(value) {
  jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string edi_code = 4;
 * @return {string}
 */
proto.cbv1.UpdateChargeTypeRequest.prototype.getEdiCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/** @param {string} value */
proto.cbv1.UpdateChargeTypeRequest.prototype.setEdiCode = function(value) {
  jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.UpdateChargeTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.UpdateChargeTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.UpdateChargeTypeResponse.displayName = 'proto.cbv1.UpdateChargeTypeResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.UpdateChargeTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.UpdateChargeTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.UpdateChargeTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateChargeTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chargeType: (f = msg.getChargeType()) && proto_cybertron_billingsettings_settings_pb.LMOChargeType.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.UpdateChargeTypeResponse}
 */
proto.cbv1.UpdateChargeTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.UpdateChargeTypeResponse;
  return proto.cbv1.UpdateChargeTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.UpdateChargeTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.UpdateChargeTypeResponse}
 */
proto.cbv1.UpdateChargeTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billingsettings_settings_pb.LMOChargeType;
      reader.readMessage(value,proto_cybertron_billingsettings_settings_pb.LMOChargeType.deserializeBinaryFromReader);
      msg.setChargeType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.UpdateChargeTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.UpdateChargeTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.UpdateChargeTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.UpdateChargeTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChargeType();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto_cybertron_billingsettings_settings_pb.LMOChargeType.serializeBinaryToWriter
    );
  }
};


/**
 * optional LMOChargeType charge_type = 1;
 * @return {?proto.cbv1.LMOChargeType}
 */
proto.cbv1.UpdateChargeTypeResponse.prototype.getChargeType = function() {
  return /** @type{?proto.cbv1.LMOChargeType} */ (
    jspb.Message.getWrapperField(this, proto_cybertron_billingsettings_settings_pb.LMOChargeType, 1));
};


/** @param {?proto.cbv1.LMOChargeType|undefined} value */
proto.cbv1.UpdateChargeTypeResponse.prototype.setChargeType = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.cbv1.UpdateChargeTypeResponse.prototype.clearChargeType = function() {
  this.setChargeType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.cbv1.UpdateChargeTypeResponse.prototype.hasChargeType = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListChargeTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListChargeTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListChargeTypeRequest.displayName = 'proto.cbv1.ListChargeTypeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListChargeTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListChargeTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListChargeTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListChargeTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListChargeTypeRequest}
 */
proto.cbv1.ListChargeTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListChargeTypeRequest;
  return proto.cbv1.ListChargeTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListChargeTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListChargeTypeRequest}
 */
proto.cbv1.ListChargeTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListChargeTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListChargeTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListChargeTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListChargeTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListChargeTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListChargeTypeResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListChargeTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListChargeTypeResponse.displayName = 'proto.cbv1.ListChargeTypeResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListChargeTypeResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListChargeTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListChargeTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListChargeTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListChargeTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chargeTypesList: jspb.Message.toObjectList(msg.getChargeTypesList(),
    proto_cybertron_billingsettings_settings_pb.LMOChargeType.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListChargeTypeResponse}
 */
proto.cbv1.ListChargeTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListChargeTypeResponse;
  return proto.cbv1.ListChargeTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListChargeTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListChargeTypeResponse}
 */
proto.cbv1.ListChargeTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billingsettings_settings_pb.LMOChargeType;
      reader.readMessage(value,proto_cybertron_billingsettings_settings_pb.LMOChargeType.deserializeBinaryFromReader);
      msg.addChargeTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListChargeTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListChargeTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListChargeTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListChargeTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChargeTypesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_billingsettings_settings_pb.LMOChargeType.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LMOChargeType charge_types = 1;
 * @return {!Array<!proto.cbv1.LMOChargeType>}
 */
proto.cbv1.ListChargeTypeResponse.prototype.getChargeTypesList = function() {
  return /** @type{!Array<!proto.cbv1.LMOChargeType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_billingsettings_settings_pb.LMOChargeType, 1));
};


/** @param {!Array<!proto.cbv1.LMOChargeType>} value */
proto.cbv1.ListChargeTypeResponse.prototype.setChargeTypesList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.LMOChargeType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.LMOChargeType}
 */
proto.cbv1.ListChargeTypeResponse.prototype.addChargeTypes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.LMOChargeType, opt_index);
};


proto.cbv1.ListChargeTypeResponse.prototype.clearChargeTypesList = function() {
  this.setChargeTypesList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DeleteChargeTypeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DeleteChargeTypeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DeleteChargeTypeRequest.displayName = 'proto.cbv1.DeleteChargeTypeRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DeleteChargeTypeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DeleteChargeTypeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DeleteChargeTypeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DeleteChargeTypeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chargetypeid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DeleteChargeTypeRequest}
 */
proto.cbv1.DeleteChargeTypeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DeleteChargeTypeRequest;
  return proto.cbv1.DeleteChargeTypeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DeleteChargeTypeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DeleteChargeTypeRequest}
 */
proto.cbv1.DeleteChargeTypeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChargetypeid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DeleteChargeTypeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DeleteChargeTypeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DeleteChargeTypeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DeleteChargeTypeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChargetypeid();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 ChargeTypeID = 1;
 * @return {number}
 */
proto.cbv1.DeleteChargeTypeRequest.prototype.getChargetypeid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/** @param {number} value */
proto.cbv1.DeleteChargeTypeRequest.prototype.setChargetypeid = function(value) {
  jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.DeleteChargeTypeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.DeleteChargeTypeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.DeleteChargeTypeResponse.displayName = 'proto.cbv1.DeleteChargeTypeResponse';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.DeleteChargeTypeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.DeleteChargeTypeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.DeleteChargeTypeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DeleteChargeTypeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.DeleteChargeTypeResponse}
 */
proto.cbv1.DeleteChargeTypeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.DeleteChargeTypeResponse;
  return proto.cbv1.DeleteChargeTypeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.DeleteChargeTypeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.DeleteChargeTypeResponse}
 */
proto.cbv1.DeleteChargeTypeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.DeleteChargeTypeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.DeleteChargeTypeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.DeleteChargeTypeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.DeleteChargeTypeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Status = 1;
 * @return {string}
 */
proto.cbv1.DeleteChargeTypeResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/** @param {string} value */
proto.cbv1.DeleteChargeTypeResponse.prototype.setStatus = function(value) {
  jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListUsersFromAuthIdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListUsersFromAuthIdsRequest.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListUsersFromAuthIdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListUsersFromAuthIdsRequest.displayName = 'proto.cbv1.ListUsersFromAuthIdsRequest';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListUsersFromAuthIdsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListUsersFromAuthIdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListUsersFromAuthIdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListUsersFromAuthIdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListUsersFromAuthIdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListUsersFromAuthIdsRequest}
 */
proto.cbv1.ListUsersFromAuthIdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListUsersFromAuthIdsRequest;
  return proto.cbv1.ListUsersFromAuthIdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListUsersFromAuthIdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListUsersFromAuthIdsRequest}
 */
proto.cbv1.ListUsersFromAuthIdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListUsersFromAuthIdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListUsersFromAuthIdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListUsersFromAuthIdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListUsersFromAuthIdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.cbv1.ListUsersFromAuthIdsRequest.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<string>} value */
proto.cbv1.ListUsersFromAuthIdsRequest.prototype.setIdsList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!string} value
 * @param {number=} opt_index
 */
proto.cbv1.ListUsersFromAuthIdsRequest.prototype.addIds = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.ListUsersFromAuthIdsRequest.prototype.clearIdsList = function() {
  this.setIdsList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListUsersFromAuthIdsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListUsersFromAuthIdsResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListUsersFromAuthIdsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListUsersFromAuthIdsResponse.displayName = 'proto.cbv1.ListUsersFromAuthIdsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListUsersFromAuthIdsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListUsersFromAuthIdsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListUsersFromAuthIdsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListUsersFromAuthIdsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListUsersFromAuthIdsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    proto_cybertron_billingsettings_settings_pb.UserDetails.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListUsersFromAuthIdsResponse}
 */
proto.cbv1.ListUsersFromAuthIdsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListUsersFromAuthIdsResponse;
  return proto.cbv1.ListUsersFromAuthIdsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListUsersFromAuthIdsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListUsersFromAuthIdsResponse}
 */
proto.cbv1.ListUsersFromAuthIdsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto_cybertron_billingsettings_settings_pb.UserDetails;
      reader.readMessage(value,proto_cybertron_billingsettings_settings_pb.UserDetails.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListUsersFromAuthIdsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListUsersFromAuthIdsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListUsersFromAuthIdsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListUsersFromAuthIdsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto_cybertron_billingsettings_settings_pb.UserDetails.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserDetails users = 1;
 * @return {!Array<!proto.cbv1.UserDetails>}
 */
proto.cbv1.ListUsersFromAuthIdsResponse.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.cbv1.UserDetails>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto_cybertron_billingsettings_settings_pb.UserDetails, 1));
};


/** @param {!Array<!proto.cbv1.UserDetails>} value */
proto.cbv1.ListUsersFromAuthIdsResponse.prototype.setUsersList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.cbv1.UserDetails=} opt_value
 * @param {number=} opt_index
 * @return {!proto.cbv1.UserDetails}
 */
proto.cbv1.ListUsersFromAuthIdsResponse.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.cbv1.UserDetails, opt_index);
};


proto.cbv1.ListUsersFromAuthIdsResponse.prototype.clearUsersList = function() {
  this.setUsersList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListCurrentUserPermissionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cbv1.ListCurrentUserPermissionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListCurrentUserPermissionsRequest.displayName = 'proto.cbv1.ListCurrentUserPermissionsRequest';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListCurrentUserPermissionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListCurrentUserPermissionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListCurrentUserPermissionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListCurrentUserPermissionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListCurrentUserPermissionsRequest}
 */
proto.cbv1.ListCurrentUserPermissionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListCurrentUserPermissionsRequest;
  return proto.cbv1.ListCurrentUserPermissionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListCurrentUserPermissionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListCurrentUserPermissionsRequest}
 */
proto.cbv1.ListCurrentUserPermissionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListCurrentUserPermissionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListCurrentUserPermissionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListCurrentUserPermissionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListCurrentUserPermissionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cbv1.ListCurrentUserPermissionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.cbv1.ListCurrentUserPermissionsResponse.repeatedFields_, null);
};
goog.inherits(proto.cbv1.ListCurrentUserPermissionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.cbv1.ListCurrentUserPermissionsResponse.displayName = 'proto.cbv1.ListCurrentUserPermissionsResponse';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.cbv1.ListCurrentUserPermissionsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cbv1.ListCurrentUserPermissionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.cbv1.ListCurrentUserPermissionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cbv1.ListCurrentUserPermissionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListCurrentUserPermissionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    permissionsList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cbv1.ListCurrentUserPermissionsResponse}
 */
proto.cbv1.ListCurrentUserPermissionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cbv1.ListCurrentUserPermissionsResponse;
  return proto.cbv1.ListCurrentUserPermissionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cbv1.ListCurrentUserPermissionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cbv1.ListCurrentUserPermissionsResponse}
 */
proto.cbv1.ListCurrentUserPermissionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<!proto.cbv1.UserPermissions>} */ (reader.readPackedEnum());
      msg.setPermissionsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cbv1.ListCurrentUserPermissionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cbv1.ListCurrentUserPermissionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cbv1.ListCurrentUserPermissionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cbv1.ListCurrentUserPermissionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPermissionsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated UserPermissions permissions = 1;
 * @return {!Array<!proto.cbv1.UserPermissions>}
 */
proto.cbv1.ListCurrentUserPermissionsResponse.prototype.getPermissionsList = function() {
  return /** @type {!Array<!proto.cbv1.UserPermissions>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<!proto.cbv1.UserPermissions>} value */
proto.cbv1.ListCurrentUserPermissionsResponse.prototype.setPermissionsList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.cbv1.UserPermissions} value
 * @param {number=} opt_index
 */
proto.cbv1.ListCurrentUserPermissionsResponse.prototype.addPermissions = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.cbv1.ListCurrentUserPermissionsResponse.prototype.clearPermissionsList = function() {
  this.setPermissionsList([]);
};


goog.object.extend(exports, proto.cbv1);
