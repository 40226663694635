import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share, take } from 'rxjs/operators';
import { ListAccountsRequest, ListAccountsResponse } from '~proto/account/account_api_pb';
import { AccountAPI } from '~proto/account/account_api_pb_service';
import { Account } from '~proto/account/account_pb';
import { ListAccountsForOrdersRequest, ListAccountsForOrdersResponse } from '~proto/billing/billing_api_pb';
import { BillingAPI } from '~proto/billing/billing_api_pb_service';
import { AccountType } from '~proto/types/types_pb';
import { AuthService } from '~services/auth.service';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { observableArrayFromRecordGetter$ } from '~utilities/observableGetter';
import { GrpcService } from '../../services/grpc.service';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  private allCurrentVendors$$: BehaviorSubject<Record<string, Account.AsObject>> = new BehaviorSubject({});
  private filteredVendors$$: BehaviorSubject<Record<number, boolean>> = new BehaviorSubject(null);

  private allCurrentLMOs$$: BehaviorSubject<Record<string, Account.AsObject>> = new BehaviorSubject({});
  private filteredLMOs$$: BehaviorSubject<Record<string, boolean>> = new BehaviorSubject(null);

  private allVendors$$: BehaviorSubject<Record<string, Account.AsObject>> = new BehaviorSubject({});

  public get allCurrentVendors$(): Observable<Account.AsObject[]> {
    this.loadAllCurrentVendors();
    return observableArrayFromRecordGetter$(this.allCurrentVendors$$);
  }

  public get filteredVendors$(): Observable<Record<number, boolean>> {
    return this.filteredVendors$$.asObservable().pipe(share());
  }

  public get allVendors$(): Observable<Account.AsObject[]> {
    this.loadAllVendors();
    return observableArrayFromRecordGetter$(this.allVendors$$);
  }

  public get allCurrentLMOs$(): Observable<Account.AsObject[]> {
    this.loadAllLMOs();
    return observableArrayFromRecordGetter$(this.allCurrentLMOs$$);
  }

  public get filteredLMOs$(): Observable<Record<number, boolean>> {
    return this.filteredLMOs$$.asObservable().pipe(share());
  }

  public setFilteredVendors(vendors: Record<number, boolean>) {
    this.filteredVendors$$.next(vendors);
  }

  public setFilteredLMOs(lmos: Record<number, boolean>) {
    this.filteredLMOs$$.next(lmos);
  }

  constructor(private grpc: GrpcService, private authService: AuthService) {}

  private loadAllCurrentVendors() {
    this.authService
      .isLMO$()
      .pipe(take(1))
      .subscribe((isLmo) => {
        if (isLmo) {
          const truckingVendorRequest = new ListAccountsForOrdersRequest();
          truckingVendorRequest.setAccountType(AccountType.ACCOUNT_TYPE_TRUCKING_VENDOR);
          this.grpc
            .invoke$(BillingAPI.ListAccountsForOrders, truckingVendorRequest)
            .subscribe((truckingVendors: ListAccountsForOrdersResponse) => {
              console.log('vendors are from API', truckingVendors);
              this.allCurrentVendors$$.next(idArrayToRecord(truckingVendors.toObject().accountsList));
            });
        }
      });
  }

  private loadAllVendors() {
    const truckingVendorRequest = new ListAccountsRequest();
    truckingVendorRequest.setAccountType(AccountType.ACCOUNT_TYPE_TRUCKING_VENDOR);
    this.grpc
      .invoke$(AccountAPI.ListAccounts, truckingVendorRequest)
      .subscribe((truckingVendors: ListAccountsResponse) => {
        this.allVendors$$.next(idArrayToRecord(truckingVendors.toObject().accountsList));
      });
  }

  private loadAllLMOs() {
    this.authService
      .isLMO$()
      .pipe(take(1))
      .subscribe((isLmo) => {
        if (!isLmo) {
          const lmoRequest = new ListAccountsForOrdersRequest();
          lmoRequest.setAccountType(AccountType.ACCOUNT_TYPE_LMO);
          this.grpc
            .invoke$(BillingAPI.ListAccountsForOrders, lmoRequest)
            .subscribe((truckingVendors: ListAccountsForOrdersResponse) => {
              this.allCurrentLMOs$$.next(idArrayToRecord(truckingVendors.toObject().accountsList));
            });
        }
      });
  }
}
