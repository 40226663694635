// package: cbv1
// file: proto/cybertron/files/export_api.proto

var proto_cybertron_files_export_api_pb = require("../../../proto/cybertron/files/export_api_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var FileAPI = (function () {
  function FileAPI() {}
  FileAPI.serviceName = "cbv1.FileAPI";
  return FileAPI;
}());

FileAPI.BulkExport = {
  methodName: "BulkExport",
  service: FileAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_files_export_api_pb.BulkExportRequest,
  responseType: proto_cybertron_files_export_api_pb.BulkExportResponse
};

FileAPI.BulkSiteExport = {
  methodName: "BulkSiteExport",
  service: FileAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_files_export_api_pb.BulkSiteExportRequest,
  responseType: proto_cybertron_files_export_api_pb.BulkExportResponse
};

exports.FileAPI = FileAPI;

function FileAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

FileAPIClient.prototype.bulkExport = function bulkExport(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(FileAPI.BulkExport, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

FileAPIClient.prototype.bulkSiteExport = function bulkSiteExport(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(FileAPI.BulkSiteExport, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.FileAPIClient = FileAPIClient;

