import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, share, switchMap, tap } from 'rxjs/operators';
import { BusinessLine } from '~proto/businessline/businessline_pb';
import {
  ArchiveCostCenterRequest,
  ArchiveCostCenterResponse,
  CreateCostCenterRequest,
  CreateCostCenterResponse,
  ListCostCenterRequest,
  ListCostCenterResponse,
  UpdateCostCenterRequest,
  UpdateCostCenterResponse,
} from '~proto/order/order_api_pb';
import { OrderAPI } from '~proto/order/order_api_pb_service';
import { CostCenter } from '~proto/order/order_pb';
import { AuthService } from '~services/auth.service';
import { GrpcService } from '~services/grpc.service';

@Injectable({
  providedIn: 'root',
})
export class CostCenterService {
  private costCenters$$: BehaviorSubject<CostCenter.AsObject[]> = new BehaviorSubject([]);
  private loading$$ = new BehaviorSubject<boolean>(false);

  public get costCenters$(): Observable<CostCenter.AsObject[]> {
    return this.costCenters$$.asObservable().pipe(share());
  }

  public get loading$(): Observable<boolean> {
    return this.loading$$.asObservable().pipe(share());
  }

  constructor(private grpc: GrpcService, private authService: AuthService) {
    this.loadCostCenters();
  }

  public loadCostCenters(businessLine?: BusinessLine.AsObject): any {
    this.authService
      .isLMO$()
      .pipe(
        tap(() => this.loading$$.next(true)),
        filter((isLMO) => isLMO),
        switchMap(() => {
          const request = new ListCostCenterRequest();
          request.setBusinessLineId(businessLine && businessLine.id);
          return this.grpc.invoke$(OrderAPI.ListCostCenter, request);
        }),
        tap(() => this.loading$$.next(false)),
      )
      .subscribe((costCenters: ListCostCenterResponse) => {
        this.costCenters$$.next(costCenters.toObject().costCentersList);
      });
  }

  public createCostCenter$(costCenterName: string, businessLines: Array<number>): Observable<CreateCostCenterResponse> {
    const request = new CreateCostCenterRequest();
    request.setName(costCenterName);
    request.setBusinessLineIdsList(businessLines);
    return (this.grpc.invoke$(OrderAPI.CreateCostCenter, request) as Observable<CreateCostCenterResponse>).pipe(
      tap(() => this.loadCostCenters()),
    );
  }

  public editCostCenter$(
    costCenterName: string,
    id: number,
    businessLines: Array<number>,
  ): Observable<UpdateCostCenterResponse> {
    const request = new UpdateCostCenterRequest();
    request.setName(costCenterName);
    request.setId(id);
    request.setBusinessLineIdsList(businessLines);
    return (this.grpc.invoke$(OrderAPI.UpdateCostCenter, request) as Observable<UpdateCostCenterResponse>).pipe(
      tap(() => this.loadCostCenters()),
    );
  }

  public archiveCostCenter$(costCenterId: number): Observable<ArchiveCostCenterResponse> {
    const request = new ArchiveCostCenterRequest();
    request.setId(costCenterId);
    return (this.grpc.invoke$(OrderAPI.ArchiveCostCenter, request) as Observable<ArchiveCostCenterResponse>).pipe(
      tap(() => this.loadCostCenters()),
    );
  }
}
