// package: cbv1
// file: proto/cybertron/site/site_api.proto

var proto_cybertron_site_site_api_pb = require("../../../proto/cybertron/site/site_api_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var SiteAPI = (function () {
  function SiteAPI() {}
  SiteAPI.serviceName = "cbv1.SiteAPI";
  return SiteAPI;
}());

SiteAPI.LMOListSites = {
  methodName: "LMOListSites",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.LMOListSitesRequest,
  responseType: proto_cybertron_site_site_api_pb.LMOListSitesResponse
};

SiteAPI.LMOSearchSites = {
  methodName: "LMOSearchSites",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.LMOSearchSitesRequest,
  responseType: proto_cybertron_site_site_api_pb.LMOSearchSitesResponse
};

SiteAPI.VendorListSites = {
  methodName: "VendorListSites",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.VendorListSitesRequest,
  responseType: proto_cybertron_site_site_api_pb.VendorListSitesResponse
};

SiteAPI.GetSite = {
  methodName: "GetSite",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.GetSiteRequest,
  responseType: proto_cybertron_site_site_api_pb.GetSiteResponse
};

SiteAPI.CreateSite = {
  methodName: "CreateSite",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.CreateSiteRequest,
  responseType: proto_cybertron_site_site_api_pb.CreateSiteResponse
};

SiteAPI.DeleteSite = {
  methodName: "DeleteSite",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.DeleteSiteRequest,
  responseType: proto_cybertron_site_site_api_pb.DeleteSiteResponse
};

SiteAPI.UpdateSite = {
  methodName: "UpdateSite",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.UpdateSiteRequest,
  responseType: proto_cybertron_site_site_api_pb.UpdateSiteResponse
};

SiteAPI.PutSite = {
  methodName: "PutSite",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.PutSiteRequest,
  responseType: proto_cybertron_site_site_api_pb.UpdateSiteResponse
};

SiteAPI.FavoriteSite = {
  methodName: "FavoriteSite",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.FavoriteSiteRequest,
  responseType: proto_cybertron_site_site_api_pb.FavoriteSiteResponse
};

SiteAPI.AddStock = {
  methodName: "AddStock",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.AddStockRequest,
  responseType: proto_cybertron_site_site_api_pb.AddStockResponse
};

SiteAPI.UpdateStock = {
  methodName: "UpdateStock",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.UpdateStockRequest,
  responseType: proto_cybertron_site_site_api_pb.UpdateStockResponse
};

SiteAPI.DeleteStock = {
  methodName: "DeleteStock",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.DeleteStockRequest,
  responseType: proto_cybertron_site_site_api_pb.DeleteStockResponse
};

SiteAPI.UpdateStocksQuantity = {
  methodName: "UpdateStocksQuantity",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.UpdateStocksQuantityRequest,
  responseType: proto_cybertron_site_site_api_pb.UpdateStocksQuantityResponse
};

SiteAPI.ReportSiteAvailability = {
  methodName: "ReportSiteAvailability",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.ReportSiteAvailabilityRequest,
  responseType: proto_cybertron_site_site_api_pb.ReportSiteAvailabilityResponse
};

SiteAPI.StreamAccountSiteEvents = {
  methodName: "StreamAccountSiteEvents",
  service: SiteAPI,
  requestStream: false,
  responseStream: true,
  requestType: proto_cybertron_site_site_api_pb.StreamAccountSiteEventsRequest,
  responseType: proto_cybertron_site_site_api_pb.StreamAccountSiteEventsResponse
};

SiteAPI.LMOSiteActivityFeed = {
  methodName: "LMOSiteActivityFeed",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.LMOSiteActivityFeedRequest,
  responseType: proto_cybertron_site_site_api_pb.LMOSiteActivityFeedResponse
};

SiteAPI.LMOSalesOrderNumberShortcuts = {
  methodName: "LMOSalesOrderNumberShortcuts",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.LMOSalesOrderNumberShortcutsRequest,
  responseType: proto_cybertron_site_site_api_pb.LMOSalesOrderNumberShortcutsResponse
};

SiteAPI.LMOPurchaseOrderShortcuts = {
  methodName: "LMOPurchaseOrderShortcuts",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.LMOPurchaseOrderShortcutsRequest,
  responseType: proto_cybertron_site_site_api_pb.LMOPurchaseOrderShortcutsResponse
};

SiteAPI.LMOSitesStatistics = {
  methodName: "LMOSitesStatistics",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.LMOSitesStatisticsRequest,
  responseType: proto_cybertron_site_site_api_pb.LMOSitesStatisticsResponse
};

SiteAPI.LMOSitesCalendar = {
  methodName: "LMOSitesCalendar",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.LMOSitesCalendarRequest,
  responseType: proto_cybertron_site_site_api_pb.LMOSitesCalendarResponse
};

SiteAPI.GetVendorSite = {
  methodName: "GetVendorSite",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.GetVendorSiteRequest,
  responseType: proto_cybertron_site_site_api_pb.GetVendorSiteResponse
};

SiteAPI.VendorListArchivedSites = {
  methodName: "VendorListArchivedSites",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.VendorListArchivedSitesRequest,
  responseType: proto_cybertron_site_site_api_pb.VendorListArchivedSitesResponse
};

SiteAPI.VendorSearchPossibleSitesForTask = {
  methodName: "VendorSearchPossibleSitesForTask",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.VendorSearchPossibleSitesForTaskRequest,
  responseType: proto_cybertron_site_site_api_pb.VendorSearchPossibleSitesForTaskResponse
};

SiteAPI.LMOListDistricts = {
  methodName: "LMOListDistricts",
  service: SiteAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_site_site_api_pb.LMOListDistrictsRequest,
  responseType: proto_cybertron_site_site_api_pb.LMOListDistrictsResponse
};

exports.SiteAPI = SiteAPI;

function SiteAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

SiteAPIClient.prototype.lMOListSites = function lMOListSites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.LMOListSites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.lMOSearchSites = function lMOSearchSites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.LMOSearchSites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.vendorListSites = function vendorListSites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.VendorListSites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.getSite = function getSite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.GetSite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.createSite = function createSite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.CreateSite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.deleteSite = function deleteSite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.DeleteSite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.updateSite = function updateSite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.UpdateSite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.putSite = function putSite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.PutSite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.favoriteSite = function favoriteSite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.FavoriteSite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.addStock = function addStock(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.AddStock, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.updateStock = function updateStock(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.UpdateStock, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.deleteStock = function deleteStock(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.DeleteStock, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.updateStocksQuantity = function updateStocksQuantity(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.UpdateStocksQuantity, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.reportSiteAvailability = function reportSiteAvailability(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.ReportSiteAvailability, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.streamAccountSiteEvents = function streamAccountSiteEvents(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(SiteAPI.StreamAccountSiteEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.lMOSiteActivityFeed = function lMOSiteActivityFeed(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.LMOSiteActivityFeed, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.lMOSalesOrderNumberShortcuts = function lMOSalesOrderNumberShortcuts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.LMOSalesOrderNumberShortcuts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.lMOPurchaseOrderShortcuts = function lMOPurchaseOrderShortcuts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.LMOPurchaseOrderShortcuts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.lMOSitesStatistics = function lMOSitesStatistics(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.LMOSitesStatistics, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.lMOSitesCalendar = function lMOSitesCalendar(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.LMOSitesCalendar, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.getVendorSite = function getVendorSite(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.GetVendorSite, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.vendorListArchivedSites = function vendorListArchivedSites(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.VendorListArchivedSites, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.vendorSearchPossibleSitesForTask = function vendorSearchPossibleSitesForTask(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.VendorSearchPossibleSitesForTask, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

SiteAPIClient.prototype.lMOListDistricts = function lMOListDistricts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(SiteAPI.LMOListDistricts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.SiteAPIClient = SiteAPIClient;

