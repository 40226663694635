import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, interval, merge, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, finalize, map, share, switchMap, takeUntil } from 'rxjs/operators';
import { LoadHeatMapRequest, LoadHeatMapResponse } from '~proto/order/order_api_pb';
import { OrderAPI } from '~proto/order/order_api_pb_service';
import { GrpcService } from '~services/grpc.service';
import { RouterStateService } from '~services/router-state.service';
import { Waypoint } from '../../proto/user/user/user_pb';

@Injectable({
  providedIn: 'root',
})
export class DriverReplayService {
  private heatmapActive = false;
  private waypoints$$: BehaviorSubject<Waypoint.AsObject[]> = new BehaviorSubject([]);
  private selectedWaypoint$$: BehaviorSubject<Waypoint> = new BehaviorSubject(null);
  private selectedIndex$$: BehaviorSubject<number> = new BehaviorSubject(0);
  private loading$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private playing$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private speed$$: BehaviorSubject<number> = new BehaviorSubject(1);
  private player$: Observable<number> = merge(this.playing$, this.speed$).pipe(
    switchMap(() => {
      if (this.playing$$.value) {
        return interval(1000 / this.speed$$.value).pipe(takeUntil(this.playing$.pipe(filter((playing) => !playing))));
      }
      return of(null);
    }),
  );

  public get waypoints$(): Observable<Waypoint.AsObject[]> {
    return this.waypoints$$.asObservable().pipe(share());
  }

  public get selectedIndex$(): Observable<number> {
    return this.selectedIndex$$.asObservable().pipe(share());
  }

  public get selectedWaypoint$(): Observable<Waypoint.AsObject> {
    return combineLatest([this.waypoints$, this.selectedIndex$])
      .pipe(
        map(([waypoints, index]) => {
          if (waypoints.length) {
            return waypoints[index];
          }
          return null;
        }),
      )
      .pipe(share());
  }

  public get loading$(): Observable<boolean> {
    return this.loading$$.asObservable().pipe(share());
  }

  public get playing$(): Observable<boolean> {
    return this.playing$$.asObservable().pipe(
      distinctUntilChanged(),
      share(),
    );
  }

  public get speed$(): Observable<number> {
    return this.speed$$.asObservable().pipe(share());
  }

  constructor(private routerState: RouterStateService, private grpc: GrpcService) {
    this.routerState.routerState$.subscribe((state) => {
      if (this.heatmapActive && (state.params.tab !== 'completed' || !state.params.load)) {
        this.clear();
      }
    });

    this.player$.subscribe(() => {
      const waypoints = this.waypoints$$.value;
      const currentIndex = this.selectedIndex$$.value;
      if (this.playing$$.value && currentIndex + 1 < waypoints.length) {
        this.selectedIndex$$.next(currentIndex + 1);
      } else {
        this.playing$$.next(false);
      }
    });
  }

  private clear() {
    this.waypoints$$.next([]);
    this.selectedWaypoint$$.next(null);
    this.heatmapActive = false;
  }

  public loadOrderMapInfo(orderId: number): void {
    this.loading$$.next(true);
    this.clear();

    const request = new LoadHeatMapRequest();
    request.setLoadId(orderId);
    this.grpc
      .invoke$(OrderAPI.LoadHeatMap, request)
      .pipe(
        map((response: LoadHeatMapResponse) => response.toObject().waypointsList),
        finalize(() => this.loading$$.next(false)),
      )
      .subscribe((waypoints) => {
        const tmp = [];
        waypoints.forEach((waypoint) => {
          tmp.push(waypoint);
        });
        this.waypoints$$.next(tmp);
        this.setActiveWaypoint(0);
        this.heatmapActive = true;
        this.loading$$.next(false);
      });
  }

  public setActiveWaypoint(index: number) {
    const length = this.waypoints$$.value.length;
    if (index >= 0 && index < length) {
      this.selectedIndex$$.next(index);
    }
  }

  public setSpeed(speed: number) {
    if (speed > 0) {
      this.speed$$.next(speed);
    }
  }

  public goToEnd() {
    const waypoints = this.waypoints$$.value;
    if (waypoints.length > 0) {
      this.selectedIndex$$.next(waypoints.length - 1);
    }
  }

  public goToBeginning() {
    this.selectedIndex$$.next(0);
  }

  public play() {
    this.playing$$.next(true);
  }

  public pause() {
    this.playing$$.next(false);
  }
}
