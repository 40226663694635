import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment-precise-range-plugin';

@Pipe({
  name: 'minutesToHuman',
})
export class MinutesToHumanPipe implements PipeTransform {
  public transform(minutes: number): string {
    return minutesToHuman(minutes);
  }
}

interface PreciseDiff {
  days: number;
  firstDateWasLater: boolean;
  hours: number;
  minutes: number;
  months: number;
  seconds: number;
  years: number;
}

const dateOrder: (keyof PreciseDiff)[] = ['years', 'months', 'days', 'hours', 'minutes'];

const substitutes: Partial<Record<keyof PreciseDiff, string>> = { months: 'mo' };

function minutesToHuman(minutes: number): string {
  if (typeof minutes === 'string') {
    return minutes;
  }
  const asMoment = moment().add(minutes, 'minutes');
  if (asMoment.isValid) {
    const preciseDiff: PreciseDiff = (moment as any).preciseDiff(moment(), asMoment, true);
    const result = [];
    let dateOrderIndex = 0;
    while (dateOrderIndex < dateOrder.length && result.length < 2) {
      const key = dateOrder[dateOrderIndex];
      if (preciseDiff[key] > 0) {
        const keyName = substitutes[key] ? substitutes[key] : key.substring(0, 1).toLocaleLowerCase();
        result.push(`${preciseDiff[key]}${keyName}`);
      }
      dateOrderIndex++;
    }
    if (result.length) {
      return `${result.join(' ')}`;
    }
    return '0m';
  }
  return '-';
}
