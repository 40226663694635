import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatus } from '~proto/types/types_pb';

@Pipe({
  name: 'orderStatus',
})
export class OrderStatusPipe implements PipeTransform {
  public transform(value: number): any {
    switch (value) {
      case OrderStatus.ORDER_STATUS_CANCELLED:
        return 'Cancelled';
      case OrderStatus.ORDER_STATUS_COMPLETED:
        return 'Completed';
      case OrderStatus.ORDER_STATUS_CONFIRMATION_NEEDED:
        return 'Confirmation Needed';
      case OrderStatus.ORDER_STATUS_DECLINED:
        return 'Declined';
      case OrderStatus.ORDER_STATUS_DISPATCHED:
        return 'Dispatched';
      case OrderStatus.ORDER_STATUS_DRIVER_ACCEPTED:
        return 'Driver Accepted';
      case OrderStatus.ORDER_STATUS_DRIVER_REJECTED:
        return 'Driver Rejected';
      case OrderStatus.ORDER_STATUS_INVALID:
        return 'Invalid';
      case OrderStatus.ORDER_STATUS_PENDING:
        return 'Pending';
      default:
        return '';
    }
  }
}
