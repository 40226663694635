import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
  transition('* => *', [
    query(':enter', [style({ opacity: 0 })], { optional: true }),
    group([
      query(':leave', [style({ opacity: 1 }), animate('0.125s', style({ opacity: 0 }))], { optional: true }),
      query(':enter', [style({ opacity: 0 }), animate('0.125s', style({ opacity: 1 }))], { optional: true }),
    ]),
  ]),
]);
