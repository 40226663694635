import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyInPennies',
})
export class CurrencyInPenniesPipe extends CurrencyPipe implements PipeTransform {
  public transform(value: any, ...args: any[]): any {
    if (!isNaN(value)) {
      return super.transform(value / 100, ...args);
    }
    return super.transform(value, ...args);
  }
}
