import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import {
  awardRfqRequest,
  CalculateDistanceRequest,
  CanBidRequest,
  cancelRfqRequest,
  CanMarkNotInterestedRequest,
  CanUpdateBidderInformationRequest,
  CreateRFQBidRequest,
  CreateRFQRequest,
  CreateRFQResponse,
  listRfqRequest,
  listRfqResponse,
  LoadRFQByIdRequest,
  LoadRFQByIdResponse,
  markNotInterestedRequest,
  rfqSuggestionsRequest,
  rfqSuggestionsResponse,
  SearchBidderRequest,
  updateBidderInformationRequest,
} from '~proto/marketplace/rfq_api_pb';
import { MarketplaceBidderAPI, MarketplaceLMOAPI } from '~proto/marketplace/rfq_api_pb_service';
import { MarketplaceRfq } from '~proto/marketplace/rfq_pb';
import { MarketplaceRfqStatus } from '~proto/types/types_pb';
import { GrpcService } from '~services/grpc.service';

@Injectable({
  providedIn: 'root',
})
export class MarketplaceService {
  private allRFQ$$: BehaviorSubject<MarketplaceRfq.AsObject[]> = new BehaviorSubject(null);
  private rfqSuggestions$$: BehaviorSubject<rfqSuggestionsResponse.AsObject> = new BehaviorSubject(null);
  private selectedRFQ$$: BehaviorSubject<MarketplaceRfq.AsObject> = new BehaviorSubject(null);

  public get allRFQ$(): Observable<MarketplaceRfq.AsObject[]> {
    return this.allRFQ$$.asObservable();
  }
  public get selectedRFQ$(): Observable<MarketplaceRfq.AsObject> {
    return this.selectedRFQ$$.asObservable();
  }

  public get rfqSuggestions$(): Observable<rfqSuggestionsResponse.AsObject> {
    return this.rfqSuggestions$$.asObservable();
  }

  constructor(private grpc: GrpcService, private snackbar: MatSnackBar, private router: Router) {
    this.subscribeToRoutes();
  }

  private subscribeToRoutes() {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const rfqList = new listRfqRequest();
      const urls = this.router.url.split('/');
      switch (urls[urls.length - 1]) {
        case 'dashboard':
          rfqList.addStatuses(MarketplaceRfqStatus.RFQ_STATUS_BIDDING_OPEN);
          rfqList.addStatuses(MarketplaceRfqStatus.RFQ_STATUS_BIDDING_CLOSED);
          rfqList.addStatuses(MarketplaceRfqStatus.RFQ_STATUS_SCHEDULED);
          rfqList.addStatuses(MarketplaceRfqStatus.RFQ_STATUS_AWARDED);
          break;
        case 'awarded':
          rfqList.addStatuses(MarketplaceRfqStatus.RFQ_STATUS_AWARDED);
          break;
        case 'cancelled':
          rfqList.addStatuses(MarketplaceRfqStatus.RFQ_STATUS_CANCELLED);
          break;
      }
      if (rfqList.getStatusesList().length > 0) {
        this.loadRFQs(rfqList);
      }
    });
  }

  public clearSelectedRfq() {
    this.selectedRFQ$$.next(null);
    this.allRFQ$$.next(null);
  }

  public loadRfqById(id: number) {
    const loadRfqRequest = new LoadRFQByIdRequest();
    loadRfqRequest.setRfqId(id);
    this.grpc.invoke$(MarketplaceLMOAPI.loadRFQById, loadRfqRequest).subscribe((rfq: LoadRFQByIdResponse) => {
      this.selectedRFQ$$.next(rfq.toObject().rfq);
    });
  }

  public loadSuggestions() {
    return this.grpc
      .invoke$(MarketplaceLMOAPI.rfqSuggestions, new rfqSuggestionsRequest())
      .subscribe((rfqSuggestions: rfqSuggestionsResponse) => {
        this.rfqSuggestions$$.next(rfqSuggestions.toObject());
      });
  }

  public cancelRfq(id: number) {
    const cancelRfqReq = new cancelRfqRequest();
    cancelRfqReq.setRfqId(id);
    return this.grpc.invoke$(MarketplaceLMOAPI.cancelRfq, cancelRfqReq);
  }

  public awardRfq(bidId: number, rfqId: number) {
    const awardRfqReq = new awardRfqRequest();
    awardRfqReq.setBidId(bidId);
    awardRfqReq.setRfqId(rfqId);
    return this.grpc.invoke$(MarketplaceLMOAPI.awardRfq, awardRfqReq);
  }

  private loadRFQs(rfqList: listRfqRequest) {
    this.grpc.invoke$(MarketplaceLMOAPI.listRfq, rfqList).subscribe((rfqListResponse: listRfqResponse) => {
      this.mergeRfqs(rfqListResponse.toObject().rfqsList);
    });
  }

  public searchVendor(email: SearchBidderRequest) {
    return this.grpc.invoke$(MarketplaceLMOAPI.SearchBidder, email);
  }

  public createRFQ(rfqRequest: CreateRFQRequest) {
    this.grpc.invoke$(MarketplaceLMOAPI.CreateRFQ, rfqRequest).subscribe((rfqResponse: CreateRFQResponse) => {
      this.snackbar.open('RFQ Created Successfully', null, {
        duration: 2000,
      });
      this.router.navigate(['', 'lmo', 'marketplace', 'dashboard']);
    });
  }

  public canBid(canBidRequest: CanBidRequest) {
    return this.grpc.invokeWithoutLogin$(MarketplaceBidderAPI.CanBid, canBidRequest);
  }

  public canMarkNotinterested(canMarkNotinterestedRequest: CanMarkNotInterestedRequest) {
    return this.grpc.invokeWithoutLogin$(MarketplaceBidderAPI.CanMarkNotInterested, canMarkNotinterestedRequest);
  }

  public canUpdateProfile(canUpdateProfileRequest: CanUpdateBidderInformationRequest) {
    return this.grpc.invokeWithoutLogin$(MarketplaceBidderAPI.CanUpdateBidderInformation, canUpdateProfileRequest);
  }

  public createBid(createBidRequest: CreateRFQBidRequest) {
    return this.grpc.invokeWithoutLogin$(MarketplaceBidderAPI.CreateBid, createBidRequest);
  }

  public markNotInterested(markNotInterested: markNotInterestedRequest) {
    return this.grpc.invokeWithoutLogin$(MarketplaceBidderAPI.markNotInterested, markNotInterested);
  }

  public getDistance(distanceRequest: CalculateDistanceRequest) {
    return this.grpc.invokeWithoutLogin$(MarketplaceBidderAPI.distance, distanceRequest);
  }

  public updateBidderInformation(updateInformationRequest: updateBidderInformationRequest) {
    return this.grpc.invokeWithoutLogin$(MarketplaceBidderAPI.updateBidderInformation, updateInformationRequest);
  }

  public mergeRfqs(updatedRfqs: MarketplaceRfq.AsObject[]) {
    this.allRFQ$$.pipe(take(1)).subscribe((rfqs) => {
      if (updatedRfqs.length) {
        updatedRfqs.forEach((updatedRfq: MarketplaceRfq.AsObject) => {
          const index = rfqs && rfqs.findIndex((rfq) => rfq.id === updatedRfq.id);
          if (index != null && index > -1) {
            rfqs.splice(index, 1);
          }
          rfqs ? rfqs.push(updatedRfq) : (rfqs = [updatedRfq]);
        });
        this.allRFQ$$.next(rfqs);
      } else {
        this.allRFQ$$.next([]);
      }
    });
  }

  public updateRfq(updatedRfq: MarketplaceRfq.AsObject) {
    this.allRFQ$$.pipe(take(1)).subscribe((rfqs) => {
      // Updated All RFQ Details
      const index = rfqs && rfqs.findIndex((rfq) => rfq.id === updatedRfq.id);
      if (index != null && index > -1) {
        rfqs.splice(index, 1);
      }
      rfqs ? rfqs.push(updatedRfq) : (rfqs = [updatedRfq]);
      this.allRFQ$$.next(rfqs);
    });
    this.selectedRFQ$$.pipe(take(1)).subscribe((selectedRfq: MarketplaceRfq.AsObject) => {
      // Updated Single RFQ Details
      if (selectedRfq && selectedRfq.id === updatedRfq.id) {
        this.selectedRFQ$$.next(updatedRfq);
      }
    });
  }
}
