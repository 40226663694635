import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VisibilityService {
  private calendarhidden$$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public get calendarhidden$(): Observable<boolean> {
    return this.calendarhidden$$.asObservable();
  }

  constructor() {}

  public toggleCalendarVisibility() {
    const newState = !this.calendarhidden$$.value;
    this.calendarhidden$$.next(newState);
  }
}
