// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const apiKey = 'HAULI_API_URL';

const defaultApiUrl = 'https://dev.prime.shaleapps.com';

export const environment = {
  get api(): string {
    return getSessionThenLocalWithDefault(apiKey, defaultApiUrl);
  },
  set api(url: string) {
    sessionStorage.setItem(apiKey, url);
  },
  environment: 'dev',
  firebaseConfig: {
    apiKey: 'AIzaSyD7X5KQtp-W5y1OaF48mz9l2ckn281Cj1w',
    authDomain: 'agnus-2fff8.firebaseapp.com',
    databaseURL: 'https://agnus-2fff8.firebaseio.com',
    messagingSenderId: '889599006301',
    projectId: 'agnus-2fff8',
    storageBucket: 'agnus-2fff8.appspot.com',
  },
  production: false,
};

function getSessionThenLocalWithDefault(key: string, defaultValue: string): string {
  const sessionValue = sessionStorage.getItem(key);
  if (sessionValue) {
    return sessionValue;
  }

  const localValue = localStorage.getItem(key);
  if (localValue) {
    return localValue;
  }

  return defaultValue;
}
