// package: cbv1
// file: proto/cybertron/businessline/businessline_api.proto

var proto_cybertron_businessline_businessline_api_pb = require("../../../proto/cybertron/businessline/businessline_api_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var BusinessLineAPI = (function () {
  function BusinessLineAPI() {}
  BusinessLineAPI.serviceName = "cbv1.BusinessLineAPI";
  return BusinessLineAPI;
}());

BusinessLineAPI.CreateNewBusinessLine = {
  methodName: "CreateNewBusinessLine",
  service: BusinessLineAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_businessline_businessline_api_pb.CreateNewBusinessLineRequest,
  responseType: proto_cybertron_businessline_businessline_api_pb.CreateNewBusinessLineResponse
};

BusinessLineAPI.UpdateNewBusinessLine = {
  methodName: "UpdateNewBusinessLine",
  service: BusinessLineAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_businessline_businessline_api_pb.UpdateNewBusinessLineRequest,
  responseType: proto_cybertron_businessline_businessline_api_pb.UpdateNewBusinessLineResponse
};

BusinessLineAPI.ListBusinessLines = {
  methodName: "ListBusinessLines",
  service: BusinessLineAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_businessline_businessline_api_pb.ListBusinessLinesRequest,
  responseType: proto_cybertron_businessline_businessline_api_pb.ListBusinessLinesResponse
};

BusinessLineAPI.ArchiveBusinessLine = {
  methodName: "ArchiveBusinessLine",
  service: BusinessLineAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_businessline_businessline_api_pb.ArchiveBusinessLineRequest,
  responseType: proto_cybertron_businessline_businessline_api_pb.ArchiveBusinessLineResponse
};

BusinessLineAPI.UsersAttachedToBusinessLine = {
  methodName: "UsersAttachedToBusinessLine",
  service: BusinessLineAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_businessline_businessline_api_pb.UsersAttachedToBusinessLineRequest,
  responseType: proto_cybertron_businessline_businessline_api_pb.UsersAttachedToBusinessLineResponse
};

BusinessLineAPI.BusinessLinesAttachedToUser = {
  methodName: "BusinessLinesAttachedToUser",
  service: BusinessLineAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_businessline_businessline_api_pb.BusinessLinesAttachedToUserRequest,
  responseType: proto_cybertron_businessline_businessline_api_pb.BusinessLinesAttachedToUserResponse
};

exports.BusinessLineAPI = BusinessLineAPI;

function BusinessLineAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

BusinessLineAPIClient.prototype.createNewBusinessLine = function createNewBusinessLine(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BusinessLineAPI.CreateNewBusinessLine, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BusinessLineAPIClient.prototype.updateNewBusinessLine = function updateNewBusinessLine(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BusinessLineAPI.UpdateNewBusinessLine, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BusinessLineAPIClient.prototype.listBusinessLines = function listBusinessLines(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BusinessLineAPI.ListBusinessLines, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BusinessLineAPIClient.prototype.archiveBusinessLine = function archiveBusinessLine(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BusinessLineAPI.ArchiveBusinessLine, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BusinessLineAPIClient.prototype.usersAttachedToBusinessLine = function usersAttachedToBusinessLine(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BusinessLineAPI.UsersAttachedToBusinessLine, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BusinessLineAPIClient.prototype.businessLinesAttachedToUser = function businessLinesAttachedToUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BusinessLineAPI.BusinessLinesAttachedToUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.BusinessLineAPIClient = BusinessLineAPIClient;

