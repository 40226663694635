import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { FieldMask } from 'google-protobuf/google/protobuf/field_mask_pb';
import { BehaviorSubject, combineLatest, interval, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, switchMapTo, take, tap, throttleTime } from 'rxjs/operators';
import { BulkExportRequest, BulkExportResponse, BulkSiteExportRequest } from '~proto/files/export_api_pb';
import { FileAPI } from '~proto/files/export_api_pb_service';
import { GetSiteOrderETAsRequest, GetSiteOrderETAsResponse } from '~proto/order/order_api_pb';
import { OrderAPI } from '~proto/order/order_api_pb_service';
import { SiteOrderETAs } from '~proto/order/order_pb';
import {
  AddStockRequest,
  AddStockResponse,
  CreateSiteRequest,
  CreateSiteResponse,
  DeleteStockRequest,
  FavoriteSiteRequest,
  FavoriteSiteResponse,
  GetSiteRequest,
  GetSiteResponse,
  LMOListSitesRequest,
  LMOListSitesResponse,
  LMOSearchSitesRequest,
  LMOSearchSitesResponse,
  LMOSitesCalendarRequest,
  LMOSitesCalendarResponse,
  ReportSiteAvailabilityRequest,
  ReportSiteAvailabilityResponse,
  UpdateSiteRequest,
  UpdateSiteResponse,
  UpdateStockRequest,
  UpdateStocksQuantityRequest,
  UpdateStocksQuantityResponse,
} from '~proto/site/site_api_pb';
import { SiteAPI } from '~proto/site/site_api_pb_service';
import { LMOCalendarStats, LMOSiteSummary, Site, SiteShort, Stock } from '~proto/site/site_pb';
import { AccountType } from '~proto/types/types_pb';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { observableArrayFromArrayGetter$, observableArrayFromRecordGetter$ } from '~utilities/observableGetter';
import { sortByName } from '~utilities/sortByName';
import * as fromRouterConstants from '../../app-routing.constants';
import { AuthService } from '../../services/auth.service';
import { FeatureFlagService } from '../../services/feature-flag.service';
import { GrpcService } from '../../services/grpc.service';
import { RouterStateService } from '../../services/router-state.service';

const pollingTime = 1 * 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class JobSitesService {
  private refreshSites$ = new Subject<null>();
  private sites$$: BehaviorSubject<Record<string, LMOSiteSummary.AsObject>> = new BehaviorSubject({});
  private searching$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private currentSite$$: BehaviorSubject<Site.AsObject> = new BehaviorSubject(null);
  private pendingCount$$: BehaviorSubject<number> = new BehaviorSubject(null);
  private inProgressCount$$: BehaviorSubject<number> = new BehaviorSubject(null);
  private needsAttnCount$$: BehaviorSubject<number> = new BehaviorSubject(null);
  private siteOrderEtas$$: BehaviorSubject<SiteOrderETAs.AsObject> = new BehaviorSubject(null);
  private siteCalendarStats$$: BehaviorSubject<LMOCalendarStats.AsObject[]> = new BehaviorSubject([]);

  public get sites$(): Observable<LMOSiteSummary.AsObject[]> {
    this.refreshSites$.next(null);
    return observableArrayFromRecordGetter$(this.sites$$, sortByName);
  }

  public get pinnedSites$(): Observable<LMOSiteSummary.AsObject[]> {
    const onlyPinned$ = this.sites$.pipe(map((sites) => sites.filter((site) => site.favorited)));
    // Since we are using observableArrayFromRecordGetter, it has already waited the set amount of time, no need to add more
    return observableArrayFromArrayGetter$(onlyPinned$, sortByName, 0);
  }

  public get unpinnedSites$(): Observable<LMOSiteSummary.AsObject[]> {
    const onlyUnpinned$ = this.sites$.pipe(
      map((sites) => sites.filter((site) => !site.favorited && !site.currentlyActive)),
    );
    // Since we are using observableArrayFromRecordGetter, it has already waited the set amount of time, no need to add more
    return observableArrayFromArrayGetter$(onlyUnpinned$, sortByName, 0);
  }

  public get currentlyActiveSites$(): Observable<LMOSiteSummary.AsObject[]> {
    const onlyCurrentlyActive$ = this.sites$.pipe(
      map((sites) => sites.filter((site) => site.currentlyActive && !site.favorited)),
    );
    // Since we are using observableArrayFromRecordGetter, it has already waited the set amount of time, no need to add more
    return observableArrayFromArrayGetter$(onlyCurrentlyActive$, sortByName, 0);
  }

  public get currentSite$(): Observable<Site.AsObject> {
    return this.currentSite$$.pipe(filter((site) => !!site));
  }

  public get searching$(): Observable<boolean> {
    return this.searching$$.asObservable();
  }

  public get currentSiteStock$(): Observable<Stock.AsObject> {
    return combineLatest([
      this.currentSite$,
      this.routerState.listenForParamChange$(fromRouterConstants.STOCK_ID),
    ]).pipe(
      map(([currentSite, stockId]) => {
        if (!currentSite) {
          return null;
        }
        return currentSite.stockReferencesList.find((s) => s.id === +stockId);
      }),
    );
  }

  public get pendingCount$(): Observable<number> {
    return this.pendingCount$$.asObservable();
  }

  public get inProgressCount$(): Observable<number> {
    return this.inProgressCount$$.asObservable();
  }

  public get needsAttnCount$(): Observable<number> {
    return this.needsAttnCount$$.asObservable();
  }

  public get siteOrderEtas$(): Observable<SiteOrderETAs.AsObject> {
    return this.siteOrderEtas$$.asObservable();
  }

  public get siteCalendarStats$(): Observable<LMOCalendarStats.AsObject[]> {
    return observableArrayFromArrayGetter$(this.siteCalendarStats$$);
  }

  constructor(
    private routerState: RouterStateService,
    private snackBar: MatSnackBar,
    private grpcService: GrpcService,
    private authService: AuthService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.refreshSites$.next(null);
    this.getLMOSiteCalendarStats();
    this.subscribeToRouterJobIdChanges();
    this.siteListPolling();
    this.currentSitePolling();

    this.authService.idToken$.subscribe((idToken) => {
      if (!idToken) {
        this.clearEverything();
      }
    });
    this.refreshSites$.pipe(throttleTime(100)).subscribe(() => this.getSites());
  }
  public createSite$(site: CreateSiteRequest): Observable<Site.AsObject> {
    return this.grpcService.invoke$(SiteAPI.CreateSite, site).pipe(
      tap(() => {
        this.refreshSites$.next(null);
      }),
      map((message: CreateSiteResponse) => {
        return message.toObject().site;
      }),
    );
  }

  public updateSite$(request: UpdateSiteRequest): Observable<Site.AsObject> {
    request.setId(this.currentSite$$.value.id);
    return this.grpcService.invoke$(SiteAPI.UpdateSite, request).pipe(
      tap(() => {
        this.refreshSites$.next(null);
        this.reloadCurrentSite();
      }),
      map((message: CreateSiteResponse) => {
        return message.toObject().site;
      }),
    );
  }

  public setFavorite(favorite: boolean, siteId: number) {
    const setFaveReq = new FavoriteSiteRequest();
    setFaveReq.setSiteId(siteId);
    setFaveReq.setFavorited(favorite);

    this.grpcService.invoke$(SiteAPI.FavoriteSite, setFaveReq).subscribe((message: FavoriteSiteResponse) => {
      this.reloadCurrentSite();
      this.refreshSites$.next(null);
      const favoritedObj = message.toObject();
      const snackbarMsg = favoritedObj.favorited ? 'Site Pinned' : 'Site Unpinned';
      this.snackBar.open(snackbarMsg, null, { duration: 5000 });
    });
  }

  public reloadCurrentSite() {
    this.routerState
      .listenForParamChange$(fromRouterConstants.JOB_ID)
      .pipe(take(1))
      .subscribe((jobId) => {
        if (!jobId) {
          this.currentSite$$.next(null);
          return;
        }
        this.getSite(+jobId);
      });
  }

  public addStockToSite$(request: AddStockRequest): Observable<AddStockResponse.AsObject> {
    const currentSite = this.currentSite$$.value;
    if (!currentSite || !currentSite.id) {
      return;
    }
    request.setSiteId(currentSite.id);
    return this.grpcService.invoke$(SiteAPI.AddStock, request).pipe(
      tap(() => {
        this.reloadCurrentSite();
        this.refreshSites$.next(null);
      }),
      map((response: AddStockResponse) => response.toObject()),
    );
  }

  public updateSiteStock$(request: UpdateStockRequest): Observable<UpdateStockRequest.AsObject> {
    const currentSite = this.currentSite$$.value;
    if (!currentSite || !currentSite.id) {
      return;
    }
    return this.grpcService.invoke$(SiteAPI.UpdateStock, request).pipe(
      tap(() => {
        this.reloadCurrentSite();
        this.refreshSites$.next(null);
      }),
      map((response: UpdateStockRequest) => response.toObject()),
    );
  }

  public removeSiteStock$(request: DeleteStockRequest): Observable<DeleteStockRequest.AsObject> {
    const currentSite = this.currentSite$$.value;
    if (!currentSite || !currentSite.id) {
      return;
    }
    return this.grpcService.invoke$(SiteAPI.DeleteStock, request).pipe(
      tap(() => {
        this.reloadCurrentSite();
        this.refreshSites$.next(null);
      }),
      map((response: DeleteStockRequest) => response.toObject()),
    );
  }

  public updateStockQuanities$(
    request: UpdateStocksQuantityRequest,
  ): Observable<UpdateStocksQuantityResponse.AsObject> {
    const currentSite = this.currentSite$$.value;
    if (!currentSite || !currentSite.id) {
      return;
    }
    return this.grpcService.invoke$(SiteAPI.UpdateStocksQuantity, request).pipe(
      tap(() => {
        this.reloadCurrentSite();
        this.refreshSites$.next(null);
      }),
      map((response: UpdateStockRequest) => response.toObject()),
    );
  }

  public reportIssue$(request: ReportSiteAvailabilityRequest): Observable<{}> {
    return this.grpcService.invoke$(SiteAPI.ReportSiteAvailability, request).pipe(
      map((response: ReportSiteAvailabilityResponse) => {
        this.reloadCurrentSite();
        this.refreshSites$.next(null);
        return response.toObject();
      }),
    );
  }

  public exportAllXlsx$(request: BulkExportRequest): Observable<string> {
    return this.grpcService.invoke$(FileAPI.BulkExport, request).pipe(
      map((response: BulkExportResponse) => {
        return response.toObject().link;
      }),
    );
  }

  public exportSiteXlsx$(request: BulkSiteExportRequest): Observable<string> {
    request.setSiteId(this.currentSite$$.value.id);
    request.setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    return this.grpcService.invoke$(FileAPI.BulkSiteExport, request).pipe(
      map((response: BulkExportResponse) => {
        return response.toObject().link;
      }),
    );
  }

  public searchForSite$(site: string): Observable<SiteShort.AsObject[]> {
    this.searching$$.next(true);
    const request = new LMOSearchSitesRequest();
    request.setSiteName(site);

    return this.grpcService.invoke$(SiteAPI.LMOSearchSites, request).pipe(
      map((results: LMOSearchSitesResponse) => {
        this.searching$$.next(false);
        const list = results.toObject().sitesList;
        if (this.currentSite$$.value) {
          return list.filter((s) => s.id !== this.currentSite$$.value.id);
        }
        return list;
      }),
    );
  }

  public archiveSite(siteId: number, targetState: boolean) {
    const request = new UpdateSiteRequest();
    request.setArchived(targetState);
    request.setId(siteId);
    const mask = new FieldMask();
    mask.addPaths('archived');
    request.setMask(mask);
    this.grpcService.invoke$(SiteAPI.UpdateSite, request).subscribe((response: UpdateSiteResponse) => {
      this.refreshSites$.next(null);
      const site = response.toObject().site;
      if (this.currentSite$$.value && this.currentSite$$.value.id === site.id) {
        this.currentSite$$.next(site);
      }
      const verb = site.archived ? 'archived' : 'restored';
      const snack = this.snackBar.open(`${site.name} ${verb}`, 'Undo', { duration: 10000 });
      snack.onAction().subscribe(() => {
        this.archiveSite(site.id, !site.archived);
      });
    });
  }

  public async getLMOSiteCalendarStats() {
    if (
      !(await this.authService
        .isLMO$()
        .pipe(take(1))
        .toPromise())
    ) {
      return;
    }
    const getCalendarStats = new LMOSitesCalendarRequest();
    this.grpcService
      .invoke$(SiteAPI.LMOSitesCalendar, getCalendarStats)
      .subscribe((response: LMOSitesCalendarResponse) => {
        this.siteCalendarStats$$.next(response.toObject().calendarStatsList);
      });
  }

  private subscribeToRouterJobIdChanges() {
    this.routerState.listenForParamChange$(fromRouterConstants.JOB_ID).subscribe((jobId) => {
      if (!jobId) {
        this.currentSite$$.next(null);
        return;
      }
      this.getSite(+jobId);
      this.getSiteOrderEtas(+jobId);
    });
  }

  private async getSite(siteId: number, backgroundRequest = false) {
    const isLMO = await this.authService
      .isLMO$()
      .pipe(take(1))
      .toPromise();
    if (isLMO) {
      const getSite = new GetSiteRequest();
      getSite.setId(siteId);
      this.grpcService.invoke$(SiteAPI.GetSite, getSite, backgroundRequest).subscribe((message: GetSiteResponse) => {
        const site = message.toObject().site;
        this.currentSite$$.next(site);
      });
    }
  }

  private getSites(backgroundRequest = false) {
    const listSites = new LMOListSitesRequest();
    listSites.setMaxResults(100);
    this.grpcService
      .invoke$(SiteAPI.LMOListSites, listSites, backgroundRequest)
      .subscribe((message: LMOListSitesResponse) => {
        const sitesAsRecord = idArrayToRecord(message.toObject().sitesList);
        this.sites$$.next(sitesAsRecord);
      });
  }

  private siteListPolling() {
    this.routerState.routerState$
      .pipe(
        filter((state) => !!state),
        map((state) => state.url),
        switchMap((url) => {
          if (url.endsWith('lmo/jobs')) {
            return interval(pollingTime).pipe(
              switchMapTo(this.featureFlagService.isFlagActive$('pollingLMO').pipe(take(1))),
              tap(async (isFlagActive) => {
                if (isFlagActive) {
                  const isLMO = await this.authService
                    .isAccountType$(AccountType.ACCOUNT_TYPE_LMO)
                    .pipe(take(1))
                    .toPromise();
                  if (!isLMO) {
                    return;
                  }
                  this.getSites(true);
                }
              }),
            );
          } else {
            return of(null);
          }
        }),
      )
      .subscribe();
  }

  private currentSitePolling() {
    this.routerState.routerState$
      .pipe(
        filter((state) => !!state),
        switchMap((state) => {
          if (state.params[fromRouterConstants.JOB_ID]) {
            return interval(pollingTime).pipe(
              switchMapTo(this.featureFlagService.isFlagActive$('pollingLMO').pipe(take(1))),
              tap(async (isFlagActive) => {
                if (isFlagActive) {
                  const isLMO = await this.authService
                    .isAccountType$(AccountType.ACCOUNT_TYPE_LMO)
                    .pipe(take(1))
                    .toPromise();
                  if (!isLMO) {
                    return;
                  }

                  this.getSite(+state.params[fromRouterConstants.JOB_ID], true);
                  this.getSiteOrderEtas(+state.params[fromRouterConstants.JOB_ID], true);
                }
              }),
            );
          } else {
            return of(null);
          }
        }),
      )
      .subscribe();
  }

  private getSiteOrderEtas(siteId: number, backgroundRequest = false) {
    const getEtas = new GetSiteOrderETAsRequest();
    getEtas.setSiteId(siteId);

    this.grpcService
      .invoke$(OrderAPI.GetSiteOrderEtas, getEtas, backgroundRequest)
      .subscribe((response: GetSiteOrderETAsResponse) => {
        const etas = response.toObject();
        this.siteOrderEtas$$.next(etas.etas);
      });
  }

  private clearEverything() {
    this.sites$$.next({});
    this.searching$$.next(false);
    this.currentSite$$.next(null);
    this.pendingCount$$.next(null);
    this.inProgressCount$$.next(null);
    this.needsAttnCount$$.next(null);
    this.siteOrderEtas$$.next(null);
    this.siteCalendarStats$$.next([]);
  }
}
