// package: cbv1
// file: proto/cybertron/contracts/contracts_api.proto

var proto_cybertron_contracts_contracts_api_pb = require("../../../proto/cybertron/contracts/contracts_api_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ContractsAPI = (function () {
  function ContractsAPI() {}
  ContractsAPI.serviceName = "cbv1.ContractsAPI";
  return ContractsAPI;
}());

ContractsAPI.CreateVendorContract = {
  methodName: "CreateVendorContract",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.CreateVendorContractRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.CreateVendorContractResponse
};

ContractsAPI.EditVendorContract = {
  methodName: "EditVendorContract",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.EditVendorContractRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.EditVendorContractResponse
};

ContractsAPI.GetVendorContract = {
  methodName: "GetVendorContract",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.GetVendorContractRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.GetVendorContractResponse
};

ContractsAPI.ToggleArchiveVendorContract = {
  methodName: "ToggleArchiveVendorContract",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.ToggleArchiveVendorContractRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.ToggleArchiveVendorContractResponse
};

ContractsAPI.ListVendorContracts = {
  methodName: "ListVendorContracts",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.ListVendorContractsRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.ListVendorContractsResponse
};

ContractsAPI.CreateLMOVendorPayloadTypePercentage = {
  methodName: "CreateLMOVendorPayloadTypePercentage",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.CreateLMOVendorPayloadTypePercentageRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.CreateLMOVendorPayloadTypePercentageResponse
};

ContractsAPI.GetLMOVendorPayloadTypePercentage = {
  methodName: "GetLMOVendorPayloadTypePercentage",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.GetLMOVendorPayloadTypePercentageRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.GetLMOVendorPayloadTypePercentageResponse
};

ContractsAPI.CreateVendorContractType = {
  methodName: "CreateVendorContractType",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.CreateContractTypeRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.CreateContractTypeResponse
};

ContractsAPI.EditVendorContractType = {
  methodName: "EditVendorContractType",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.EditContractTypeRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.EditContractTypeResponse
};

ContractsAPI.ToggleArchiveVendorContractType = {
  methodName: "ToggleArchiveVendorContractType",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.ToggleArchiveVendorContractTypeRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.ToggleArchiveVendorContractTypeResponse
};

ContractsAPI.ListVendorContractTypes = {
  methodName: "ListVendorContractTypes",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.ListContractTypeRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.ListContractTypeResponse
};

ContractsAPI.ListFixPointCosts = {
  methodName: "ListFixPointCosts",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.ListFixPointCostsRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.ListFixPointCostsResponse
};

ContractsAPI.ListFuelSurchargeBrackets = {
  methodName: "ListFuelSurchargeBrackets",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.ListFuelSurchargeBracketsRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.ListFuelSurchargeBracketsResponse
};

ContractsAPI.DownloadP2PFile = {
  methodName: "DownloadP2PFile",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.DownloadP2PFileRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.DownloadP2PFileResponse
};

ContractsAPI.DownloadFuelCostFile = {
  methodName: "DownloadFuelCostFile",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.DownloadFuelCostFileRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.DownloadFuelCostFileResponse
};

ContractsAPI.createFuelSurchargeContracts = {
  methodName: "createFuelSurchargeContracts",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.CreateFuelSurchargeContractsRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.CreateFuelSurchargeContractsResponse
};

ContractsAPI.ListFuelSurchargeContracts = {
  methodName: "ListFuelSurchargeContracts",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.ListFuelSurchargeContractsRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.ListFuelSurchargeContractsResponse
};

ContractsAPI.UpdateFuelSurchargeContract = {
  methodName: "UpdateFuelSurchargeContract",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.UpdateFuelSurchargeContractRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.UpdateFuelSurchargeContractResponse
};

ContractsAPI.ArchiveFuelSurchargeContract = {
  methodName: "ArchiveFuelSurchargeContract",
  service: ContractsAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_contracts_contracts_api_pb.ArchiveFuelSurchargeContractRequest,
  responseType: proto_cybertron_contracts_contracts_api_pb.ArchiveFuelSurchargeContractResponse
};

exports.ContractsAPI = ContractsAPI;

function ContractsAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ContractsAPIClient.prototype.createVendorContract = function createVendorContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.CreateVendorContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContractsAPIClient.prototype.editVendorContract = function editVendorContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.EditVendorContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContractsAPIClient.prototype.getVendorContract = function getVendorContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.GetVendorContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContractsAPIClient.prototype.toggleArchiveVendorContract = function toggleArchiveVendorContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.ToggleArchiveVendorContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContractsAPIClient.prototype.listVendorContracts = function listVendorContracts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.ListVendorContracts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContractsAPIClient.prototype.createLMOVendorPayloadTypePercentage = function createLMOVendorPayloadTypePercentage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.CreateLMOVendorPayloadTypePercentage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContractsAPIClient.prototype.getLMOVendorPayloadTypePercentage = function getLMOVendorPayloadTypePercentage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.GetLMOVendorPayloadTypePercentage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContractsAPIClient.prototype.createVendorContractType = function createVendorContractType(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.CreateVendorContractType, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContractsAPIClient.prototype.editVendorContractType = function editVendorContractType(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.EditVendorContractType, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContractsAPIClient.prototype.toggleArchiveVendorContractType = function toggleArchiveVendorContractType(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.ToggleArchiveVendorContractType, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContractsAPIClient.prototype.listVendorContractTypes = function listVendorContractTypes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.ListVendorContractTypes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContractsAPIClient.prototype.listFixPointCosts = function listFixPointCosts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.ListFixPointCosts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContractsAPIClient.prototype.listFuelSurchargeBrackets = function listFuelSurchargeBrackets(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.ListFuelSurchargeBrackets, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContractsAPIClient.prototype.downloadP2PFile = function downloadP2PFile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.DownloadP2PFile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContractsAPIClient.prototype.downloadFuelCostFile = function downloadFuelCostFile(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.DownloadFuelCostFile, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContractsAPIClient.prototype.createFuelSurchargeContracts = function createFuelSurchargeContracts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.createFuelSurchargeContracts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContractsAPIClient.prototype.listFuelSurchargeContracts = function listFuelSurchargeContracts(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.ListFuelSurchargeContracts, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContractsAPIClient.prototype.updateFuelSurchargeContract = function updateFuelSurchargeContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.UpdateFuelSurchargeContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ContractsAPIClient.prototype.archiveFuelSurchargeContract = function archiveFuelSurchargeContract(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ContractsAPI.ArchiveFuelSurchargeContract, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ContractsAPIClient = ContractsAPIClient;

