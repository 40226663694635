import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, finalize, share, take } from 'rxjs/operators';
import {
  ListCostCenterRequest,
  ListCostCenterResponse, MaintenanceOrderSearchRequest, MaintenanceOrderSearchResponse,
  PurchaseOrderSearchRequest,
  PurchaseOrderSearchResponse,
  SalesOrderSearchRequest,
  SalesOrderSearchResponse,
} from '~proto/order/order_api_pb';
import { OrderAPI } from '~proto/order/order_api_pb_service';
import { CostCenter } from '~proto/order/order_pb';
import {
  LMOPurchaseOrderShortcutsRequest,
  LMOPurchaseOrderShortcutsResponse,
  LMOSalesOrderNumberShortcutsRequest,
  LMOSalesOrderNumberShortcutsResponse,
} from '~proto/site/site_api_pb';
import { SiteAPI } from '~proto/site/site_api_pb_service';
import { GrpcService } from '~services/grpc.service';
import { RouterStateService } from '~services/router-state.service';
import * as fromRouterConstants from '../../app-routing.constants';

interface SalesOrdersWithSearchResults extends LMOSalesOrderNumberShortcutsResponse.AsObject {
  searchResults: string[];
}

interface PurchaseOrdersWithSearchResults extends LMOPurchaseOrderShortcutsResponse.AsObject {
  searchResults: string[];
}

interface MaintenanceOrdersWithSearchResults {
  recentlyUsedList: Array<string>,
  frequentlyUsedList: Array<string>,
  searchResults: string[];
}

@Injectable({
  providedIn: 'root',
})
export class CreateOrderRecentsService {
  private searchingSalesOrders$$ = new BehaviorSubject(false);
  private salesOrders$$: BehaviorSubject<SalesOrdersWithSearchResults> = new BehaviorSubject(null);

  private searchingPurchaseOrders$$ = new BehaviorSubject(false);
  private purchaseOrders$$: BehaviorSubject<PurchaseOrdersWithSearchResults> = new BehaviorSubject(null);

  private searchingCostCenters$$ = new BehaviorSubject(false);
  private costCenters$$: BehaviorSubject<CostCenter.AsObject[]> = new BehaviorSubject([]);

  private searchingMaintenanceOrders$$ = new BehaviorSubject(false);
  private maintenanceOrders$$: BehaviorSubject<MaintenanceOrdersWithSearchResults> = new BehaviorSubject(null);

  public get searchingSalesOrders$(): Observable<boolean> {
    return this.searchingSalesOrders$$.asObservable();
  }

  public get salesOrders$(): Observable<SalesOrdersWithSearchResults> {
    return this.salesOrders$$.asObservable().pipe(filter((s) => !!s));
  }

  public get searchingPurchaseOrders$(): Observable<boolean> {
    return this.searchingPurchaseOrders$$.asObservable();
  }

  public get purchaseOrders$(): Observable<PurchaseOrdersWithSearchResults> {
    return this.purchaseOrders$$.asObservable().pipe(filter((s) => !!s));
  }

  public get searchingCostCenters$(): Observable<boolean> {
    return this.searchingCostCenters$$.asObservable();
  }

  public get maintenanceOrders$(): Observable<MaintenanceOrdersWithSearchResults> {
    return this.maintenanceOrders$$.asObservable().pipe(filter((s) => !!s));
  }

  public get costCenters$(): Observable<CostCenter.AsObject[]> {
    return this.costCenters$$.asObservable().pipe(share());
  }

  constructor(private grpcService: GrpcService, private routerState: RouterStateService) {
  }

  public loadSalesOrder(): void {
    this.salesOrders$$.next(null);
    this.routerState
      .listenForParamChange$(fromRouterConstants.JOB_ID)
      .pipe(take(1))
      .subscribe((siteId) => {
        if (!siteId) {
          return;
        }
        const request = new LMOSalesOrderNumberShortcutsRequest();
        request.setSiteId(+siteId);
        this.grpcService
          .invoke$(SiteAPI.LMOSalesOrderNumberShortcuts, request)
          .subscribe((response: LMOSalesOrderNumberShortcutsResponse) => {
            this.salesOrders$$.next({
              ...response.toObject(),
              searchResults: [],
            });
          });
      });
  }

  public searchSalesOrders(searchTerm: string): void {
    if (!searchTerm || searchTerm === '') {
      this.salesOrders$$.next({
        ...this.salesOrders$$.value,
        searchResults: [],
      });
      return;
    }

    const request = new SalesOrderSearchRequest();
    request.setSearchText(searchTerm);
    this.searchingSalesOrders$$.next(true);
    this.grpcService
      .invoke$(OrderAPI.SalesOrderSearch, request)
      .pipe(finalize(() => this.searchingSalesOrders$$.next(false)))
      .subscribe((response: SalesOrderSearchResponse) => {
        let existingNames: Record<string, boolean> = {};
        if (this.salesOrders$$.value) {
          existingNames = [
            ...(this.salesOrders$$.value.recentlyUsedList || []),
            ...(this.salesOrders$$.value.frequentlyUsedList || []),
          ].reduce((map, name) => ({ ...map, [name]: true }), {});
        }

        this.salesOrders$$.next({
          ...this.salesOrders$$.value,
          searchResults: response.toObject().salesOrdersList.filter((name) => !existingNames[name]),
        });
      });
  }

  public searchMaintenanceOrders(searchTerm: string): void {
    if (!searchTerm || searchTerm === '') {
      this.maintenanceOrders$$.next({
        ...this.maintenanceOrders$$.value,
        searchResults: [],
      });
      return;
    }

    const request = new MaintenanceOrderSearchRequest();
    request.setSearchText(searchTerm);
    this.searchingMaintenanceOrders$$.next(true);
    this.grpcService
      .invoke$(OrderAPI.MaintenanceOrderSearch, request)
      .pipe(finalize(() => this.searchingMaintenanceOrders$$.next(false)))
      .subscribe((response: MaintenanceOrderSearchResponse) => {
        let existingNames: Record<string, boolean> = {};
        if (this.maintenanceOrders$$.value) {
          existingNames = [
            ...(this.maintenanceOrders$$.value.recentlyUsedList || []),
            ...(this.maintenanceOrders$$.value.frequentlyUsedList || []),
          ].reduce((map, name) => ({ ...map, [name]: true }), {});
        }

        this.maintenanceOrders$$.next({
          ...this.maintenanceOrders$$.value,
          searchResults: response.toObject().maintenanceOrdersList.filter((name) => !existingNames[name]),
        });
      });
  }

  public loadPurchaseOrders(payloadId: number): void {
    this.salesOrders$$.next(null);
    this.routerState
      .listenForParamChange$(fromRouterConstants.JOB_ID)
      .pipe(take(1))
      .subscribe((siteId) => {
        if (!siteId) {
          return;
        }
        const request = new LMOPurchaseOrderShortcutsRequest();
        request.setSiteId(+siteId);
        request.setPayloadId(payloadId);
        this.grpcService
          .invoke$(SiteAPI.LMOPurchaseOrderShortcuts, request)
          .subscribe((response: LMOPurchaseOrderShortcutsResponse) => {
            this.purchaseOrders$$.next({
              ...response.toObject(),
              searchResults: [],
            });
          });
      });
  }

  public searchPurchaseOrders(searchTerm: string): void {
    if (!searchTerm || searchTerm === '') {
      this.purchaseOrders$$.next({
        ...this.purchaseOrders$$.value,
        searchResults: [],
      });
      return;
    }

    const request = new PurchaseOrderSearchRequest();
    request.setSearchText(searchTerm);
    this.searchingPurchaseOrders$$.next(true);
    this.grpcService
      .invoke$(OrderAPI.PurchaseOrderSearch, request)
      .pipe(finalize(() => this.searchingPurchaseOrders$$.next(false)))
      .subscribe((response: PurchaseOrderSearchResponse) => {
        const existingNames = [
          ...(this.purchaseOrders$$.value.recentlyUsedList || []),
          ...(this.purchaseOrders$$.value.frequentlyUsedList || []),
        ].reduce((map, name) => ({ ...map, [name]: true }), {});
        this.purchaseOrders$$.next({
          ...this.purchaseOrders$$.value,
          searchResults: response.toObject().purchaseOrderNamesList.filter((name) => !existingNames[name]),
        });
      });
  }

  public searchCostCenters(searchTerm: string, businessLineId?: number): void {
    const request = new ListCostCenterRequest();
    request.setSearchTerm(searchTerm);
    request.setBusinessLineId(businessLineId);
    this.searchingCostCenters$$.next(true);
    this.grpcService
      .invoke$(OrderAPI.ListCostCenter, request)
      .pipe(finalize(() => this.searchingCostCenters$$.next(false)))
      .subscribe((response: ListCostCenterResponse) => {
        this.costCenters$$.next(response.toObject().costCentersList);
      });
  }
}
