import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

export const idColorMap = {
  0: '#e6194b',
  1: '#3cb44b',
  2: '#ffe119',
  3: '#4363d8',
  4: '#f58231',
  5: '#911eb4',
  6: '#46f0f0',
  7: '#f032e6',
  8: '#bcf60c',
  9: '#fabebe',
  10: '#008080',
  11: '#e6beff',
  12: '#9a6324',
  13: '#fffac8',
  14: '#800000',
  15: '#aaffc3',
  16: '#808000',
  17: '#ffd8b1',
  18: '#000075',
  19: '#808080',
  20: '#ffffff',
  21: '#000000',
};

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-payload-icon',
  styleUrls: ['./payload-icon.component.scss'],
  templateUrl: './payload-icon.component.svg',
})
export class PayloadIconComponent implements OnInit {
  public color = '#92A0AD';
  @Input() public set payloadTypeId(id: number) {
    this.color = idColorMap[id % 22] || '#92A0AD';
    this.cd.markForCheck();
  }
  constructor(private cd: ChangeDetectorRef) {}

  public ngOnInit() {}
}
