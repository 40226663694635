import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { startWith } from 'rxjs/operators';
import { CustomFieldSetting } from '~proto/fields/fields_pb';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFieldComponent),
    },
  ],
  selector: 'ct-custom-field',
  styleUrls: ['./custom-field.component.scss'],
  templateUrl: './custom-field.component.html',
})
export class CustomFieldComponent implements OnInit, ControlValueAccessor {
  public fc = new FormControl();
  @Input() public customField: CustomFieldSetting.AsObject;
  private onChanged = (_: any) => {};

  constructor() {}

  public ngOnInit() {
    if (this.customField.required) {
      this.fc.setValidators(Validators.required);
    }
    if (this.customField.type === 'boolean') {
      this.fc.setValue(false);
    }
  }

  public writeValue(obj: any): void {
    this.fc.setAsyncValidators(obj);
  }
  public registerOnChange(fn: any): void {
    this.onChanged = fn;
    this.fcListener();
  }
  public registerOnTouched(fn: any): void {
    // Do nothing, we don't care
  }
  public setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.fc.disable() : this.fc.enable();
  }

  private fcListener() {
    this.fc.valueChanges.pipe(startWith(this.fc.value)).subscribe((value) => {
      this.onChanged(value);
    });
  }
}
