// package: cbv1
// file: proto/cybertron/billing/billing_api.proto

var proto_cybertron_billing_billing_api_pb = require("../../../proto/cybertron/billing/billing_api_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var BillingAPI = (function () {
  function BillingAPI() {}
  BillingAPI.serviceName = "cbv1.BillingAPI";
  return BillingAPI;
}());

BillingAPI.ListNotBilledOrders = {
  methodName: "ListNotBilledOrders",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.ListNotBilledOrdersRequest,
  responseType: proto_cybertron_billing_billing_api_pb.ListNotBilledOrdersResponse
};

BillingAPI.ListApprovedByVendorOrders = {
  methodName: "ListApprovedByVendorOrders",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.ListApprovedByVendorOrdersRequest,
  responseType: proto_cybertron_billing_billing_api_pb.ListApprovedByVendorOrdersResponse
};

BillingAPI.ListApprovedByLMOOrders = {
  methodName: "ListApprovedByLMOOrders",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.ListApprovedByLMOOrdersRequest,
  responseType: proto_cybertron_billing_billing_api_pb.ListApprovedByLMOOrdersResponse
};

BillingAPI.ListInvoicedOrders = {
  methodName: "ListInvoicedOrders",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.ListInvoicedOrdersRequest,
  responseType: proto_cybertron_billing_billing_api_pb.ListInvoicedOrdersResponse
};

BillingAPI.ListApprovedOrders = {
  methodName: "ListApprovedOrders",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.ListApprovedOrdersRequest,
  responseType: proto_cybertron_billing_billing_api_pb.ListApprovedOrdersResponse
};

BillingAPI.ListBatchInvoices = {
  methodName: "ListBatchInvoices",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.ListBatchInvoicesRequest,
  responseType: proto_cybertron_billing_billing_api_pb.ListBatchInvoicesResponse
};

BillingAPI.ListBatchedOrders = {
  methodName: "ListBatchedOrders",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.ListBatchedOrdersRequest,
  responseType: proto_cybertron_billing_billing_api_pb.ListBatchedOrdersResponse
};

BillingAPI.GetBillingOrder = {
  methodName: "GetBillingOrder",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.GetBillingOrderRequest,
  responseType: proto_cybertron_billing_billing_api_pb.GetBillingOrderResponse
};

BillingAPI.SendToInvoicing = {
  methodName: "SendToInvoicing",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.SendToInvoicingRequest,
  responseType: proto_cybertron_billing_billing_api_pb.SendToInvoicingResponse
};

BillingAPI.BackToDispatcher = {
  methodName: "BackToDispatcher",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.BackToDispatcherRequest,
  responseType: proto_cybertron_billing_billing_api_pb.BackToDispatcherResponse
};

BillingAPI.BillOrders = {
  methodName: "BillOrders",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.BillOrdersRequest,
  responseType: proto_cybertron_billing_billing_api_pb.BillOrdersResponse
};

BillingAPI.ApproveInvoices = {
  methodName: "ApproveInvoices",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.ApproveInvoicesRequest,
  responseType: proto_cybertron_billing_billing_api_pb.ApproveInvoicesResponse
};

BillingAPI.RejectInvoices = {
  methodName: "RejectInvoices",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.RejectInvoicesRequest,
  responseType: proto_cybertron_billing_billing_api_pb.RejectInvoicesResponse
};

BillingAPI.UnApproveInvoices = {
  methodName: "UnApproveInvoices",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.UnApproveInvoicesRequest,
  responseType: proto_cybertron_billing_billing_api_pb.UnApproveInvoicesResponse
};

BillingAPI.UpdatedChargeObjList = {
  methodName: "UpdatedChargeObjList",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.UpdatedChargeObjListRequest,
  responseType: proto_cybertron_billing_billing_api_pb.UpdatedChargeObjListResponse
};

BillingAPI.UpdateBillingOrder = {
  methodName: "UpdateBillingOrder",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.UpdateBillingOrderRequest,
  responseType: proto_cybertron_billing_billing_api_pb.UpdateBillingOrderResponse
};

BillingAPI.ListOrderChargeTypes = {
  methodName: "ListOrderChargeTypes",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.ListOrderChargeTypesRequest,
  responseType: proto_cybertron_billing_billing_api_pb.ListOrderChargeTypesResponse
};

BillingAPI.ListAccountsForOrders = {
  methodName: "ListAccountsForOrders",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.ListAccountsForOrdersRequest,
  responseType: proto_cybertron_billing_billing_api_pb.ListAccountsForOrdersResponse
};

BillingAPI.OrdersCountPerStatus = {
  methodName: "OrdersCountPerStatus",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.OrdersCountPerStatusRequest,
  responseType: proto_cybertron_billing_billing_api_pb.OrdersCountPerStatusResponse
};

BillingAPI.NeedsPushback = {
  methodName: "NeedsPushback",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.NeedsPushbackRequest,
  responseType: proto_cybertron_billing_billing_api_pb.NeedsPushbackResponse
};

BillingAPI.ListContractsForOrder = {
  methodName: "ListContractsForOrder",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.ListContractsForOrderRequest,
  responseType: proto_cybertron_billing_billing_api_pb.ListContractsForOrderResponse
};

BillingAPI.SwitchContractForOrder = {
  methodName: "SwitchContractForOrder",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.SwitchContractForOrderRequest,
  responseType: proto_cybertron_billing_billing_api_pb.SwitchContractForOrderResponse
};

BillingAPI.LMOCancelCompletedOrders = {
  methodName: "LMOCancelCompletedOrders",
  service: BillingAPI,
  requestStream: false,
  responseStream: false,
  requestType: proto_cybertron_billing_billing_api_pb.LMOCancelCompletedOrdersRequest,
  responseType: proto_cybertron_billing_billing_api_pb.LMOCancelCompletedOrdersResponse
};

exports.BillingAPI = BillingAPI;

function BillingAPIClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

BillingAPIClient.prototype.listNotBilledOrders = function listNotBilledOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.ListNotBilledOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.listApprovedByVendorOrders = function listApprovedByVendorOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.ListApprovedByVendorOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.listApprovedByLMOOrders = function listApprovedByLMOOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.ListApprovedByLMOOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.listInvoicedOrders = function listInvoicedOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.ListInvoicedOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.listApprovedOrders = function listApprovedOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.ListApprovedOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.listBatchInvoices = function listBatchInvoices(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.ListBatchInvoices, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.listBatchedOrders = function listBatchedOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.ListBatchedOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.getBillingOrder = function getBillingOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.GetBillingOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.sendToInvoicing = function sendToInvoicing(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.SendToInvoicing, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.backToDispatcher = function backToDispatcher(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.BackToDispatcher, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.billOrders = function billOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.BillOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.approveInvoices = function approveInvoices(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.ApproveInvoices, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.rejectInvoices = function rejectInvoices(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.RejectInvoices, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.unApproveInvoices = function unApproveInvoices(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.UnApproveInvoices, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.updatedChargeObjList = function updatedChargeObjList(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.UpdatedChargeObjList, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.updateBillingOrder = function updateBillingOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.UpdateBillingOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.listOrderChargeTypes = function listOrderChargeTypes(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.ListOrderChargeTypes, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.listAccountsForOrders = function listAccountsForOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.ListAccountsForOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.ordersCountPerStatus = function ordersCountPerStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.OrdersCountPerStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.needsPushback = function needsPushback(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.NeedsPushback, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.listContractsForOrder = function listContractsForOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.ListContractsForOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.switchContractForOrder = function switchContractForOrder(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.SwitchContractForOrder, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

BillingAPIClient.prototype.lMOCancelCompletedOrders = function lMOCancelCompletedOrders(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(BillingAPI.LMOCancelCompletedOrders, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.BillingAPIClient = BillingAPIClient;

