import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ct-smart-dropdown',
  styleUrls: ['./smart-dropdown.component.scss'],
  templateUrl: './smart-dropdown.component.html',
})
export class SmartDropdownComponent implements OnInit, AfterViewInit {
  public selector = '';
  private _label = '';
  private isTransformed = false;
  private stopTransition$ = new Subject();
  public formControl = new FormControl();
  public isActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @ViewChild('overlay', { static: true }) private overlay: ElementRef;
  @ViewChild('input', { static: true }) private input: ElementRef;
  @ViewChild('inputWrapper', { static: true }) private inputWrapper: ElementRef;

  @Input()
  public set label(label: string) {
    this.selector = label
      .toLocaleLowerCase()
      .split(' ')
      .join('_');
    this._label = label;
  }

  public get label() {
    return this._label;
  }

  @Input() public selectionTemplate: TemplateRef<any>;
  @Input() public emptyTextTemplate: TemplateRef<any>;
  @Input() public addNewTemplate: TemplateRef<any>;
  @Input() public clearTemplate: TemplateRef<any>;
  @Input() public items: any[];
  @Input() public emptyText = 'No Results';
  @Input() public triggerText = '';
  @Input() public invalid = false;
  @Input() public finalPercentage = 100;
  @Input() public finalTop = -54;
  @Input() public finalLeft = 0;
  @Input() public extraMargin = 50;
  @Input() public showCloseButton = true;
  @Input() public startActive = false;
  @Input() public tab = '';
  @Input() public disabled = false;
  @Output() public textChange = new EventEmitter();
  @Output() public enterKey = new EventEmitter();
  @Output() public closed = new EventEmitter();
  @Output() public scrolled = new EventEmitter();

  constructor() {}

  public ngOnInit() {
    this.formControl.valueChanges.subscribe((value) => {
      this.textChange.next(value);
    });
  }

  public ngAfterViewInit(): void {
    if (this.startActive) {
      timer(200)
        .pipe(take(1))
        .subscribe(() => {
          this.focused();
        });
    }
  }

  public focused() {
    if (this.isTransformed || this.disabled) {
      return;
    }
    this.isTransformed = true;
    const target = this.inputWrapper.nativeElement as HTMLDivElement;
    const input = target.querySelector('input') as HTMLInputElement;
    const targetRect = target.getBoundingClientRect();
    const container = target.closest('.smart-dropdown-container');
    if (!container) {
      console.error('smart-dropdown must have an ancestor container indicated by ctSmartDropdownContainer');
      return;
    }
    const containerRect = container.getBoundingClientRect();
    const parentRect = container.parentElement.getBoundingClientRect();
    const currentHeight = targetRect.height;
    this.isActive$.next(true);
    target.setAttribute(
      'style',
      `top: ${this.finalTop}px; left: ${this.finalLeft}px;  width: ${this.finalPercentage}%; height: ${currentHeight}`,
    );
    this.overlay.nativeElement.setAttribute(
      'style',
      `top: ${parentRect.top}px; width: ${containerRect.width + 2 * this.extraMargin}px; height: ${
        parentRect.height
      }px; left: ${containerRect.left - this.extraMargin}px; padding: 0 ${this.extraMargin / 2}px; position: fixed;`,
    );
    setTimeout(() => {
      if (input) {
        input.focus();
      }
    }, 200);
  }

  public close() {
    this.isTransformed = false;
    this.stopTransition$.next(null);
    this.input.nativeElement.parentElement.setAttribute('style', '');
    this.overlay.nativeElement.setAttribute('style', ``);
    this.isActive$.next(false);
    this.closed.emit(null);
  }

  public onEnterKey($event: KeyboardEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.enterKey.next(null);
  }

  public trackByIdOrName<T extends { id?: number; name?: string }>(index: number, t: T): any {
    return t.id ? t.id : t.name;
  }

  public clearText() {
    this.formControl.setValue('');
  }

  public whenScrolled(event: any) {
    this.scrolled.next(event);
  }
}
