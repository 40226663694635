import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import {
  GetDriverCertificationsRequest,
  GetDriverCertificationsResponse,
} from '~proto/drivercertification/driverCertification_api_pb';
import { DriverCertificationAPI } from '~proto/drivercertification/driverCertification_api_pb_service';
import { DriverCertification } from '~proto/drivercertification/driverCertification_pb';
import { GrpcService } from '~services/grpc.service';
import { idArrayToRecord } from '~utilities/idArrayToRecord';
import { observableArrayFromRecordGetter$ } from '~utilities/observableGetter';
import { sortByName } from '~utilities/sortByName';

@Injectable({
  providedIn: 'root',
})
export class DriverCertificationService {
  private loadDriverCertificationThrottle$$ = new Subject();
  private driverCertifications$$: BehaviorSubject<Record<string, DriverCertification.AsObject>> = new BehaviorSubject(
    {},
  );

  public get driverCertifications$(): Observable<DriverCertification.AsObject[]> {
    this.loadDriverCertifications();
    return observableArrayFromRecordGetter$(this.driverCertifications$$, sortByName);
  }

  constructor(private grpc: GrpcService) {
    this.loadDriverCertificationThrottle$$.pipe(throttleTime(100)).subscribe(() => this._loadDriverCertifications());
  }

  private loadDriverCertifications() {
    this.loadDriverCertificationThrottle$$.next();
  }

  private _loadDriverCertifications() {
    const request = new GetDriverCertificationsRequest();
    this.grpc
      .invoke$(DriverCertificationAPI.GetDriverCertifications, request)
      .subscribe((driverCertifications: GetDriverCertificationsResponse) => {
        this.driverCertifications$$.next(idArrayToRecord(driverCertifications.toObject().driverCertificationsList));
      });
  }
}
