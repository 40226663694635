import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnDestroy,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { FeatureFlagService } from '~services/feature-flag.service';

@Directive({
  selector: '[ctFeatureFlag]',
})
export class FeatureFlagDirective implements OnDestroy {
  private _flag: string;
  private _elseTemplate$ = new BehaviorSubject<TemplateRef<any>>(null);
  private hasView = false;
  private hasElseView = false;
  private subscription: Subscription;
  constructor(
    private renderer: Renderer2,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlagService: FeatureFlagService,
    private cd: ChangeDetectorRef,
  ) {}

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @Input() set ctFeatureFlagElse(elseTemplate: TemplateRef<any>) {
    this._elseTemplate$.next(elseTemplate);
  }

  @Input() set ctFeatureFlag(flag: string) {
    this._flag = flag;
    this.updateView();
  }

  private updateView() {
    this.subscription = combineLatest([
      this.featureFlagService.isFlagActive$(this._flag),
      this.featureFlagService.isSAOnlyFlag$(this._flag),
      this._elseTemplate$,
    ]).subscribe(([flagActive, saOnly, elseTemplate]) => {
      if (!flagActive) {
        if (this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
          this.cd.markForCheck();
        }
        if (elseTemplate && !this.hasElseView) {
          this.viewContainer.createEmbeddedView(elseTemplate);
          this.hasElseView = true;
          this.cd.markForCheck();
        }
        return;
      }

      if (flagActive) {
        if (!this.hasView) {
          if (this.hasElseView) {
            this.viewContainer.clear();
            this.hasElseView = false;
          }
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
          this.cd.markForCheck();
        }

        const createdElement = this.renderer.nextSibling(this.templateRef.elementRef.nativeElement);
        if (createdElement && createdElement.classList) {
          if (saOnly) {
            this.renderer.addClass(createdElement, 'sa-only-flag');
          } else {
            this.renderer.removeClass(createdElement, 'sa-only-flag');
          }
        }
      }
    });
  }
}
